import React from "react";
import { connect } from "react-redux";
import { getConfig } from "../../actions/appConfigActions";
import CookieConsent from "react-cookie-consent";
import { withTranslation } from "react-i18next";
import CloseIcon from "../../static/close_white.svg";

class CookiePopup extends React.Component {
  componentDidMount() {
    this.props.getConfig(false);
  }

  render() {
    const { t } = this.props;
    const {
      info_bar,
      error_bar,
      info_bar_iat,
      error_bar_iat,
    } = this.props.config;
    return (
      <>
        {info_bar && info_bar_iat ? (
          <CookieConsent
            location="bottom"
            buttonText={t("const.close")}
            cookieName={`info_${info_bar_iat}`}
            id="cookieInfoBar"
            containerClasses={`bar bar--info ${error_bar ? `second-bar` : ""}`}
            buttonStyle={{
              background: "transparent",
              backgroundImage: `url(${CloseIcon})`,
              backgroundSize: "cover",
              height: 30,
              width: 30,
              color: "white",
              fontSize: "0 !important",
            }}
            style={{
              background: "black",
              fontSize: 18,
              alignItems: "center",
            }}
            expires={1}
          >
            <div dangerouslySetInnerHTML={{ __html: info_bar }} />
          </CookieConsent>
        ) : null}
        {error_bar && error_bar_iat ? (
          <CookieConsent
            location="bottom"
            buttonText={t("const.close")}
            cookieName={`error_${error_bar_iat}`}
            id="cookieErrorBar"
            containerClasses={`bar bar--error`}
            buttonStyle={{
              background: "transparent",
              backgroundImage: `url(${CloseIcon})`,
              backgroundSize: "cover",
              height: 30,
              width: 30,
              color: "white",
              fontSize: "0 !important",
            }}
            style={{
              background: "#ba0f20",
              fontSize: 18,
              alignItems: "center",
            }}
            expires={1}
          >
            <div dangerouslySetInnerHTML={{ __html: error_bar }} />
          </CookieConsent>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.appConfig.config,
  };
};

export default connect(mapStateToProps, { getConfig })(
  withTranslation()(CookiePopup)
);
