import _ from "lodash";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import jwt from "jwt-simple";
import history from "./history";
import { UNAUTH_USER } from "./actions/_types";

const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // die
  }
};

const checkTokenExpirationMiddleware = (store) => (next) => (
  action
) => {
  const token =
    JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user"))["token"];
  if (token) {
    if (jwt.decode(token, "", true).exp < Date.now() / 1000) {
      localStorage.clear();
      store.dispatch({ type: UNAUTH_USER });
      history.push("/logowanie?sessionEnd=true");
      next(action);
    }
  }
  next(action);
};

const store = createStore(
  reducers,
  loadState(),
  composeEnhancers(
    applyMiddleware(reduxThunk, checkTokenExpirationMiddleware)
  )
);

store.subscribe(
  _.throttle(() => {
    const { token, user, authenticated } = store.getState().auth;
    saveState({
      auth: {
        token,
        user,
        authenticated,
        signUp: {},
        signIn: {},
        verificationResend: {},
        verification: {},
        updateUser: {},
      },
    });
  }, 1000)
);

export default store;
