import React from "react";
import { withTranslation } from "react-i18next";
import { reduxForm, Field } from "redux-form";
import i18n from "../../../i18n";
import Input from "../../form-components/Input";
import Button from "../../form-components/Button";

class NewPasswordForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  render() {
    const { t } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          name="newpassword"
          component={Input}
          type="password"
          placeholder={t("newpasswd.new")}
        />
        <Field
          name="repeatpassword"
          component={Input}
          type="password"
          placeholder={t("newpasswd.repeat")}
        />
        <Button type="primary" htmlType="submit">
          {t("newpasswd.reset")}
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.newpassword)
    errors.newpassword = i18n.t("form.validate.required");
  if (!formValues.repeatpassword)
    errors.repeatpassword = i18n.t("form.validate.required");

  if (
    !/^[A-Za-zzżźćńółęąśŻŹĆĄŚĘŁÓŃ\d@^()\-$#!%*?&]{8,}$/.test(
      formValues.newpassword
    )
  )
    errors.newpassword = i18n.t("form.validate.passwd");

  if (formValues.newpassword !== formValues.repeatpassword)
    errors.repeatpassword = i18n.t("form.validate.passwdmatch");
  return errors;
};

export default reduxForm({ form: "resetPassword", validate })(
  withTranslation()(NewPasswordForm)
);
