import React from "react";
import { Card, Checkbox, Input, Form, Button, Select } from "antd";
import { connect } from "react-redux";
import { getConfig } from "../../../../actions/appConfigActions";
import { updateConfig } from "../../../../helpers/updateConfig";
import "../managers.scss";

class LimitationsSettings extends React.Component {
  state = {
    max_diff_ip_views_per_user_changed: false,
    max_diff_ip_views_per_user: this.props.config.max_diff_ip_views_per_user,
    max_diff_ip_views_per_user_error: "",
    tickets_expiration_time:
      this.props.config.tickets_expiration_time || "no-limit",
    tickets_expiration_time_changed: false,
    tickets_expiration_time_error: "",
  };
  onInputChange = (e, inputName) => {
    const newState = {};
    newState[inputName] = e.target.value;
    newState[`${inputName}_changed`] = true;
    this.setState(newState);
  };
  toggleConfig = (e, field) => {
    let newData = {};
    newData[field] = e.target.checked ? 1 : 0;
    this.changeConfig(newData).then(() => {
      let newState = {};
      newState[field] = e.target.checked;
      this.setState(newState);
    });
  };
  changeConfig = (data) => {
    return updateConfig(data).then(() => {
      this.props.getConfig();
    });
  };
  updateMaxDiffIp = () => {
    const val = this.state.max_diff_ip_views_per_user;
    if (val !== undefined && val !== null && val !== "" && isNaN(parseInt(val)))
      this.setState({
        max_diff_ip_views_per_user_error: "Niepoprawna wartość",
        max_diff_ip_views_per_user_changed: false,
      });
    let newData = {};
    newData["max_diff_ip_views_per_user"] = val;
    this.changeConfig(newData).then(() => {
      this.setState({
        max_diff_ip_views_per_user: val,
        max_diff_ip_views_per_user_changed: false,
        max_diff_ip_views_per_user_error: "",
      });
    });
  };
  updateTicketsExpirationTime = () => {
    const val =
      this.state.tickets_expiration_time === "no-limit"
        ? null
        : this.state.tickets_expiration_time;
    if (![null, 3, 6, 12, 24, 36, 48, 72].includes(val)) {
      this.setState({
        tickets_expiration_time_error:
          "Niepoprawna wartość. Dozwolone wartości: 3, 6, 12, 24, 36, 48, 72.",
        tickets_expiration_time_changed: false,
      });
      return;
    }
    let newData = {};
    newData["tickets_expiration_time"] = val;
    this.changeConfig(newData).then(() => {
      this.setState({
        tickets_expiration_time: val || "no-limit",
        tickets_expiration_time_changed: false,
        tickets_expiration_time_error: "",
      });
    });
  };
  componentDidMount() {
    this.props.getConfig();
  }
  render() {
    const {
      access_only_poland,
      autoban_after_exceeding_ip_limit,
      send_email_after_ip_ban,
      access_only_europe,
      maintenance,
    } = this.props.config;
    const {
      max_diff_ip_views_per_user,
      max_diff_ip_views_per_user_changed,
    } = this.state;
    return (
      <>
        <Card style={{ marginBottom: "30px" }} title="Tryb konserwacji">
          <label htmlFor="maintenance">
            <Checkbox
              style={{ marginRight: "5px" }}
              id="maintenance"
              checked={maintenance}
              type="checkbox"
              name="maintenance"
              onChange={(e) => this.toggleConfig(e, "maintenance")}
            />
            Włącz tryb konserwacji. Logowanie dostępne na podstronie
            /admin-login
          </label>
        </Card>
        <Card
          style={{ marginBottom: "30px" }}
          title="Ograniczenie dostępu do wideo"
        >
          <label htmlFor="access_only_poland">
            <Checkbox
              style={{ marginRight: "5px" }}
              id="access_only_poland"
              checked={access_only_poland}
              type="checkbox"
              name="access_only_poland"
              onChange={(e) => this.toggleConfig(e, "access_only_poland")}
            />
            Wideo dostępne tylko dla użytkowników z Polski
          </label>
          <br />
          <br />
          <label htmlFor="access_only_europe">
            <Checkbox
              style={{ marginRight: "5px" }}
              id="access_only_europe"
              checked={access_only_europe}
              type="checkbox"
              name="access_only_europe"
              onChange={(e) => this.toggleConfig(e, "access_only_europe")}
            />
            Wideo dostępne tylko dla użytkowników z Unii Europejskiej
          </label>
        </Card>
        <Card style={{ marginBottom: "30px" }} title="Blokowanie kont">
          <label htmlFor="autoban_after_exceeding_ip_limit">
            <Checkbox
              style={{ marginRight: "5px" }}
              id="autoban_after_exceeding_ip_limit"
              checked={autoban_after_exceeding_ip_limit}
              type="checkbox"
              name="autoban_after_exceeding_ip_limit"
              onChange={(e) =>
                this.toggleConfig(e, "autoban_after_exceeding_ip_limit")
              }
            />
            Automatyczne blokowanie kont użytkowników na których obejrzano wideo
            z kilku różnych adresów IP
          </label>
          <br />
          <br />
          <label htmlFor="send_email_after_ip_ban">
            <Checkbox
              style={{ marginRight: "5px" }}
              id="send_email_after_ip_ban"
              checked={send_email_after_ip_ban}
              type="checkbox"
              name="send_email_after_ip_ban"
              onChange={(e) => this.toggleConfig(e, "send_email_after_ip_ban")}
            />
            Wyślij maila do użytkownika o zablokowaniu jego konta z powodu
            przekroczenia liczby wyświetleń wideo z różnych adresów IP
          </label>
          <br />
          <br />
          <br />
          <p>
            Maksymalna liczba różnych adresów IP z których można obejrzeć wideo
            w ramach jednego użytkownika
          </p>
          <Form layout="inline">
            <Form.Item>
              <div style={{ display: "inline-block" }}>
                <Input
                  name="max_diff_ip_views_per_user"
                  value={
                    max_diff_ip_views_per_user ||
                    this.props.config.max_diff_ip_views_per_user
                  }
                  type="number"
                  step={1}
                  style={{ width: 200, marginRight: 15 }}
                  onChange={(e) =>
                    this.onInputChange(e, "max_diff_ip_views_per_user")
                  }
                />
                <div style={{ color: "#cf3d3d", padding: "10px 5px" }}>
                  {this.state.max_diff_ip_views_per_user_error}
                </div>
              </div>
              <Button
                type="primary"
                onClick={() =>
                  this.updateMaxDiffIp("max_diff_ip_views_per_user")
                }
                disabled={!max_diff_ip_views_per_user_changed}
              >
                Zapisz
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Card
          style={{ marginBottom: "30px" }}
          title="Czas ważności biletów dla treści VOD."
        >
          <Form layout="inline">
            <Form.Item>
              <div style={{ display: "inline-block" }}>
                <Select
                  value={
                    this.state.tickets_expiration_time ||
                    this.props.config.tickets_expiration_time ||
                    "no-limit"
                  }
                  allowClear
                  style={{ width: "100%" }}
                  dropdownMatchSelectWidth={false}
                  onChange={(v) => {
                    this.setState({
                      tickets_expiration_time: v || "no-limit",
                      tickets_expiration_time_changed: true,
                    });
                  }}
                >
                  <Select.Option key="no-limit" value="no-limit">
                    bez ograniczeń
                  </Select.Option>
                  <Select.Option key="3" value={3}>
                    3 godziny
                  </Select.Option>
                  <Select.Option key="6" value={6}>
                    6 godzin
                  </Select.Option>
                  <Select.Option key="12" value={12}>
                    12 godzin
                  </Select.Option>
                  <Select.Option key="24" value={24}>
                    24 godziny
                  </Select.Option>
                  <Select.Option key="36" value={36}>
                    36 godzin
                  </Select.Option>
                  <Select.Option key="48" value={48}>
                    48 godzin
                  </Select.Option>
                  <Select.Option key="72" value={72}>
                    72 godziny
                  </Select.Option>
                </Select>
                <div style={{ color: "#cf3d3d", padding: "10px 5px" }}>
                  {this.state.tickets_expiration_time_error}
                </div>
              </div>
              <Button
                type="primary"
                onClick={() =>
                  this.updateTicketsExpirationTime("tickets_expiration_time")
                }
                style={{ marginLeft: 5 }}
                disabled={!this.state.tickets_expiration_time_changed}
              >
                Zapisz
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    configLoading: state.appConfig.loading,
    config: state.appConfig.config,
  };
};

export default connect(mapStateToProps, { getConfig })(LimitationsSettings);
