import {
  CODES_LOADING,
  GET_CODES_SUCCESS,
  GET_CODES_FAILURE,
  GET_CODE_SUCCESS,
  GET_CODE_FAILURE,
  DELETE_CODE_SUCCESS,
  DELETE_CODE_FAILURE,
  RENDER_CODES_SUCCESS,
  RENDER_CODES_FAILURE,
  UPDATE_CODE_SUCCESS,
  UPDATE_CODE_FAILURE,
  ACTIVATE_CODE_SUCCESS,
  ACTIVATE_CODE_FAILURE,
  DELETE_USER_CODE_SUCCESS,
  DELETE_USER_CODE_FAILURE,
  GET_USER_CODES_SUCCESS,
  GET_USER_CODES_FAILURE,
  GET_USER_CODE_SUCCESS,
  GET_USER_CODE_FAILURE,
} from "../actions/_types";

const initialState = {
  codes: [],
  userCodes: [],
  newCodes: [],
  activated: false,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        codes: action.payload,
      };
    case GET_CODES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_CODE_SUCCESS:
      let found = false;
      let codes = state.codes.map((code) => {
        if (code.id === action.payload.id) {
          found = true;
          return action.payload;
        }
        return code;
      });
      if (!found) codes.push(action.payload);
      return {
        ...state,
        loading: false,
        error: undefined,
        codes: codes,
      };
    case GET_CODE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        codes: state.codes.filter((code) => code.id !== action.payload),
      };
    case DELETE_CODE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case RENDER_CODES_SUCCESS:
      return {
        ...state,
        renderSuccess: true,
        loading: false,
        error: undefined,
        codes: [...action.payload, ...state.codes],
        newCodes: [...action.payload],
      };
    case RENDER_CODES_FAILURE:
      return {
        ...state,
        renderSuccess: false,
        error: action.payload,
        loading: false,
        newCodes: [],
      };
    case UPDATE_CODE_SUCCESS:
      return {
        ...state,
        renderSuccess: true,
        loading: false,
        error: undefined,
        codes: state.codes.map((code) => {
          if (code.id === action.payload.id) return action.payload;
          return code;
        }),
      };
    case UPDATE_CODE_FAILURE:
      return {
        ...state,
        renderSuccess: false,
        error: action.payload,
        loading: false,
      };
    case GET_USER_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        userCodes: action.payload,
      };
    case GET_USER_CODES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_USER_CODE_SUCCESS:
      let foundUserCode = false;
      let userCodes = state.userCodes.map((code) => {
        if (code.id === action.payload.id) {
          foundUserCode = true;
          return action.payload;
        }
        return code;
      });
      if (!foundUserCode) userCodes.push(action.payload);
      return {
        ...state,
        loading: false,
        error: undefined,
        userCodes: userCodes,
      };
    case GET_USER_CODE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_USER_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        userCodes: state.userCodes.filter(
          (code) => code.code_id !== action.payload
        ),
      };
    case DELETE_USER_CODE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ACTIVATE_CODE_SUCCESS:
      return {
        ...state,
        activateSuccess: true,
        loading: false,
        error: undefined,
        activated: action.payload.activated,
        userCodes: [action.payload.userCode, ...state.userCodes],
      };
    case ACTIVATE_CODE_FAILURE:
      return {
        ...state,
        activateSuccess: false,
        error: action.payload,
        loading: false,
      };
    case CODES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
