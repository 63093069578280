import React from "react";
import { Card } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../../../../actions/categoriesActions";
import CategoriesList from "./lists/CategoriesList";
import "../managers.scss";
import CategoryTranslationsModal from "./others/CategoryTranslationsModal";

class CategoriesManager extends React.Component {
  state = {
    chosenCategory: null,
    showTranslations: false,
  };
  manageTranslationsClick = (id) => {
    this.setState({
      chosenCategory: id,
      showTranslations: true,
    });
  };
  render() {
    return (
      <Card style={{ marginBottom: "30px" }} title="Kategorie filmów">
        <CategoriesList
          manageTranslationsClick={this.manageTranslationsClick}
          getCategories={this.props.getCategories}
          createCategory={this.props.createCategory}
          updateCategory={this.props.updateCategory}
          deleteCategory={this.props.deleteCategory}
          loading={this.props.loading}
          categories={this.props.categories}
        />
        {this.state.showTranslations ? (
          <CategoryTranslationsModal
            visible={this.state.showTranslations}
            category={_.pick(
              this.props.categories.filter(
                (e) => e.id === this.state.chosenCategory
              )[0],
              "id",
              "name"
            )}
            closeModal={() =>
              this.setState({ showTranslations: false })
            }
          />
        ) : null}
      </Card>
    );
  }
}
const mapStateToProsp = (state) => {
  return {
    categories: state.categories.categories,
    loading: state.categories.loading,
  };
};

export default connect(mapStateToProsp, {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
})(CategoriesManager);
