import React from "react";
import { connect } from "react-redux";
import { message, Card } from "antd";
import _ from "lodash";
import {
  getOrders,
  getGiftSettings,
  updateGiftSettings,
  clearGiftConfigStatus,
} from "../../../../actions/giftsActions";
import { getVideos } from "../../../../actions/videosActions";
import { getEvents } from "../../../../actions/eventsActions";
import GiftList from "./lists/GiftList";
import "../managers.scss";
import GiftConfig from "./forms/GiftConfig";
import moment from "moment-timezone";

class GiftsManager extends React.Component {
  state = {
    config: this.props.config,
  };
  componentDidMount() {
    this.props.getOrders();
    this.props.getGiftSettings();
    this.props.getEvents({ date_from: "2000-01-01" });
    this.props.getVideos();
  }
  componentWillUnmount() {
    this.props.clearGiftConfigStatus();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.updateSuccess) message.success("Zapisano zmiany");
    if (nextProps.error) message.error(nextProps.error);
    if (prevState.config !== nextProps.config) {
      return {
        config: nextProps.config,
      };
    }
    return null;
  }
  handleSearch = (props) => {
    this.props.getOrders(props);
  };
  render() {
    let initialValues = _.pick(
      this.props.config,
      "gift_expiration",
      "gift_available",
      "any_gift_available",
      "gift_price",
      "mobile_gift_image",
      "desktop_gift_image",
      "tablet_gift_image",
      "gift_label",
      "gift_label_en",
      "gift_description_en",
      "gift_description"
    );
    if (initialValues.gift_description)
      initialValues.gift_description = initialValues.gift_description.replace(
        /<br \/>/gi,
        "\n"
      );
    if (initialValues.gift_description_en)
      initialValues.gift_description_en = initialValues.gift_description_en.replace(
        /<br \/>/gi,
        "\n"
      );
    if (initialValues.gift_expiration)
      initialValues.gift_expiration = moment(initialValues.gift_expiration);
    const orders = this.props.orders;
    return (
      <>
        <GiftConfig
          onSubmit={this.props.updateGiftSettings}
          initialValues={initialValues}
          mobile_gift_image={initialValues.mobile_gift_image}
          tablet_gift_image={initialValues.tablet_gift_image}
          desktop_gift_image={initialValues.desktop_gift_image}
          any_gift_checked={initialValues.any_gift_available}
        />
        <Card
          style={{ marginBottom: "30px" }}
          title="Zamówione prezenty"
          className="gift-list"
        >
          <GiftList
            orders={orders}
            onSubmit={this.handleSearch}
            videos={this.props.videos}
            events={this.props.events}
          />
        </Card>
      </>
    );
  }
}

const mapStateToProsp = (state) => {
  return {
    orders: state.gifts.orders,
    config: state.gifts.config,
    updateSuccess: state.gifts.updateSuccess,
    videos: state.videos.videos,
    events: state.events.events,
  };
};

export default connect(mapStateToProsp, {
  getOrders,
  getGiftSettings,
  updateGiftSettings,
  clearGiftConfigStatus,
  getVideos,
  getEvents,
})(GiftsManager);
