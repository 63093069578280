import vod, { tokenConfig } from "../apis/vod";
import {
  GALLERIES_LOADING,
  CREATE_GALLERY_SUCCESS,
  CREATE_GALLERY_FAILURE,
  DELETE_GALLERY_SUCCESS,
  DELETE_GALLERY_FAILURE,
  GET_GALLERIES_SUCCESS,
  GET_GALLERIES_FAILURE,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_FAILURE,
  DELETE_GALLERY_IMAGE_SUCCESS,
  DELETE_GALLERY_IMAGE_FAILURE,
  ADD_GALLERY_IMAGE_SUCCESS,
  ADD_GALLERY_IMAGE_FAILURE,
  UPDATE_GALLERY_SUCCESS,
  UPDATE_GALLERY_FAILURE,
  GALLERY_FORM_ERRORS,
} from "./_types";
import handleErrors from "./handleErrors";

export const getGalleries = (props = {}) => (dispatch, getState) => {
  dispatch(setGalleriesLoading);
  vod
    .get(`/api/galleries`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_GALLERIES_SUCCESS,
        payload: res.data.galleries,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GALLERIES_FAILURE);
    });
};

export const getGallery = (id) => (dispatch, getState) => {
  dispatch(setGalleriesLoading);
  vod
    .get(`/api/galleries/${id}`, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_GALLERY_SUCCESS,
        payload: res.data.gallery,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GALLERY_FAILURE);
    });
};

export const deleteGallery = (id) => (dispatch, getState) => {
  dispatch(setGalleriesLoading());
  vod
    .delete(`/api/galleries/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_GALLERY_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_GALLERY_FAILURE);
    });
};

export const createGallery = (props) => (dispatch, getState) => {
  dispatch(setGalleriesLoading());
  vod
    .post(`/api/galleries/`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: CREATE_GALLERY_SUCCESS,
        payload: res.data.gallery,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        CREATE_GALLERY_FAILURE,
        GALLERY_FORM_ERRORS
      );
    });
};

export const addGalleryImage = (galleryId, props) => (
  dispatch,
  getState
) => {
  dispatch(setGalleriesLoading());
  vod
    .post(
      `/api/galleries/${galleryId}/images`,
      props,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: ADD_GALLERY_IMAGE_SUCCESS,
        payload: res.data.gallery,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        ADD_GALLERY_IMAGE_FAILURE,
        GALLERY_FORM_ERRORS
      );
    });
};

export const deleteGalleryImage = (id) => (dispatch, getState) => {
  dispatch(setGalleriesLoading());
  vod
    .delete(`/api/galleryImages/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_GALLERY_IMAGE_SUCCESS,
        payload: res.data.gallery,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_GALLERY_IMAGE_FAILURE);
    });
};

export const updateGallery = (props, id) => (dispatch, getState) => {
  dispatch(setGalleriesLoading());
  vod
    .put(`/api/galleries/${id}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_GALLERY_SUCCESS,
        payload: res.data.gallery,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        UPDATE_GALLERY_FAILURE,
        GALLERY_FORM_ERRORS
      );
    });
};

export const setGalleriesLoading = () => {
  return {
    type: GALLERIES_LOADING,
  };
};
