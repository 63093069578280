import React from "react";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import "moment/locale/pl";
import "./Datepicker.scss";
import { useTranslation } from "react-i18next";

moment.locale("pl");

export const makeField = (Component, format, showErrorNextTo = false) => ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  style,
  formStyle,
  ...rest
}) => {
  const { t } = useTranslation();
  const error = meta.touched && meta.error !== null ? meta.error : null;
  return (
    <FormItem
      label={label}
      hasFeedback
      help={error ? t(error) : null}
      validateStatus={error ? "error" : null}
      style={formStyle}
    >
      <Component
        format={format}
        {...input}
        {...rest}
        children={children}
        style={showErrorNextTo ? { marginRight: 5, ...style } : style}
      />
      {showErrorNextTo && meta.error ? (
        <span style={{ color: "#FF4D4F" }} className="input-error nextto">
          {meta.error}
        </span>
      ) : null}
    </FormItem>
  );
};
