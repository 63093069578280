import React from "react";
import { connect } from "react-redux";
import qs from "query-string";
import { Link } from "react-router-dom";
import { Button, Alert, Result } from "antd";
import history from "../../../history";
import { resendVerification } from "../../../actions/authActions";
import { getConfig } from "../../../actions/appConfigActions";
import Wrapper from "../../wrappers/Wrapper";
import LineBreak from "../../LineBreak";
import { withTranslation } from "react-i18next";

class SignUpSuccess extends React.Component {
  resendEmail = () => {
    this.props.resendVerification({ email: this.email });
  };
  componentDidMount() {
    this.email = qs.parse(this.props.location.search).email;
    if (!this.props.success || !this.email)
      history.push("/rejestracja");
    this.props.getConfig(false);
  }
  render() {
    const { t } = this.props;
    const {
      error,
      resendSuccess,
      loading,
    } = this.props.verificationResend;
    return (
      <Wrapper
        title={t("signup.title")}
        bg={this.props.appConfig.noauth_bg_image}
        authLayout={true}
      >
        <Result status="success" title={t("signup.success")} />
        <p
          style={{
            lineHeight: "1.3",
            fontSize: "17px",
            textAlign: "center",
          }}
        >
          {t("signup.success_text")}
          <br />
          <br />
          <Link to={`/logowanie`} className="ui button primary">
            <Button type="primary">{t("signin.title")}</Button>
          </Link>
        </p>
        <LineBreak />
        <p style={{ fontSize: "12px" }}>{t("signup.no_email")}</p>
        {!error && !resendSuccess ? (
          <>
            <Button
              onClick={this.resendEmail}
              loading={loading ? true : false}
              size="small"
            >
              {t("signup.resend")}
            </Button>
          </>
        ) : null}
        {error && !resendSuccess ? (
          <Alert
            message={`${t("signup.resend_error")} ${this.email}. ${t(
              "signup.resend_error_2"
            )}`}
            type="error"
            showIcon
            style={{ fontSize: "12px" }}
          />
        ) : null}
        {!error && resendSuccess ? (
          <Alert
            type="success"
            message={t("signup.resend_success")}
            showIcon
          />
        ) : null}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    success: state.auth.signUp.success,
    verificationResend: state.auth.verificationResend,
    appConfig: state.appConfig.config,
  };
};

export default connect(mapStateToProps, {
  resendVerification,
  getConfig,
})(withTranslation()(SignUpSuccess));
