import React from "react";
import { Link } from "react-router-dom";
import { Skeleton, Button, Result } from "antd";
import Wrapper from "../../wrappers/Wrapper";
import vod from "../../../apis/vod";
import "./StaticPage.scss";
import { withTranslation } from "react-i18next";

class StaticPage extends React.Component {
  state = { loading: true, not_found: false };
  componentDidMount() {
    const id = this.props.slug || this.props.match.params.slug;

    vod
      .get(`/api/pages/${id}`)
      .then((res) => {
        this.setState({ ...res.data.page, loading: false });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.setState({ not_found: true, loading: false });
        }
      });
  }
  render() {
    const { t } = this.props;
    if (!this.state.loading && !this.state.not_found) {
      const { title, content } = this.state;
      return (
        <Wrapper authLayout={false}>
          <h1>{title}</h1>
          <div
            className="page-content"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </Wrapper>
      );
    } else if (!this.state.loading && this.state.not_found) {
      return (
        <Wrapper authLayout={false}>
          <Result
            status={404}
            title={404}
            subTitle={t("errorpage.404")}
            extra={
              <Link to="/">
                <Button type="primary">
                  {t("errorpage.homepage")}
                </Button>
              </Link>
            }
          />
        </Wrapper>
      );
    } else {
      return (
        <Wrapper authLayout={false}>
          <Skeleton />
        </Wrapper>
      );
    }
  }
}

export default withTranslation()(StaticPage);
