import React from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../theme-variables";
import Cookies from "universal-cookie";
import "./LanguageSelector.scss";

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();
  let currentLanguage = i18n.language;
  const cookies = new Cookies();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    cookies.set("language", language);
  };

  return (
    <div className="languageSelector">
      {t("const.lng_choice")}:{" "}
      {languages.map((language) => {
        return (
          <button
            key={language}
            onClick={() => changeLanguage(language)}
            className={`languageButton ${
              currentLanguage === language ? "active" : ""
            }`}
          >
            {language}
          </button>
        );
      })}
    </div>
  );
};

export default LanguageSelector;
