import { Button, notification, Result } from "antd";
import React from "react";
import { withTranslation } from "react-i18next";
import qs from "query-string";
import vod from "../../../apis/vod";
import "./Transmission.scss";
import { renderPreview } from "../../../helpers/renderPreview";
import { Link } from "react-router-dom";
import { facebookPixel } from "../../../theme-variables";
import ReactPixel from "react-facebook-pixel";

class Transmission extends React.Component {
  state = { videoType: "standard" };
  changeVideoType = (videoType) => {
    this.setState({ videoType });
  };
  renderVideosSwitcher = () => {
    const { urlAudiodesc, urlAccessibility, stopTransmission, t } = this.props;

    return (
      <>
        <Link to="?" title={t("videodesc.spectacle_desc")}>
          <Button onClick={stopTransmission}>
            {t("videodesc.spectacle_desc")}
          </Button>
        </Link>
        {urlAccessibility || urlAudiodesc ? (
          <Link to="?ogladaj=true" title={t("videodesc.watch")}>
            <Button onClick={() => this.changeVideoType("standard")}>
              {t("videodesc.watch")}
            </Button>
          </Link>
        ) : null}
        {urlAccessibility ? (
          <Link to="?ogladaj=true&typ=pjm" title={t("videodesc.pjm_video")}>
            <Button onClick={() => this.changeVideoType("pjm")}>
              {t("videodesc.pjm_video")}
            </Button>
          </Link>
        ) : null}
        {urlAudiodesc ? (
          <Link
            to="?ogladaj=true&typ=audiodeskrypcja"
            title={t("videodesc.audiodesc_video")}
          >
            <Button onClick={() => this.changeVideoType("audiodesc")}>
              {t("videodesc.audiodesc_video")}
            </Button>
          </Link>
        ) : null}
      </>
    );
  };
  componentDidMount() {
    const { t, userToken, queryParams, price, title, id } = this.props;
    const videoType = qs.parse(queryParams).typ || "standard";
    if (facebookPixel) {
      const productType = window.location.href.indexOf("wideo")
        ? "video"
        : "event";
      ReactPixel.track("ViewContent", {
        content_ids: [`${productType}-${id}`],
        content_name: title,
        content_type: "product",
        contents: [{ id: `${productType}-${id}`, quantity: 1 }],
        currency: "PLN",
        value: parseFloat(price),
      });
    }
    this.setState({ videoType });
    this.checker = setInterval(() => {
      vod
        .get(`/api/users/validate-session`, {
          headers: {
            "x-auth-token": userToken,
          },
        })
        .then(() => {})
        .catch((err) => {
          if (err.response.status) {
            clearInterval(this.checker);
            switch (err.response.status) {
              case 401:
                notification["error"]({
                  message: t("const.warning"),
                  duration: 10,
                  onClose: () => this.logout("userBlocked"),
                  description: t("videotrans.account_blocked"),
                });
                break;
              case 403:
                notification["error"]({
                  message: t("const.warning"),
                  duration: 10,
                  onClose: () => this.logout("anotherUser"),
                  description: t("videotrans.another_user"),
                });
                break;
              default:
                break;
            }
          }
        });
    }, 300000);
  }
  logout = (redirectParam) => {
    this.props.signOutUser(redirectParam);
  };
  componentWillUnmount() {
    clearInterval(this.checker);
  }
  renderVideo = () => {
    const {
      urlVideo,
      urlAccessibility,
      urlAudiodesc,
      countryBlock,
      access,
      t,
    } = this.props;
    const { videoType } = this.state;
    if (!access || countryBlock)
      return <Result status="error" title={t("videotrans.no_access")} />;
    if (urlAudiodesc && videoType === "audiodesc")
      return renderPreview(urlAudiodesc);
    if (urlAccessibility && videoType === "pjm")
      return renderPreview(urlAccessibility);
    return renderPreview(urlVideo);
  };
  render() {
    const { title, date, time } = this.props;
    return (
      <div className="transmission">
        <div className="transmission-header">
          <h1 className="header-title">{title}</h1>
          <div className="header-additional">
            {date && time ? (
              <div className="header-datetime">
                <div className="header-date">{date}</div>
                <div className="header-time">{time}</div>
              </div>
            ) : null}
            <div className="header-links">{this.renderVideosSwitcher()}</div>
          </div>
        </div>
        <div className="transmission-content">{this.renderVideo()}</div>
      </div>
    );
  }
}

export default withTranslation()(Transmission);
