import {
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
  CONFIG_LOADING,
} from "./_types";
import vod from "../apis/vod";
import handleErrors from "./handleErrors";

export const getConfig = (auth = true) => (dispatch, getState) => {
  dispatch(setConfigLoading());
  vod
    .get(
      `/api/appconfig/`,
      auth
        ? {
            headers: {
              "x-auth-token": getState().auth.token,
            },
          }
        : {}
    )
    .then((res) => {
      dispatch({
        type: GET_CONFIG_SUCCESS,
        payload: res.data.config,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_CONFIG_FAILURE);
    });
};

export const setConfigLoading = () => {
  return {
    type: CONFIG_LOADING,
  };
};
