import { SET_ERROR_PAGE } from "./_types";
import history from "../history";

export const setErrorPage = (
  statusCode = 500,
  message = "Wystąpił błąd. Prosimy spróbować później.",
  link = undefined
) => (dispatch) => {
  dispatch({
    type: SET_ERROR_PAGE,
    payload: {
      statusCode,
      message,
      link: link ? link : history.location.pathname,
    },
  });
  history.push(`/error`);
};
