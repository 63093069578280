import { Input, Radio, Select, Form } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import countries from "../../../helpers/countries";
import { allowCompanyInvoices } from "../../../theme-variables";

class InvoiceForm extends Component {
  renderCountryCodeSelect = ({
    input,
    placeholder,
    meta,
    prefix,
    suffix,
    required,
    ...rest
  }) => {
    const error = meta.touched && meta.error !== null ? meta.error : null;
    return (
      <Form.Item
        className={`teapp-select-wrapper ${required ? "required" : ""}`}
        colon
        help={error ? this.props.t(error) : null}
        onBlur={() => {
          input.onBlur(input.value);
        }}
        validateStatus={error ? "error" : null}
        hasFeedback
        {...rest}
      >
        <Select
          allowClear
          showSearch
          placeholder={placeholder}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          {...input}
          dropdownMatchSelectWidth={false}
        >
          {countries.map((v) => (
            <Select.Option key={v.code} value={v.code}>
              {v.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };
  onInvoiceTypeChange = (e) => {
    this.props.changeInvoiceType(e.target.value);
  };
  render() {
    const { t, invoiceData, changeInvoiceData } = this.props;
    const {
      street,
      postal_code,
      country_code,
      city,
      state,
      nip,
      company,
      firstname,
      lastname,
      street_changed,
      postal_code_changed,
      country_code_changed,
      city_changed,
      state_changed,
      nip_changed,
      company_changed,
      firstname_changed,
      lastname_changed,
      street_error,
      postal_code_error,
      country_code_error,
      city_error,
      state_error,
      nip_error,
      company_error,
      firstname_error,
      lastname_error,
    } = invoiceData;
    return (
      <div className="invoice-form">
        {allowCompanyInvoices ? (
          <Radio.Group
            onChange={this.onInvoiceTypeChange}
            defaultValue="person"
          >
            <Radio.Button value="company" style={{ marginRight: 15 }}>
              Firma
            </Radio.Button>
            <Radio.Button value="person">Osoba prywatna</Radio.Button>
          </Radio.Group>
        ) : null}
        <Form>
          {this.props.invoiceType === "company" ? (
            <Form.Item
              hasFeedback
              validateStatus={
                company_error ? "error" : company_changed ? "success" : null
              }
              help={company_error || null}
            >
              <Input
                value={company}
                placeholder={t("form.placeholder.company")}
                onChange={(e) => {
                  changeInvoiceData({
                    company: e.target.value,
                    company_changed: true,
                    company_error:
                      e.target.value && e.target.value.length > 100
                        ? t("form.validate.wrong")
                        : null,
                  });
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              hasFeedback
              validateStatus={
                firstname_error ? "error" : firstname_changed ? "success" : null
              }
              help={firstname_error || null}
            >
              <Input
                value={firstname}
                placeholder={t("form.placeholder.name")}
                onChange={(e) => {
                  changeInvoiceData({
                    firstname: e.target.value,
                    firstname_changed: true,
                    firstname_error: !e.target.value
                      ? t("form.validate.required")
                      : !/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(
                          e.target.value
                        )
                      ? t("form.validate.special_chars")
                      : null,
                  });
                }}
              />
            </Form.Item>
          )}
          {this.props.invoiceType === "company" ? (
            <Form.Item
              hasFeedback
              validateStatus={
                nip_error ? "error" : nip_changed ? "success" : null
              }
              help={nip_error || null}
            >
              <Input
                value={nip}
                placeholder={t("form.placeholder.nip")}
                onChange={(e) => {
                  changeInvoiceData({
                    nip: e.target.value,
                    nip_changed: true,
                    nip_error: !e.target.value
                      ? t("form.validate.required")
                      : !/^[0-9]*$/.test(e.target.value) ||
                        e.target.value.length > 10
                      ? t("form.validate.wrong")
                      : null,
                  });
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              hasFeedback
              validateStatus={
                lastname_error ? "error" : lastname_changed ? "success" : null
              }
              help={lastname_error || null}
            >
              <Input
                value={lastname}
                placeholder={t("form.placeholder.lastname")}
                onChange={(e) => {
                  changeInvoiceData({
                    lastname: e.target.value,
                    lastname_changed: true,
                    lastname_error: !e.target.value
                      ? t("form.validate.required")
                      : !/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(
                          e.target.value
                        )
                      ? t("form.validate.special_chars")
                      : null,
                  });
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            hasFeedback
            validateStatus={
              street_error ? "error" : street_changed ? "success" : null
            }
            help={street_error || null}
          >
            <Input
              value={street}
              placeholder={t("form.placeholder.street")}
              onChange={(e) => {
                changeInvoiceData({
                  street: e.target.value,
                  street_changed: true,
                  street_error: !e.target.value
                    ? t("form.validate.required")
                    : null,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            validateStatus={
              city_error ? "error" : city_changed ? "success" : null
            }
            help={city_error || null}
          >
            <Input
              value={city}
              placeholder={t("form.placeholder.city")}
              onChange={(e) => {
                changeInvoiceData({
                  city: e.target.value,
                  city_changed: true,
                  city_error: !e.target.value
                    ? t("form.validate.required")
                    : e.target.value.length > 30 ||
                      /<[a-z/][\s\S]*>/.test(e.target.value)
                    ? t("form.validate.wrong_input_max30")
                    : null,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            validateStatus={
              state_error ? "error" : state_changed ? "success" : null
            }
            help={state_error || null}
          >
            <Input
              value={state}
              placeholder={t("form.placeholder.state")}
              onChange={(e) => {
                changeInvoiceData({
                  state: e.target.value,
                  state_changed: true,
                  state_error: !e.target.value
                    ? t("form.validate.required")
                    : e.target.value.length > 30 ||
                      /<[a-z/][\s\S]*>/.test(e.target.value)
                    ? t("form.validate.wrong_input_max30")
                    : null,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            validateStatus={
              postal_code_error
                ? "error"
                : postal_code_changed
                ? "success"
                : null
            }
            help={postal_code_error || null}
          >
            <Input
              placeholder={t("form.placeholder.postal_code")}
              value={postal_code}
              onChange={(e) => {
                changeInvoiceData({
                  postal_code: e.target.value,
                  postal_code_changed: true,
                  postal_code_error: !e.target.value
                    ? t("form.validate.required")
                    : e.target.value.length > 10 ||
                      /<[a-z/][\s\S]*>/.test(e.target.value)
                    ? t("form.validate.wrong_input_max10")
                    : null,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            validateStatus={
              country_code_error
                ? "error"
                : country_code_changed
                ? "success"
                : null
            }
            help={country_code_error || null}
          >
            <Select
              value={country_code}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              required={true}
              placeholder={t("form.placeholder.country_code")}
              dropdownMatchSelectWidth={false}
              onChange={(val) => {
                changeInvoiceData({
                  country_code: val,
                  country_code_changed: true,
                  country_code_error: !val
                    ? t("form.validate.required")
                    : val.length > 2
                    ? t("form.validate.wrong_input_max2")
                    : null,
                });
              }}
            >
              {countries.map((v) => (
                <Select.Option key={v.code} value={v.code}>
                  {v.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(InvoiceForm);
