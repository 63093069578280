import React from "react";
import { connect } from "react-redux";
import { message, Card } from "antd";
import _ from "lodash";
import {
  getOrders,
  getGroupTicketSettings,
  updateGroupTicketSettings,
  clearGroupTicketConfigStatus,
} from "../../../../actions/groupTicketsActions";
import { getVideos } from "../../../../actions/videosActions";
import { getEvents } from "../../../../actions/eventsActions";
import GroupTicketList from "./lists/GroupTicketList";
import "../managers.scss";
import GroupTicketConfig from "./forms/GroupTicketConfig";

class GroupTicketsManager extends React.Component {
  state = {
    config: this.props.config,
  };
  componentDidMount() {
    this.props.getOrders();
    this.props.getGroupTicketSettings();
    this.props.getEvents({ date_from: "2000-01-01" });
    this.props.getVideos();
  }
  componentWillUnmount() {
    this.props.clearGroupTicketConfigStatus();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.updateSuccess) message.success("Zapisano zmiany");
    if (nextProps.error) message.error(nextProps.error);
    if (prevState.config !== nextProps.config) {
      return {
        config: nextProps.config,
      };
    }
    return null;
  }
  handleSearch = (props) => {
    this.props.getOrders(props);
  };
  render() {
    const orders = this.props.orders;
    return (
      <>
        <Card
          style={{ marginBottom: "30px" }}
          title="Ustawienia biletów grupowych"
          className="group-ticket-config"
        >
          <GroupTicketConfig
            onSubmit={this.props.updateGroupTicketSettings}
            initialValues={_.pick(
              this.state.config,
              "event_available",
              "video_available",
              "one_free",
              "min_units",
              "max_units",
              "discount"
            )}
          />
        </Card>
        <Card
          style={{ marginBottom: "30px" }}
          title="Zamówione bilety grupowe"
          className="group-ticket-list"
        >
          <GroupTicketList
            orders={orders}
            onSubmit={this.handleSearch}
            videos={this.props.videos}
            events={this.props.events}
          />
        </Card>
      </>
    );
  }
}

const mapStateToProsp = (state) => {
  return {
    orders: state.groupTickets.orders,
    config: state.groupTickets.config,
    updateSuccess: state.groupTickets.updateSuccess,
    videos: state.videos.videos,
    events: state.events.events,
  };
};

export default connect(mapStateToProsp, {
  getOrders,
  getGroupTicketSettings,
  updateGroupTicketSettings,
  clearGroupTicketConfigStatus,
  getVideos,
  getEvents,
})(GroupTicketsManager);
