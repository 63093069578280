import imageCompression from "browser-image-compression";

const compressImage = async (imageFile, options) => {
  try {
    let compressedFile = await imageCompression(imageFile, options);
    compressedFile = new File([compressedFile], imageFile.name, {
      type: imageFile.type,
      lastModified: Date.now(),
    });
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};

export default compressImage;
