import React from "react";
import FileInput from "./FileInput";
import { Field } from "redux-form";

class FileField extends React.Component {
  validateImageWeight = (imageFile) => {
    if (imageFile && imageFile.size) {
      // Get image size in kilobytes
      const imageFileKb = imageFile.size / 1024;
      const maxWeight = this.props.maxWeight;
      if (imageFileKb > maxWeight) {
        return `Zbyt duży rozmiar pliku. Maksymalny rozmiar pliku to ${parseInt(
          this.props.maxWeight / 1024
        )}MB.`;
      }
    }
  };
  validateImageFormat = (imageFile) => {
    if (imageFile) {
      const mimeType = "image/jpeg, image/png";
      if (!mimeType.includes(imageFile.type)) {
        return `Złe rozszerzenie pliku. Dozwolony jpeg oraz png.`;
      }
    }
  };
  validateImageWidth = (imageFile) => {
    if (imageFile) {
      const { maxWidth } = this.props.maxWidth;
      if (imageFile.width > maxWidth) {
        return `Maksymalna szerokość to ${maxWidth}px`;
      }
    }
  };
  validateImageHeight = (imageFile) => {
    if (imageFile) {
      const { maxHeight } = this.props.maxHeight;
      if (imageFile.height > maxHeight) {
        return `Maksymalna wysokość to ${maxHeight}px`;
      }
    }
  };

  handleImageUploadChange = (event, input) => {
    event.preventDefault();
    let imageFile = event.target.files[0];
    if (imageFile) {
      const mimeType = "image/jpeg, image/png";
      if (!mimeType.includes(imageFile.type)) {
        input.onChange(imageFile);
      } else {
        const localImageUrl = URL.createObjectURL(imageFile);
        const imageObject = new window.Image();
        imageObject.onload = () => {
          imageFile.width = imageObject.naturalWidth;
          imageFile.height = imageObject.naturalHeight;
          input.onChange(imageFile);
          URL.revokeObjectURL(imageFile);
        };
        imageObject.src = localImageUrl;

        this.props.setImageUrl(localImageUrl, this.props.urlField);
      }
    }
  };
  render() {
    return (
      <Field
        id={this.props.name}
        name={this.props.name}
        label={this.props.label}
        urlField={this.props.urlField}
        deleteImageFromServer={this.props.deleteImageFromServer}
        handleImageUploadChange={this.handleImageUploadChange}
        setImageUrl={this.props.setImageUrl}
        currentImage={this.props.currentImage}
        type="file"
        validate={[
          this.validateImageFormat,
          this.validateImageWeight,
          this.validateImageHeight,
          this.validateImageWidth,
        ]}
        component={FileInput}
      />
    );
  }
}

export default FileField;
