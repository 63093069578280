import React from "react";
import { Table } from "antd";
import {
  renderTagByPaymentStatus,
  getDatetimeWithTz,
} from "../../../helpers/productUtilities";
import { withTranslation } from "react-i18next";

const Column = Table.Column;

class UserPaymentList extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Table
        locale={{ emptyText: t("userpage.no_orders") }}
        className="users-orders-table"
        dataSource={this.props.dataSource}
        pagination={{ defaultPageSize: 20 }}
      >
        <Column
          title={t("userpage.order_id")}
          dataIndex="extOrderId"
          key="extOrderId"
        />
        <Column
          title={t("userpage.payu_order_id")}
          dataIndex="orderId"
          key="orderId"
        />
        <Column
          title={t("userpage.price")}
          dataIndex="amount"
          key="amount"
          render={(v) => `${v} zł`}
        />
        <Column title={t("userpage.order_desc")} dataIndex="desc" key="title" />
        <Column
          title={t("userpage.created")}
          dataIndex="created_at"
          key="created_at"
          render={(v) => {
            const date = getDatetimeWithTz(v);
            return date.format("H:mm, DD.MM.YYYY");
          }}
        />
        <Column
          title={t("userpage.order_status")}
          dataIndex="status"
          key="status"
          render={(v) => {
            return renderTagByPaymentStatus(v, t);
          }}
        />
      </Table>
    );
  }
}

export default withTranslation()(UserPaymentList);
