import vod from "../apis/vod";
import history from "../history";
import {
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_FORM_ERROR,
  VERIFY_RESET_PASSWORD_SUCCESS,
  VERIFY_RESET_PASSWORD_FAILURE,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
} from "./_types";
import handleErrors from "./handleErrors";

export const requestResetPassword = (props) => (dispatch) => {
  dispatch({ type: RESET_PASSWORD_LOADING });
  vod
    .post(`/api/passwords`, props)
    .then(() => {
      dispatch({ type: RESET_PASSWORD_SUCCESS });

      history.push(
        `/resetowanie-hasla/informacja?email=${props.email}`
      );
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        RESET_PASSWORD_FAILURE,
        RESET_PASSWORD_FORM_ERROR
      );
    });
};

export const verifyResetPassword = (props) => (dispatch) => {
  vod
    .post(`/api/passwords/verifyreset`, props)
    .then(() => {
      dispatch({ type: VERIFY_RESET_PASSWORD_SUCCESS });
    })
    .catch((err) => {
      handleErrors(err, dispatch, VERIFY_RESET_PASSWORD_FAILURE);
    });
};

export const setNewPassword = (props) => (dispatch) => {
  vod
    .put(`/api/passwords`, props)
    .then((response) => {
      dispatch({ type: SET_NEW_PASSWORD_SUCCESS });
    })
    .catch((err) => {
      handleErrors(err, dispatch, SET_NEW_PASSWORD_FAILURE);
    });
};
