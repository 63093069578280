import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Table, Popconfirm } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Column from "antd/lib/table/Column";

class CodeList extends React.Component {
  onFilterChange = (formValues) => {
    this.props.onFilterChange(formValues);
  };
  render() {
    return (
      <>
        <Table
          dataSource={this.props.codes}
          pagination={{ defaultPageSize: 20 }}
          size="small"
          expandable={{
            expandedRowRender: (r) => (
              <ul style={{ margin: 0 }}>
                {r.uses.map((u) => (
                  <li key={u.id}>
                    <Link to={`/konto/${u.userId}`}>
                      {u.firstname} {u.lastname}
                    </Link>{" "}
                    —{" "}
                    {u.used
                      ? `Wykorzystano: ${moment(u.usage_date).format(
                          "H:mm, DD.MM.YYYY"
                        )}`
                      : "Nie wykorzystano"}
                  </li>
                ))}
              </ul>
            ),
            rowExpandable: (r) => r.uses && r.uses.length > 0,
          }}
        >
          <Column
            title="Kod"
            dataIndex="code"
            key="code"
            sorter={(a, b) => a.code.localeCompare(b.code)}
          />
          <Column
            title="Opis"
            dataIndex="desc"
            key="desc"
            sorter={(a, b) => a.desc.localeCompare(b.desc)}
          />
          <Column
            title="Szczegóły"
            dataIndex="expiration_date"
            key="details"
            render={(v, r) => {
              return (
                <>
                  {r.discount_percent
                    ? `–${r.discount_percent}%`
                    : `–${r.discount_cash} zł`}
                  <br />
                  {r.event_id ? (
                    <Link to={`/wydarzenie/${r.event_id}`}>
                      Wydarzenie #{r.event_id}
                    </Link>
                  ) : null}
                  {r.video_id ? (
                    <Link to={`/wideo/${r.video_id}`}>
                      Wideo #{r.video_id}
                    </Link>
                  ) : null}
                  {!r.video_id && !r.event_id
                    ? `Na dowolny produkt`
                    : null}
                  <br />
                  Data wygaśnięcia:{" "}
                  {moment(r.expiration_date).format(
                    "H:mm, DD.MM.YYYY"
                  )}
                </>
              );
            }}
          />
          <Column
            title="Limit użyć / Ilość użyć"
            dataIndex="limit"
            key="limit"
            sorter={(a, b) => a.limit - b.limit}
            render={(v, r) => {
              return `${r.limit}/${
                r.numberOfUses !== undefined ? r.numberOfUses : "błąd"
              }`;
            }}
          />
          <Column
            title="Akcje"
            key="action"
            render={(text, r) => (
              <>
                {r.numberOfUses > 0 ? (
                  <DownloadOutlined
                    key="see"
                    style={{ margin: "0 10px", fontSize: 20 }}
                    onClick={() => {
                      this.props.getCodeUses(r.id);
                    }}
                  />
                ) : null}

                <EditOutlined
                  key="edit"
                  onClick={() => this.props.editCodeClick(r.id)}
                  style={{ margin: "0 10px", fontSize: 20 }}
                />

                <Popconfirm
                  title={`Czy na pewno chcesz usunąć kod rabatowy: ${r.code}? Zostanie on również usunięty dla użytkowników, którzy go aktywowali`}
                  onConfirm={() => {
                    this.props.deleteCode(r.id);
                  }}
                  okText="Tak"
                  cancelText="Nie"
                >
                  <DeleteOutlined
                    key="delete"
                    style={{ margin: "0 10px", fontSize: 20 }}
                  />
                </Popconfirm>
              </>
            )}
          />
        </Table>
      </>
    );
  }
}

export default CodeList;
