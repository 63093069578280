import React from "react";
import qs from "query-string";
import { connect } from "react-redux";
import { Button, Alert, Result } from "antd";
import { requestResetPassword } from "../../../actions/resetPasswordActions";
import { getConfig } from "../../../actions/appConfigActions";
import Wrapper from "../../wrappers/Wrapper";
import LineBreak from "../../LineBreak";
import { withTranslation } from "react-i18next";

class ResetPasswordConfirm extends React.Component {
  state = {
    resend: true,
    email: qs.parse(this.props.location.search).email,
  };
  componentDidMount() {
    this.props.getConfig(false);
  }
  onClick = (email) => {
    this.setState({ resend: false });
    this.props.requestResetPassword({ email });
  };
  render() {
    const { t } = this.props;
    return (
      <Wrapper
        title={t("newpasswd.title")}
        bg={this.props.appConfig.noauth_bg_image}
        authLayout={true}
      >
        <Result
          status="success"
          title={t("newpasswd.reset_success")}
        />
        <br />
        <LineBreak />
        <p style={{ fontSize: "12px" }}>
          {t("newpasswd.resend_text")}
        </p>
        {this.state.resend ? (
          <Button
            size="small"
            onClick={() => this.onClick(this.state.email)}
          >
            {t("newpasswd.resend")}
          </Button>
        ) : null}
        {!this.state.resend && this.props.reset.success ? (
          <Alert
            message={t("newpasswd.resend_success")}
            type="success"
            showIcon
          />
        ) : null}
        {this.props.reset.error ? (
          <Alert
            message={this.props.reset.error}
            showIcon
            type="error"
          />
        ) : null}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reset: state.resetPassword.reset,
    appConfig: state.appConfig.config,
  };
};

export default connect(mapStateToProps, {
  requestResetPassword,
  getConfig,
})(withTranslation()(ResetPasswordConfirm));
