import React from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Result, Skeleton, Button } from "antd";
import {
  verifyResetPassword,
  setNewPassword,
} from "../../../actions/resetPasswordActions";
import { getConfig } from "../../../actions/appConfigActions";
import Wrapper from "../../wrappers/Wrapper";
import NewPasswordForm from "./NewPasswordForm";
import { withTranslation } from "react-i18next";

class ResetPasswordNew extends React.Component {
  state = { ...qs.parse(this.props.location.search) };
  componentDidMount() {
    this.props.getConfig(false);
    const { email, token } = this.state;
    this.props.verifyResetPassword({ email, token });
  }
  onSubmit = (formValues) => {
    formValues.email = this.state.email;
    formValues.token = this.state.token;
    this.props.setNewPassword(formValues);
  };
  render() {
    const { t } = this.props;
    if (this.props.newPassword.success) {
      return (
        <Wrapper
          bg={this.props.appConfig.noauth_bg_image}
          authLayout={true}
        >
          <Result
            status="success"
            title={t("newpasswd.change_success")}
            extra={[
              <Link to={`/logowanie`} className="ui button primary">
                <Button type="primary">{t("newpasswd.login")}</Button>
              </Link>,
            ]}
          />
        </Wrapper>
      );
    } else if (
      this.props.verify.success &&
      !this.props.newPassword.error
    )
      return (
        <Wrapper
          title={t("newpasswd.create_new_pswd")}
          bg={this.props.appConfig.noauth_bg_image}
          authLayout={true}
        >
          <NewPasswordForm onSubmit={this.onSubmit} />
        </Wrapper>
      );
    else if (this.props.verify.error)
      return (
        <Wrapper authLayout={true}>
          <Result
            status="error"
            title={this.props.verify.error}
            extra={[
              <Link
                to={`/resetowanie-hasla`}
                className="ui button primary"
              >
                <Button type="primary">
                  {t("newpasswd.try_again")}
                </Button>
              </Link>,
            ]}
          />
        </Wrapper>
      );
    else if (this.props.newPassword.error)
      return (
        <div>
          {this.props.newPassword.error}
          <Link
            to={`/resetowanie-hasla`}
            className="ui button primary"
          >
            {t("newpasswd.try_again")}
          </Link>
        </div>
      );
    else
      return (
        <Wrapper>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    verify: state.resetPassword.verify,
    newPassword: state.resetPassword.newPassword,
    appConfig: state.appConfig.config,
  };
};

export default connect(mapStateToProps, {
  verifyResetPassword,
  setNewPassword,
  getConfig,
})(withTranslation()(ResetPasswordNew));
