import React from "react";
import { connect } from "react-redux";
import { message } from "antd";

class ErrorHandler extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.videoError &&
      prevProps.videoTime !== this.props.videoTime
    )
      message.error(this.props.videoError);
    if (
      this.props.eventError &&
      prevProps.eventTime !== this.props.eventTime
    )
      message.error(this.props.eventError);
  }
  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    videoError: state.videos.error,
    videoTime: state.videos.time,
    eventError: state.events.error,
    eventTime: state.events.time,
  };
};

export default connect(mapStateToProps)(ErrorHandler);
