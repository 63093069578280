import React from "react";
import { Popconfirm, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { getDatetimeWithTz } from "../../../../../helpers/productUtilities";

class UsersBansList extends React.Component {
  render() {
    const { bans } = this.props;
    const dataSource = bans.map((ban) => {
      ban.key = ban.id;
      return ban;
    });
    return (
      <Table dataSource={dataSource} size="small" pagination={false}>
        <Column title="Id" dataIndex="id" key="id" />
        <Column
          title="Powód blokady"
          dataIndex="reason"
          key="reason"
        />
        <Column
          title="Data wygaśnięcia"
          dataIndex="expiration_date"
          key="expiration_date"
          render={(v, r) => {
            if (r.forever) return "na stałe";
            else if (v) {
              const date = getDatetimeWithTz(v);
              return date.format("H:mm, DD.MM.YYYY");
            }
            return null;
          }}
        />
        <Column
          title="Akcje"
          key="action"
          render={(v, r) => (
            <>
              <Popconfirm
                title={`Czy na pewno chcesz usunąć blokadę?`}
                onConfirm={() => this.props.deleteBan(r.id)}
                okText="Tak"
                cancelText="Nie"
              >
                <DeleteOutlined key="delete" />
              </Popconfirm>
            </>
          )}
        />
      </Table>
    );
  }
}

export default UsersBansList;
