import vod, { tokenConfig } from "../apis/vod";
import {
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILURE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAILURE,
  INVOICES_LOADING,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILURE,
  INVOICE_FORM_ERRORS,
} from "./_types";
import handleErrors from "./handleErrors";

export const getInvoices = (props = {}) => (dispatch, getState) => {
  dispatch(setInvoicesLoading());
  vod
    .get(`/api/invoices`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_INVOICES_SUCCESS,
        payload: res.data.invoices,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_INVOICES_FAILURE);
    });
};

export const getInvoice = (id) => (dispatch, getState) => {
  dispatch(setInvoicesLoading());
  vod
    .get(`/api/invoices/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_INVOICE_SUCCESS,
        payload: res.data.invoice,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_INVOICE_FAILURE);
    });
};

export const deleteInvoice = (id) => (dispatch, getState) => {
  dispatch(setInvoicesLoading());
  vod
    .delete(`/api/invoices/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_INVOICE_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_INVOICE_FAILURE);
    });
};

export const updateInvoice = (props, id) => (dispatch, getState) => {
  dispatch(setInvoicesLoading());
  vod
    .put(`/api/invoices/${id}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_INVOICE_SUCCESS,
        payload: res.data.invoice,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, UPDATE_INVOICE_FAILURE, INVOICE_FORM_ERRORS);
    });
};

export const setInvoicesLoading = () => {
  return {
    type: INVOICES_LOADING,
  };
};
