import {
  GET_BANS_SUCCESS,
  GET_BANS_FAILURE,
  BANS_LOADING,
  DELETE_BAN_FAILURE,
  DELETE_BAN_SUCCESS,
  ADD_BAN_FAILURE,
  ADD_BAN_SUCCESS,
} from "../actions/_types";

const initialState = {
  bans: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BANS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        bans: action.payload,
      };
    case GET_BANS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_BAN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        bans: state.bans.filter((ban) => ban.id !== action.payload),
      };
    case DELETE_BAN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case ADD_BAN_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        bans: [action.payload, ...state.bans],
      };
    case ADD_BAN_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };
    case BANS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
