import { Card } from "antd";
import React from "react";

class VimeoChat extends React.Component {
  render() {
    if (this.props.chat)
      return (
        <Card>
          <div
            className="chat"
            dangerouslySetInnerHTML={{ __html: this.props.chat }}
          />
        </Card>
      );
    return null;
  }
}

export default VimeoChat;
