import React from "react";
import { Result, Button } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Wrapper from "../wrappers/Wrapper";
import { withTranslation } from "react-i18next";

class ErrorPage extends React.Component {
  render() {
    let { statusCode, message, link, page, t } = this.props;
    statusCode = statusCode ? statusCode : page.statusCode;
    message = message ? message : page.message;
    link = link ? link : page.link;

    return (
      <Wrapper title={t("const.error_occured")}>
        <Result
          status={statusCode ? statusCode : 500}
          title={statusCode ? statusCode : 500}
          subTitle={message ? message : t("errorpage.internal_error")}
          extra={
            link ? (
              <Link to={link}>
                <Button type="primary">
                  {t("errorpage.try_again")}
                </Button>
              </Link>
            ) : (
              <Link to="/">
                <Button type="primary">
                  {t("errorpage.homepage")}
                </Button>
              </Link>
            )
          }
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    page: state.errorPage,
  };
};

export default connect(mapStateToProps)(withTranslation()(ErrorPage));
