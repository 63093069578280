import React from "react";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import { DatePicker, Button, Select, Tooltip, Collapse } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { makeField } from "../../../../form-components/makeComponent";
import Input from "../../../../form-components/Input";
import Checkbox from "../../../../form-components/Checkbox";
import { Link } from "react-router-dom";

class CodeListFilter extends React.Component {
  state = { eventSelect: null, videoSelect: null };
  onSubmit = (formValues) => {
    this.props.onFilterChange(formValues);
  };
  onVideoChange = (v) => {
    this.setState({ eventSelect: null, videoSelect: v });
    this.props.change("video_id", v);
    this.props.change("event_id", null);
  };
  onEventChange = (v) => {
    this.setState({ videoSelect: null, eventSelect: v });
    this.props.change("video_id", null);
    this.props.change("event_id", v);
  };
  onDateRangeChange = (value, dateString) => {
    if (value !== null) {
      this.props.change("expiration_date_from", value[0].format("YYYY-MM-DD"));
      this.props.change("expiration_date_to", value[1].format("YYYY-MM-DD"));
    } else {
      this.props.change("expiration_date_from", null);
      this.props.change("expiration_date_to", null);
    }
  };
  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          label="Zakres dat wygaśnięcia"
          name="rangepicker"
          component={makeField(DatePicker.RangePicker, "DD.MM.YYYY")}
          placeholder={["Od", "Do"]}
          hasFeedback
          onChange={this.onDateRangeChange}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
        />
        <Field
          name="name"
          component={Input}
          placeholder="Kod"
          style={{ width: "200px" }}
        />
        <Field
          name="min_limit"
          component={Input}
          label="Maksymalna liczba wykorzystań"
          type="number"
          style={{ width: "200px" }}
        />
        <Field
          name="available"
          component={Checkbox}
          type="checkbox"
          label="tylko dostępne kody"
          size="big"
        />
        <Collapse ghost style={{ margin: "30px 0", border: "none" }}>
          <Collapse.Panel
            header="Pokaż więcej filtrów"
            key="1"
            style={{ border: "none" }}
          >
            <Field
              name="limit"
              component={Input}
              placeholder="Limit"
              type="number"
              style={{ width: "200px" }}
            />
            <Field
              name="discount"
              component={Input}
              placeholder="Kwota zniżki"
              type="number"
              suffix={
                <Tooltip title="Proszę podać liczbę bez przyrostka 'zł' oraz '%'">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
              style={{ width: "200px" }}
            />
            <label>
              Wybierz wydarzenie: <br />
              <Select
                id="eventSelect"
                defaultValue=""
                allowClear
                style={{ width: "100%" }}
                showSearch
                dropdownMatchSelectWidth={false}
                value={this.state.eventSelect}
                onChange={this.onEventChange}
                optionFilterProp={"children"}
              >
                {this.props.events.map((e) => (
                  <Select.Option key={e.id} value={e.id}>
                    {e.title} {moment(e.date_from).format("DD.MM")}
                    {e.date_to
                      ? `-${moment(e.date_to).format("DD.MM")}`
                      : ""}{" "}
                    <Link target="_blank" to={`/wydarzenie/${e.slug || e.id}`}>
                      link
                    </Link>
                  </Select.Option>
                ))}
              </Select>
            </label>
            <br />
            <br />
            <label>
              Wybierz wideo: <br />
              <Select
                id="videoSelect"
                defaultValue=""
                allowClear
                style={{ width: "100%" }}
                showSearch
                value={this.state.videoSelect}
                onChange={this.onVideoChange}
                optionFilterProp={"children"}
              >
                {this.props.videos.map((e) => (
                  <Select.Option key={e.id} value={e.id}>
                    {e.title}{" "}
                    <Link target="_blank" to={`/wideo/${e.slug || e.id}`}>
                      link
                    </Link>
                  </Select.Option>
                ))}
              </Select>
            </label>
          </Collapse.Panel>
        </Collapse>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginBottom: "30px" }}
        >
          Szukaj
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  return errors;
};

export default reduxForm({ form: "codeListFilterForm", validate })(
  CodeListFilter
);
