import React from "react";
import { Card } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getGalleries,
  getGallery,
  deleteGallery,
  deleteGalleryImage,
  updateGallery,
  createGallery,
  addGalleryImage,
} from "../../../../actions/galleriesActions";
import GalleriesList from "./lists/GalleriesList";
import "../managers.scss";
import GalleryImagesModal from "./others/GalleryImagesModal";

class GalleriesManager extends React.Component {
  state = {
    chosenGallery: null,
    showImageManager: false,
  };
  manageImagesClick = (id) => {
    this.setState({
      chosenGallery: id,
      showImageManager: true,
    });
  };
  render() {
    return (
      <Card style={{ marginBottom: "30px" }} title="Galerie">
        <GalleriesList
          manageImagesClick={this.manageImagesClick}
          getGalleries={this.props.getGalleries}
          createGallery={this.props.createGallery}
          updateGallery={this.props.updateGallery}
          deleteGallery={this.props.deleteGallery}
          loading={this.props.loading}
          galleries={this.props.galleries}
        />
        {this.state.showImageManager ? (
          <GalleryImagesModal
            visible={this.state.showImageManager}
            gallery={_.pick(
              this.props.galleries.filter(
                (e) => e.id === this.state.chosenGallery
              )[0],
              "id",
              "name",
              "images"
            )}
            getGallery={this.props.getGallery}
            deleteGalleryImage={this.props.deleteGalleryImage}
            addGalleryImage={this.props.addGalleryImage}
            closeModal={() =>
              this.setState({ showImageManager: false })
            }
          />
        ) : null}
      </Card>
    );
  }
}
const mapStateToProsp = (state) => {
  return {
    galleries: state.galleries.galleries,
    loading: state.galleries.loading,
  };
};

export default connect(mapStateToProsp, {
  getGalleries,
  getGallery,
  deleteGallery,
  deleteGalleryImage,
  updateGallery,
  createGallery,
  addGalleryImage,
})(GalleriesManager);
