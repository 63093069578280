import React from "react";
import { Skeleton } from "antd";
import { connect } from "react-redux";
import vod from "../../../../apis/vod";
import { getConfig } from "../../../../actions/appConfigActions";
import { updateConfig } from "../../../../helpers/updateConfig";
import ViewsSettings from "./sections/ViewsSettings";
import UrlsSettings from "./sections/UrlsSettings";
import PagesSettings from "./sections/PagesSettings";
import "../managers.scss";

class ContentSettings extends React.Component {
  changeConfig = (data) => {
    return updateConfig(data).then(() => {
      this.props.getConfig();
    });
  };
  changePageContent = (slug, data) => {
    return vod.put(`/api/pages/${slug}`, data, {
      headers: {
        "x-auth-token": this.props.token,
      },
    });
  };
  getPage = (id) => {
    return vod.get(`/api/pages/${id}`);
  };
  componentDidMount() {
    this.props.getConfig();
  }
  render() {
    const config = this.props.config;
    if (!this.props.loading)
      return (
        <>
          <ViewsSettings
            config={config}
            changeConfig={this.changeConfig}
            token={this.props.token}
            key="views-settings"
            handleChangeView={this.props.handleChangeView}
          />
          <UrlsSettings
            config={config}
            changeConfig={this.changeConfig}
            key="urls-settings"
          />
          <PagesSettings
            changePageContent={this.changePageContent}
            getPage={this.getPage}
            token={this.props.token}
          />
        </>
      );
    else return <Skeleton />;
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    configLoading: state.appConfig.loading,
    config: state.appConfig.config,
  };
};

export default connect(mapStateToProps, { getConfig })(ContentSettings);
