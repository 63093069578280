import React from "react";
import { Radio } from "antd";
import CodeForm from "./CodeForm";
import LineBreak from "../../../../LineBreak";

class CodeFormWrapper extends React.Component {
  state = {
    mode: this.props.mode,
    codeType: "single",
    productType: "event",
    chosenEvent: null,
    chosenVideo: null,
  };
  render() {
    if (this.state.mode === "add")
      return (
        <>
          <Radio.Group
            onChange={(e) => {
              this.setState({ codeType: e.target.value });
            }}
            value={this.state.codeType}
          >
            <Radio value={"single"} defaultChecked={true}>
              Kupon wielokrotnego użytku
            </Radio>
            <Radio value={"multiple"}>
              Lista jednorazowych kuponów
            </Radio>
          </Radio.Group>
          <LineBreak additionalStyles={{ margin: "30px 0" }} />
          <CodeForm
            onSubmit={this.props.renderCodes}
            initialValues={
              this.state.codeType !== "single" ? {} : { limit: 1 }
            }
            multipleRender={this.state.codeType !== "single"}
            videos={this.props.videos}
            events={this.props.events}
          />
        </>
      );
    else
      return (
        <CodeForm
          onSubmit={this.props.updateCode}
          initialValues={this.props.initialValues}
          multipleRender={false}
          videos={this.props.videos}
          events={this.props.events}
        />
      );
  }
}

export default CodeFormWrapper;
