import React from "react";
import { Form, Input as AInput } from "antd";
import "./Input.scss";
import { useTranslation } from "react-i18next";

const Input = ({
  input,
  placeholder,
  meta,
  type,
  prefix,
  suffix,
  required,
  allowClear,
  ...rest
}) => {
  const { t } = useTranslation();
  const error = meta.touched && meta.error !== null ? meta.error : null;
  return (
    <Form.Item
      className={`teapp-input-wrapper ${required ? "required" : ""}`}
      colon
      help={error ? t(error) : null}
      validateStatus={error ? "error" : null}
      hasFeedback
      {...rest}
    >
      <AInput
        allowClear={allowClear || false}
        className="teapp-input"
        prefix={prefix}
        suffix={suffix}
        {...input}
        type={type}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default Input;
