import React from "react";
import moment from "moment";
import vod from "../../../../apis/vod";
import { connect } from "react-redux";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import { Row, Col, List, Card, DatePicker, Table, Tag, Button } from "antd";
import { Link } from "react-router-dom";
import {
  checkEventStatusByDate,
  getDatetime,
  getDatetimeRange,
} from "../../../../helpers/productUtilities";
import Column from "antd/lib/table/Column";
import { CSVLink } from "react-csv";

const generateRandomColor = () => {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};
const generateRandomColors = (number) => {
  let colors = [];
  for (let i = 0; i < number; i++) {
    colors[i] = generateRandomColor();
  }
  return colors;
};
const getDatesBetweenDates = (startDate, endDate) => {
  let dates = [];
  const theDate = moment(startDate, "YYYYY-MM-DD");
  endDate = moment(endDate, "YYYY-MM-DD");
  while (theDate.isBefore(endDate)) {
    dates = [...dates, theDate.format("DD.MM.YYYY")];
    theDate.add(1, "days");
  }
  return dates;
};
class StatisticsManager extends React.Component {
  state = {
    dateFrom: moment().subtract(7, "days").format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
    incomeDateFrom: moment().startOf("month").format("YYYY-MM-DD"),
    incomeDateTo: moment().endOf("month").format("YYYY-MM-DD"),
    stOrders: {},
    stIncome: {},
    data1: {},
    data2: {},
    data3: [],
    data4: [],
    eventsSumm: [],
    videosSumm: [],
    eventsCsvData: [],
    videosCsvData: [],
    incomeSumm: null,
    incomeData: [],
    incomeCsvData: [],
  };
  componentDidMount() {
    const { dateFrom, dateTo, incomeDateFrom, incomeDateTo } = this.state;
    this.countOrders({ dateFrom, dateTo });
    this.countIncome({ dateFrom, dateTo });
    this.ordersPerVideo();
    this.viewsPerVideo();
    this.getEventsSumm();
    this.getVideosSumm();
    this.getIncomeSumm({ incomeDateFrom, incomeDateTo });
  }

  countOrders = (props) => {
    vod
      .get(
        `/api/statistics/orders?from=${this.state.dateFrom}&to=${this.state.dateTo}`,
        {
          headers: {
            "x-auth-token": this.props.token,
          },
        }
      )
      .then((res) => {
        const dataset = getDatesBetweenDates(
          this.state.dateFrom,
          this.state.dateTo
        ).map((date) => {
          let count = 0;
          const stDate = res.data.data.find((obj) => obj.date === date);
          if (stDate) count = stDate.count;
          return { date, count };
        });
        let data = {
          labels: dataset.map((row) => row.date),
          datasets: [
            {
              label: `Liczba zamówień (${this.state.dateFrom}–${this.state.dateTo})`,
              fill: false,
              lineTension: 0.1,
              backgroundColor: "rgba(75,192,192,0.4)",
              borderColor: "rgba(75,192,192,1)",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(75,192,192,1)",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(75,192,192,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: dataset.map((row) => row.count),
            },
          ],
        };
        this.setState({ stOrders: data });
      });
  };
  countIncome = () => {
    vod
      .get(
        `/api/statistics/income?from=${this.state.dateFrom}&to=${this.state.dateTo}`,
        {
          headers: {
            "x-auth-token": this.props.token,
          },
        }
      )
      .then((res) => {
        const dataset = getDatesBetweenDates(
          this.state.dateFrom,
          this.state.dateTo
        ).map((date) => {
          let summ = 0;
          const stDate = res.data.data.find((obj) => obj.date === date);
          if (stDate) summ = stDate.summ;
          return { date, summ };
        });
        let data = {
          labels: dataset.map((row) => row.date),
          datasets: [
            {
              label: `Przychody [zł] (${this.state.dateFrom}–${this.state.dateTo})`,
              fill: false,
              lineTension: 0.1,
              backgroundColor: "rgba(75,192,192,0.4)",
              borderColor: "#36A2EB",
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "#36A2EB",
              pointBackgroundColor: "#36A2EB",
              pointBorderWidth: 5,
              pointHoverRadius: 10,
              pointHoverBackgroundColor: "rgba(75,192,192,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: dataset.map((row) => row.summ),
            },
          ],
        };
        this.setState({ stIncome: data });
      });
  };
  ordersPerVideo = () => {
    vod
      .get(`/api/statistics/ordersPerVideo`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        let { labels, data } = res.data;
        const colors = generateRandomColors(res.data.labels.length);
        let data1 = {
          labels: labels.map((l) =>
            l.length > 70 ? l.substr(0, 70) + "..." : l
          ),
          datasets: [
            {
              data,
              backgroundColor: colors,
              hoverBackgroundColor: colors,
            },
          ],
        };
        let swapped;
        do {
          swapped = false;
          for (let i = 0; i < labels.length; i++) {
            if (data[i] < data[i + 1]) {
              let tmp = data[i];
              data[i] = data[i + 1];
              data[i + 1] = tmp;
              let tmp2 = labels[i];
              labels[i] = labels[i + 1];
              labels[i + 1] = tmp2;
              swapped = true;
            }
          }
        } while (swapped);
        let data4 = [];
        for (let i = 0; i < labels.length; i++) {
          data4[i] = {
            id: i + 1,
            title: labels[i],
            count: Math.floor(data[i] * 100) / 100,
          };
        }

        this.setState({ data1, data4 });
      });
  };
  viewsPerVideo = () => {
    vod
      .get(`/api/statistics/viewsPerVideo`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        const colors = generateRandomColors(res.data.labels.length);
        let data2 = {
          labels: res.data.labels.map((l) =>
            l.length > 70 ? l.substr(0, 70) + "..." : l
          ),
          datasets: [
            {
              data: res.data.data,
              backgroundColor: colors,
              hoverBackgroundColor: colors,
            },
          ],
        };
        let data3 = [];
        for (let i = 0; i < res.data.labels.length; i++) {
          const createdDate = moment(
            res.data.created_dates[i],
            "YYYY-MM-DD HH:mm"
          );
          const dateDiff = moment().diff(createdDate, "days");
          data3[i] = {
            id: i,
            title: res.data.labels[i],
            count:
              Math.floor(
                (res.data.data[i] / (dateDiff === 0 ? 1 : dateDiff)) * 100
              ) / 100,
          };
        }

        this.setState({ data2, data3 });
      });
  };
  getTimeRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  getDateRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  getEventsSumm = async () => {
    const response = await vod.get(`/api/statistics/events`, {
      headers: {
        "x-auth-token": this.props.token,
      },
    });
    let csvData = [
      [
        "tytuł",
        "data",
        "zakres godzin",
        "liczba unikalnych wyświetleń",
        "suma wpływów",
        "liczba osób z dostępem",
        "liczba zrealizowanych płatnych zamówień",
      ],
    ];
    let dataSource = response.data.events.map((e) => {
      const eventStatus = checkEventStatusByDate(
        e.date_from,
        e.date_to,
        e.full_day,
        e.time_from,
        e.time_to
      );
      switch (eventStatus) {
        case -1:
          e.timeStatus = "Zakończone";
          break;
        case 0:
          e.timeStatus = "Wkrótce";
          break;
        case 1:
          e.timeStatus = "W trakcie";
          break;
        default:
          break;
      }
      e.key = e.id;
      e.income = e.orders
        .map((o) => {
          if (o.status === "COMPLETED") return Number.parseFloat(o.amount);
          return 0;
        })
        .reduce((a, b) => a + b, 0)
        .toString()
        .replace(".", ",");
      e.numberOfOrders = e.orders.length;
      e.numberOfCompletedOrders = e.orders.filter(
        (o) => o.status === "COMPLETED"
      ).length;
      e.payments = e.orders.filter(
        (o) => o.status === "COMPLETED" && o.amount > 0
      ).length;
      e.usedOrders = e.orders.filter((o) => o.used === 1).length;
      csvData.push([
        e.title,
        `${moment(e.date_from).format("DD.MM.YYYY")}${
          e.date_from !== e.date_to
            ? `-${moment(e.date_from).format("DD.MM.YYYY")}`
            : ""
        }`,
        `${e.full_day ? " cały dzień" : ` ${e.time_from}-${e.time_to}`}`,
        e.views,
        parseFloat(e.income).toFixed(2).toString().replace(".", ","),
        e.numberOfCompletedOrders,
        e.payments,
      ]);
      return e;
    });
    this.setState({ eventsSumm: dataSource, eventsCsvData: csvData });
  };

  getVideosSumm = async () => {
    const response = await vod.get(`/api/statistics/videos`, {
      headers: {
        "x-auth-token": this.props.token,
      },
    });
    let csvData = [
      [
        "tytuł",
        "liczba unikalnych wyświetleń",
        "suma wpływów",
        "liczba osób z dostępem",
        "liczba zrealizowanych płatnych zamówień",
      ],
    ];
    let dataSource = response.data.videos.map((v) => {
      v.key = v.id;
      v.income = v.orders
        .map((o) => {
          if (o.status === "COMPLETED") return Number.parseFloat(o.amount);
          return 0;
        })
        .reduce((a, b) => a + b, 0)
        .toString()
        .replace(".", ",");
      v.payments = v.orders.filter(
        (o) => o.status === "COMPLETED" && o.amount > 0
      ).length;
      v.numberOfOrders = v.orders.length;
      v.numberOfCompletedOrders = v.orders.filter(
        (o) => o.status === "COMPLETED"
      ).length;
      v.usedOrders = v.orders.filter((o) => o.used === 1).length;
      csvData.push([
        v.title,
        v.views,
        parseFloat(v.income).toFixed(2).toString().replace(".", ","),
        v.numberOfCompletedOrders,
        v.payments,
      ]);
      return v;
    });
    this.setState({ videosSumm: dataSource, videosCsvData: csvData });
  };
  getIncomeSumm = async () => {
    const response = await vod.get(
      `/api/statistics/paymentsumm?from=${this.state.dateFrom}&to=${this.state.dateTo}`,
      {
        headers: {
          "x-auth-token": this.props.token,
        },
      }
    );
    const eventsDataSource = response.data.eventsSumm.map((r) => {
      r.nodeType = "wydarzenie";
      r.key = "e" + r.event_id;
      return r;
    });
    const videosDataSource = response.data.videosSumm.map((r) => {
      r.nodeType = "vod";
      r.key = "v" + r.video_id;
      return r;
    });
    const dataSource = [].concat(eventsDataSource, videosDataSource);
    const incomeSumm = dataSource
      .map((r) => {
        return Number.parseFloat(r.income);
      })
      .reduce((a, b) => a + b, 0)
      .toString()
      .replace(".", ",");
    const { incomeDateFrom, incomeDateTo } = this.state;
    let csvData = [
      ["zakres dat", "suma wpływów"],
      [
        `${moment(incomeDateFrom).format("DD.MM.YYYY")}-${moment(
          incomeDateTo
        ).format("DD.MM.YYYY")}`,
        incomeSumm,
      ],
      [],
      ["zawartość", "tytuł", "suma wpływów"],
    ];
    dataSource.forEach((r) => {
      csvData.push([
        r.nodeType,
        r.nodeType === "wydarzenie"
          ? `${r.title} (${r.full_day ? "cały dzień" : r.time_from} ${moment(
              r.date_from
            ).format("DD.MM.YYYY")})`
          : r.title,
        parseFloat(r.income).toFixed(2).toString().replace(".", ","),
      ]);
    });

    this.setState({ incomeSumm: dataSource, incomeCsvData: csvData });
  };
  onDateRangeChange = (value, dateString) => {
    if (value !== null)
      this.setState({
        dateFrom: value[0].format("YYYY-MM-DD"),
        dateTo: value[1].format("YYYY-MM-DD"),
      });
    else
      this.setState({
        dateFrom: moment().subtract(7, "days").format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      });
  };
  componentDidUpdate(prevProps, prevState) {
    const { dateFrom, dateTo } = this.state;
    if (prevState.dateFrom !== dateFrom || prevState.dateTo !== dateTo) {
      this.countOrders({ dateFrom, dateTo });
      this.countIncome({ dateFrom, dateTo });
    }
  }
  handleVideosTableChange = (pagination, filters, sorter, extra) => {
    let csvData = [
      [
        "tytuł",
        "liczba unikalnych wyświetleń",
        "suma wpływów",
        "liczba osób z dostępem",
        "liczba zrealizowanych płatnych zamówień",
      ],
    ];
    extra.currentDataSource.forEach((r) => {
      csvData.push([
        r.title,
        r.views,
        parseFloat(r.income).toFixed(2).toString().replace(".", ","),
        r.numberOfCompletedOrders,
        r.payments,
      ]);
    });
    this.setState({ videosCsvData: csvData });
  };
  handleEventsTableChange = (pagination, filters, sorter, extra) => {
    let csvData = [
      [
        "tytuł",
        "data",
        "zakres godzin",
        "liczba unikalnych wyświetleń",
        "suma wpływów",
        "liczba osób z dostępem",
        "liczba zrealizowanych płatnych zamówień",
      ],
    ];
    extra.currentDataSource.forEach((r) => {
      csvData.push([
        r.title,
        `${moment(r.date_from).format("DD.MM.YYYY")}${
          r.date_from !== r.date_to
            ? `-${moment(r.date_from).format("DD.MM.YYYY")}`
            : ""
        }`,
        `${r.full_day ? " cały dzień" : ` ${r.time_from}-${r.time_to}`}`,
        r.views,
        parseFloat(r.income).toFixed(2).toString().replace(".", ","),
        r.numberOfCompletedOrders,
        r.payments,
      ]);
    });
    this.setState({ eventsCsvData: csvData });
  };
  render() {
    return (
      <>
        <Card
          style={{ marginBottom: "30px" }}
          title="Statystyki dla podanego zakresu"
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              paddingBottom: 5,
            }}
          >
            <Row gutter={[15, 55]}>
              <Col lg={24}>
                <form>
                  <label style={{ marginRight: 10, display: "inline-block" }}>
                    Zakres dat:
                  </label>
                  <DatePicker.RangePicker
                    label="Zakres dat"
                    placeholder={["Od", "Do"]}
                    hasFeedback
                    onChange={this.onDateRangeChange}
                    onFocus={(e) => e.preventDefault()}
                    onBlur={(e) => e.preventDefault()}
                  />
                </form>
              </Col>
            </Row>
            <Button type="primary" style={{ marginBottom: 30 }}>
              <CSVLink
                data={this.state.incomeCsvData}
                separator={";"}
                filename="Podsumowanie wpływów.csv"
              >
                Eksportuj podsumowanie do CSV
              </CSVLink>
            </Button>
            <Row gutter={[15, 55]}>
              <Col lg={12}>
                <h2>Liczba zamówień</h2>
                {this.state.stOrders ? (
                  <Bar
                    data={this.state.stOrders}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }}
                  />
                ) : null}
              </Col>
              <Col lg={12}>
                <h2>Przychody</h2>
                {this.state.stIncome ? (
                  <Line data={this.state.stIncome} />
                ) : null}
              </Col>
            </Row>
          </div>
        </Card>
        <Card
          style={{ marginBottom: "30px" }}
          title="Statystyki dla wideo (uwzględnione dane w ramach wszystkich wydarzeń oraz sekcji VOD)"
        >
          <div style={{ backgroundColor: "white", padding: "20px" }}>
            <Row gutter={[15, 55]}>
              <Col lg={24}>
                <h2>Liczba zamówień dla poszczególnych wideo</h2>
                <br />
                {this.state.data1 ? (
                  <Doughnut
                    options={{
                      legend: {
                        position: "right",
                      },
                    }}
                    data={this.state.data1}
                  />
                ) : null}
              </Col>
              <Col lg={24}>
                <h2>Popularność wideo pod względem liczby wyświetleń</h2>
                <br />
                {this.state.data2 ? (
                  <Doughnut
                    options={{
                      legend: {
                        position: "right",
                      },
                    }}
                    data={this.state.data2}
                  />
                ) : null}
              </Col>
            </Row>
            <Row gutter={[15, 55]}>
              <Col lg={12}>
                <h2>Średnia liczba wyświetleń na dzień</h2>
                <List
                  size="small"
                  dataSource={this.state.data3}
                  renderItem={(item) => (
                    <List.Item>
                      <p>{item.title}</p>
                      <strong>{item.count}</strong>
                    </List.Item>
                  )}
                />
              </Col>
              <Col lg={12}>
                <h2>Ranking wideo pod względem liczby zamówień</h2>
                <List
                  size="small"
                  dataSource={this.state.data4}
                  renderItem={(item) => (
                    <List.Item>
                      <p>
                        <strong style={{ marginRight: 10 }}>#{item.id}</strong>
                        {item.title}
                      </p>
                      <strong>{item.count}</strong>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </div>
        </Card>
        <Card style={{ marginBottom: "30px" }} title="Podsumowanie wydarzeń">
          <div style={{ backgroundColor: "white", padding: "20px" }}>
            <Button>
              <CSVLink
                data={this.state.eventsCsvData}
                separator={";"}
                filename="Podsumowanie wydarzeń.csv"
              >
                Eksportuj do CSV
              </CSVLink>
            </Button>
            <Table
              dataSource={this.state.eventsSumm}
              pagination={{ defaultPageSize: 20 }}
              onChange={this.handleEventsTableChange}
              expandable={{
                expandedRowRender: (r) => {
                  let statistics = [
                    {
                      key: "Liczba unikalnych wyświetleń",
                      value: r.views,
                    },
                    {
                      key: "Liczba złożonych zamówień",
                      value: r.numberOfOrders,
                    },
                    {
                      key: "Liczba zrealizowanych zamówień",
                      value: r.numberOfCompletedOrders,
                    },
                    {
                      key: "Liczba zrealizowanych, płatnych zamówień",
                      value: r.payments,
                    },
                    {
                      key: "Liczba wykorzystanych zamówień",
                      value: r.usedOrders,
                    },
                    {
                      key: "Suma wpływów",
                      value: `${r.income} zł`,
                    },
                  ];
                  return (
                    <List
                      itemLayout="horizontal"
                      size="small"
                      dataSource={statistics}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <p style={{ marginBottom: 0 }}>
                                <strong>{item.key}: </strong>
                                {item.value}
                              </p>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  );
                },
                rowExpandable: (record) => record.title !== "Not Expandable",
              }}
            >
              <Column
                title="Tytuł"
                dataIndex="title"
                key="title"
                sorter={(a, b) => a.title.localeCompare(b.title)}
                render={(v, r) => {
                  let color = "";
                  switch (r.timeStatus) {
                    case "W trakcie":
                      color = "success";
                      break;
                    case "Zakończone":
                      color = "warning";
                      break;
                    default:
                      color = "processing";
                      break;
                  }
                  return (
                    <Link to={`/wydarzenie/${r.slug || r.id}`}>
                      {v}
                      <Tag style={{ marginLeft: 15 }} color={color}>
                        {r.timeStatus}
                      </Tag>
                    </Link>
                  );
                }}
              />
              <Column
                title="Zakres dat"
                dataIndex="range"
                key="range"
                sorter={(a, b) => {
                  const dateA = getDatetime(a.date_from, a.time_from);
                  const dateB = getDatetime(b.date_from, b.time_from);
                  return dateA.unix() - dateB.unix();
                }}
                render={(v, r) => {
                  const datetimeRange = getDatetimeRange(
                    r.date_from,
                    r.date_to,
                    r.time_from,
                    r.time_to,
                    r.full_day
                  );
                  const time =
                    datetimeRange.from.format("HH:mm") === "00:00" &&
                    datetimeRange.to.format("HH:mm") === "23:59"
                      ? "Cały dzień"
                      : this.getTimeRange(
                          datetimeRange.from.format("H:mm"),
                          datetimeRange.to.format("H:mm")
                        );
                  const date = this.getDateRange(
                    datetimeRange.from.format("DD.MM.YYYY"),
                    datetimeRange.to.format("DD.MM.YYYY")
                  );
                  return (
                    <div>
                      {time}, {date}
                    </div>
                  );
                }}
              />
              <Column
                title="Liczba zrealizowanych, płatnych zamówień"
                dataIndex="payments"
                key="payments"
                sorter={(a, b) => a.payments - b.payments}
                render={(v, r) => `${v}`}
              />
              <Column
                title="Suma wpływów"
                dataIndex="income"
                key="income"
                sorter={(a, b) => a.income - b.income}
                render={(v, r) => `${v} zł`}
              />
            </Table>
          </div>
        </Card>
        <Card style={{ marginBottom: "30px" }} title="Podsumowanie wideo">
          <div style={{ backgroundColor: "white", padding: "20px" }}>
            <Button>
              <CSVLink
                data={this.state.videosCsvData}
                separator={";"}
                filename="Podsumowanie wideo.csv"
              >
                Eksportuj do CSV
              </CSVLink>
            </Button>
            <Table
              dataSource={this.state.videosSumm}
              pagination={{ defaultPageSize: 20 }}
              onChange={this.handleVideosTableChange}
              expandable={{
                expandedRowRender: (r) => {
                  let statistics = [
                    {
                      key: "Liczba unikalnych wyświetleń",
                      value: r.views,
                    },
                    {
                      key: "Liczba złożonych zamówień",
                      value: r.numberOfOrders,
                    },
                    {
                      key: "Liczba zrealizowanych zamówień",
                      value: r.numberOfCompletedOrders,
                    },
                    {
                      key: "Liczba zrealizowanych, płatnych zamówień",
                      value: r.payments,
                    },
                    {
                      key: "Liczba wykorzystanych zamówień",
                      value: r.usedOrders,
                    },
                    {
                      key: "Suma wpływów",
                      value: `${r.income} zł`,
                    },
                  ];
                  return (
                    <List
                      itemLayout="horizontal"
                      size="small"
                      dataSource={statistics}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <p style={{ marginBottom: 0 }}>
                                <strong>{item.key}: </strong>
                                {item.value}
                              </p>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  );
                },
                rowExpandable: (record) => record.title !== "Not Expandable",
              }}
            >
              <Column
                title="Tytuł"
                dataIndex="title"
                key="title"
                sorter={(a, b) => a.title.localeCompare(b.title)}
                render={(v, r) => (
                  <Link to={`/wideo/${r.slug || r.id}`}>{v}</Link>
                )}
              />
              <Column
                title="Utworzono"
                dataIndex="added_at"
                key="added_at"
                sorter={(a, b) => {
                  const dateA = moment(a.added_at);
                  const dateB = moment(b.added_at);
                  return dateA.unix() - dateB.unix();
                }}
                render={(v, r) => moment(v).format("H:m, DD.MM.YYYY")}
              />
              <Column
                title="Liczba zrealizowanych, płatnych zamówień"
                dataIndex="payments"
                key="payments"
                sorter={(a, b) => a.payments - b.payments}
                render={(v, r) => `${v}`}
              />
              <Column
                title="Suma wpływów"
                dataIndex="income"
                key="income"
                sorter={(a, b) => a.income - b.income}
                render={(v, r) => `${v} zł`}
              />
            </Table>
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(StatisticsManager);
