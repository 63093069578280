import React from "react";
import { Form, Checkbox as ACheckbox } from "antd";
import "./Checkbox.scss";

const Checkbox = ({
  checked,
  input,
  label,
  size,
  meta,
  suffix,
  ...rest
}) => {
  const style = meta.touched && meta.error ? { color: "red" } : null;
  return (
    <Form.Item
      className={`teapp-checkbox-wrapper ${
        size ? "teapp-checkbox-wrapper-" + size : null
      }`}
      {...rest}
      style={{ marginBottom: 0 }}
    >
      <ACheckbox
        className={`teapp-checkbox ${
          size ? "teapp-checkbox-" + size : null
        }`}
        style={style}
        {...input}
      >
        {/* {label} */}
        <div
          style={{ float: "left" }}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        {suffix ? (
          <div style={{ float: "right", marginLeft: "10px" }}>
            {suffix}
          </div>
        ) : (
          ""
        )}
      </ACheckbox>
    </Form.Item>
  );
};

export default Checkbox;
