import {
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
  CONFIG_LOADING,
} from "../actions/_types";

const initialState = {
  config: {},
  loading: false,
  error: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        getSuccess: true,
        config: { ...state.config, ...action.payload },
      };
    case GET_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload,
        getSuccess: false,
        loading: false,
      };
    case CONFIG_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
