import React from "react";
import { connect } from "react-redux";
import { Alert, Button } from "antd";
import moment from "moment";
import history from "../../history";
import { useTranslation } from "react-i18next";

const BirthdayChangeInform = (props) => {
  const { t } = useTranslation();
  if (props.birthday === null || moment(props.birthday).year() === 1900)
    return (
      <Alert
        className="ant-alert-warning"
        message={t("const.warning")}
        description={
          <>
            {t("birthday.text")}
            <br />
            <br />
            <Button
              onClick={() => {
                history.push("/konto?edit=true");
              }}
              type="primary"
            >
              {t("birthday.go_settings")}
            </Button>
          </>
        }
        type="warning"
        showIcon
        style={{ margin: "0 auto", maxWidth: 2320, marginBottom: 30 }}
      />
    );
  else return null;
};

const mapStateToProps = (state) => {
  return {
    birthday: state.auth.user ? state.auth.user.birthday : null,
  };
};

export default connect(mapStateToProps)(BirthdayChangeInform);
