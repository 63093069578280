import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import Input from "../../form-components/Input";
import Button from "../../form-components/Button";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";

class ResetPasswordForm extends Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  render() {
    const { handleSubmit, t } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="input-group">
          <Field
            name="email"
            type="text"
            placeholder={t("form.placeholder.email")}
            component={Input}
            suffix={<MailOutlined className="site-form-item-icon" />}
          />
        </div>
        <Row>
          <Col span={12}>
            <Link to="/logowanie">{t("newpasswd.return")}</Link>
          </Col>
          <Col span={12}>
            <Button type="primary" htmlType="submit">
              {t("newpasswd.reset")}
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email)
    errors.email = i18n.t("form.validate.required");
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      formValues.email
    )
  )
    errors.email = i18n.t("form.validate.email");
  return errors;
};

export default reduxForm({ form: "resetPasswordForm", validate })(
  withTranslation()(ResetPasswordForm)
);
