import React from "react";
import { Redirect, Route } from "react-router-dom";
import { noAuthRedirect } from "../../theme-variables";

const PrivateRoute = ({ component: Component, home, ...rest }) => {
  const pathname = window.location.pathname;
  if (
    !pathname.includes("publiczne") &&
    (pathname.includes("/wydarzenie/") ||
      pathname.includes("/wideo/")) &&
    !localStorage.getItem("user")
  ) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: pathname + "/publiczne",
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: home ? noAuthRedirect : "/logowanie",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
