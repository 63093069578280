import React from "react";
import { Button } from "antd";
import "./SingleButton.scss";

const SingleButton = (props) => {
  return (
    <Button
      {...props}
      className={`single-button ${
        props.className ? props.className : null
      }`}
    >
      {props.children}
    </Button>
  );
};

export default SingleButton;
