import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Result } from "antd";
import { getConfig } from "../../../actions/appConfigActions";
import Wrapper from "../../wrappers/Wrapper";
import { withTranslation } from "react-i18next";

class SignInLink extends React.Component {
  componentDidMount() {
    this.props.getConfig(false);
  }
  render() {
    const { t } = this.props;
    return (
      <Wrapper
        title={t("signin.title")}
        bg={this.props.appConfig.noauth_bg_image}
        authLayout={true}
      >
        <Result status="success" title="Wysłano link do logowania" />
        <br />
        <div style={{ textAlign: "center" }}>
          <br />
          <Link to="/logowanie">
            <Button type="default">{t("signin.title")}</Button>
          </Link>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appConfig: state.appConfig.config,
  };
};

export default connect(mapStateToProps, {
  getConfig,
})(withTranslation()(SignInLink));
