import React from "react";
import Input from "../../../../form-components/Input";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { Button } from "antd";

const renderTextArea = ({ input, meta: { touched, error } }) => (
  <div>
    <textarea
      {...input}
      placeholder="Tekst slajdu"
      style={{ width: "100%", minHeight: 100 }}
    ></textarea>
    {touched && error && (
      <span style={{ color: "red " }}>{error}</span>
    )}
    <br />
    <br />
  </div>
);

class SlideTranslationForm extends React.Component {
  state = {};
  handleFormSubmit = async (formValues) => {
    this.props.onSubmit(
      _.pick(formValues, "title", "text", "language_code", "slide_id")
    );
  };
  render() {
    const { mode } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
        <h2>{mode === "edit" ? "Edytuj" : "Dodaj"} tłumaczenie:</h2>
        <Field
          name="language_code"
          component={Input}
          placeholder="Język (np. en)"
          type="text"
          style={{ display: mode === "edit" ? "none" : "block" }}
        />
        <Field
          name="title"
          component={Input}
          placeholder="Tytuł"
          type="text"
        />
        <Field
          name="text"
          component={renderTextArea}
          placeholder="Tekst slajdu"
        />
        <Button type="primary" htmlType="submit" block="true">
          {this.props.mode === "add" ? "Dodaj" : "Zaktualizuj"}
        </Button>
      </form>
    );
  }
}

const validate = ({ title, text, language_code }) => {
  const errors = {};
  if (title && title.length > 100)
    errors.title = "Maksymalnie 100 znaków.";
  if (text && text.length > 500)
    errors.text = "Maksymalnie 500 znaków.";
  if (!language_code) errors.language_code = "Proszę wybrać język.";
  else if (language_code.length !== 2)
    errors.language_code = "Niepoprawny język";
  return errors;
};

export default reduxForm({ form: "slideTranslationForm", validate })(
  SlideTranslationForm
);
