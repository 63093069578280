import React from "react";
import { connect } from "react-redux";
import { Card, Button, Drawer, Table, Tag, Popconfirm, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import {
  getVideos,
  deleteVideo,
  addVideo,
  updateVideo,
} from "../../../../../actions/vimeoActions";
import VimeoForm from "../forms/VimeoForm";
import VimeoContinueUploadForm from "../forms/VimeoContinueUploadForm";
import SubtitlesModal from "../others/SubtitlesModal";

class VimeoVideoList extends React.Component {
  state = {
    videos: [],
    formVisible: false,
    continueUploadVisible: false,
    manageSubtitlesVisible: false,
    chosenVideo: null,
  };
  fetchVideos = () => {
    this.props.getVideos();
  };
  componentDidMount() {
    this.fetchVideos();
  }
  showDrawer = () => {
    this.setState({ formVisible: true });
  };
  onClose = () => {
    this.fetchVideos();
    this.setState({ formVisible: false });
  };
  onContinueUploadClose = () => {
    this.fetchVideos();
    this.setState({ continueUploadVisible: false });
  };
  getChosenVideo = (id) => {
    const videos = this.props.videos;
    for (let i = 0; i < videos.length; i++)
      if (videos[i].id === id) return videos[i];
    return null;
  };
  showContinueUpload = (id) => {
    this.setState({
      continueUploadVisible: true,
      chosenVideo: this.getChosenVideo(id),
    });
  };
  manageSubtitles = (id) => {
    this.setState({
      manageSubtitlesVisible: true,
      chosenVideo: this.getChosenVideo(id),
    });
  };
  render() {
    const dataSource = this.props.videos.map((v) => {
      v.key = v.id;
      return v;
    });
    return (
      <>
        <Card
          style={{ marginBottom: "30px" }}
          title="Lista przesłanych plików wideo"
          extra={
            <Button type="primary" onClick={this.showDrawer}>
              Umieść wideo w serwisie Vimeo
            </Button>
          }
        >
          <Table
            dataSource={dataSource}
            size="small"
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50"],
            }}
          >
            <Column title="Nazwa" dataIndex="name" key="name" />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              render={(status, record) => {
                switch (status) {
                  case "NOT_UPLOADED":
                    return (
                      <>
                        <Tag color="volcano" key={status}>
                          Nie ukończono
                        </Tag>

                        <Button
                          onClick={() => this.showContinueUpload(record.id)}
                          type="primary"
                          size="small"
                        >
                          Kontynuuj transfer
                        </Button>
                      </>
                    );
                  default:
                    return (
                      <Tag color="green" key={status}>
                        Ukończono
                      </Tag>
                    );
                }
              }}
            />
            <Column
              title="Operacje"
              key="action"
              render={(v, record) => (
                <>
                  <Tooltip title="Zarządzaj napisami do wideo">
                    <FileTextOutlined
                      onClick={() => this.manageSubtitles(record.id)}
                      key="manage-subtitles"
                      style={{ fontSize: 20, marginRight: 10 }}
                    />
                  </Tooltip>
                  <Tooltip title="Usuń wideo">
                    <Popconfirm
                      title={`Czy na pewno chcesz usunąć wideo "${record.name}" z listy? Wideo nie zostanie usunięte z serwisu Vimeo.`}
                      onConfirm={() => this.props.deleteVideo(record.id)}
                      okText="Tak"
                      cancelText="Nie"
                    >
                      <DeleteOutlined key="delete" style={{ fontSize: 20 }} />
                    </Popconfirm>
                  </Tooltip>
                </>
              )}
            />
          </Table>
        </Card>
        <Drawer
          className="formDrawer"
          width="90%"
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.state.formVisible}
          maskClosable={false}
        >
          {this.state.formVisible ? (
            <VimeoForm onClose={this.onClose} token={this.props.token} />
          ) : null}
        </Drawer>
        <Drawer
          className="formDrawer"
          width="90%"
          placement="right"
          closable={true}
          onClose={this.onContinueUploadClose}
          visible={this.state.continueUploadVisible}
          maskClosable={false}
        >
          {this.state.continueUploadVisible ? (
            <VimeoContinueUploadForm
              video={this.state.chosenVideo}
              onClose={this.onContinueUploadClose}
              token={this.props.token}
            />
          ) : null}
        </Drawer>
        {this.state.chosenVideo ? (
          <SubtitlesModal
            visible={this.state.manageSubtitlesVisible}
            video={this.state.chosenVideo}
            closeModal={() =>
              this.setState({
                manageSubtitlesVisible: false,
                chosenVideo: null,
              })
            }
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProsp = (state) => {
  return {
    videos: state.vimeo.videos,
    success: state.vimeo.success,
    token: state.auth.token,
    newvideo: state.vimeo.newvideo,
  };
};

export default connect(mapStateToProsp, {
  addVideo,
  updateVideo,
  getVideos,
  deleteVideo,
})(VimeoVideoList);
