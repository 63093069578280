import React from "react";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import { DatePicker } from "antd";
import Input from "../../../../form-components/Input";
import Checkbox from "../../../../form-components/Checkbox";
import Button from "../../../../form-components/Button";
import { makeField } from "../../../../form-components/makeComponent";

class BanForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <p>Podaj dowolną datę jeżeli blokada konta na stałę</p>
        <Field
          name="reason"
          component={Input}
          required={true}
          placeholder="Powód"
        />
        <Field
          className="datePicker"
          showTime
          name="expiration_date"
          component={makeField(DatePicker, "HH:mm DD.MM.YYYY", false)}
          label={"Data wygaśnięcia:"}
          hasFeedback
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
        />
        <div className="checkbox-group">
          <Field
            name="forever"
            component={Checkbox}
            type="checkbox"
            label="blokada konta na stałę"
          />
          <Field
            name="send_email"
            component={Checkbox}
            type="checkbox"
            label="wyślij maila do użytkownika z powodem zablokowania konta"
          />
        </div>
        <Button
          type="primary"
          htmlType="submit"
          style={{ maxWidth: "300px" }}
        >
          Dodaj{" "}
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (
    formValues.expiration_date === null &&
    !moment(formValues.expiration_date).isValid()
  )
    errors.expiration_date = "Nieprawidłowa data.";

  if (moment(formValues.expiration_date).isBefore(moment()))
    errors.expiration_date = "Prosze podać datę w przyszłości.";

  return errors;
};

export default reduxForm({ form: "banForm", validate })(BanForm);
