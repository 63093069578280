import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { Alert, Row, Col } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import Input from "../../form-components/Input";
import Checkbox from "../../form-components/Checkbox";
import SingleButton from "../../form-components/SingleButton";
import { withTranslation } from "react-i18next";

class SignInForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  render() {
    const { loading, error } = this.props.signInState;
    const { t } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          name="email"
          component={Input}
          placeholder={t("form.placeholder.email")}
          type="email"
          suffix={<MailOutlined className="site-form-item-icon" />}
        />
        <Field
          name="password"
          component={Input}
          placeholder={t("form.placeholder.passwd")}
          type="password"
          suffix={<LockOutlined className="site-form-item-icon" />}
        />
        <Field
          name="remember"
          component={Checkbox}
          type="checkbox"
          label={t("signin.remember")}
          size="big"
        />
        <br />
        {error ? (
          <>
            <Alert
              message={this.props.signInState.error}
              type="error"
              showIcon
            />
            <br />{" "}
          </>
        ) : null}
        <Row gutter={10}>
          <Col
            xs={24}
            sm={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link
              to={`/resetowanie-hasla`}
              className="ui button primary forgot-password"
              style={{
                fontSize: "15px",
                lineHeight: "1.1",
                marginBottom: 20,
              }}
            >
              {t("signin.forgot_passwd")}
            </Link>
          </Col>
          <Col xs={24} sm={12}>
            <SingleButton
              htmlType="submit"
              loading={loading ? true : false}
              block="true"
              className="primary-bordered"
            >
              {t("signin.title")}
            </SingleButton>
          </Col>
        </Row>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.email) errors.email = "form.validate.required";

  if (!formValues.password)
    errors.password = "form.validate.required";

  return errors;
};

export default withTranslation()(
  reduxForm({ form: "signInForm", validate })(SignInForm)
);
