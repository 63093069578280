import React from "react";
import { connect } from "react-redux";
import { requestResetPassword } from "../../../actions/resetPasswordActions";
import { getConfig } from "../../../actions/appConfigActions";
import ResetPasswordForm from "./ResetPasswordForm";
import Wrapper from "../../wrappers/Wrapper";
import { withTranslation } from "react-i18next";

class ResetPassword extends React.Component {
  componentDidMount() {
    this.props.getConfig(false);
  }
  onSubmit = (formValues) => {
    this.props.requestResetPassword(formValues);
  };
  render() {
    return (
      <Wrapper
        title={this.props.t("newpasswd.title")}
        bg={this.props.appConfig.noauth_bg_image}
        authLayout={true}
      >
        <ResetPasswordForm
          resetPasswordState={this.props.reset}
          onSubmit={this.onSubmit}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reset: state.resetPassword.reset,
    appConfig: state.appConfig.config,
  };
};

export default connect(mapStateToProps, {
  requestResetPassword,
  getConfig,
})(withTranslation()(ResetPassword));
