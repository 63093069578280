import { message } from "antd";
import i18n from "../i18n";

const t = i18n.t;

const showErrorMessage = (err, inputName = null) => {
  const formError = err.response.data.formError || undefined;
  const errMessage = err.response.data.message || undefined;
  if (formError && inputName && formError[inputName])
    message.error(formError[inputName]);
  else if (errMessage) message.error(errMessage);
  else message.error(t("errors.unexpected_error"));
};

export default showErrorMessage;
