import vod, { tokenConfig } from "../apis/vod";
import {
  CODES_LOADING,
  GET_CODES_SUCCESS,
  GET_CODES_FAILURE,
  GET_CODE_SUCCESS,
  GET_CODE_FAILURE,
  DELETE_CODE_SUCCESS,
  DELETE_CODE_FAILURE,
  RENDER_CODES_SUCCESS,
  RENDER_CODES_FAILURE,
  UPDATE_CODE_SUCCESS,
  UPDATE_CODE_FAILURE,
  ACTIVATE_CODE_SUCCESS,
  ACTIVATE_CODE_FAILURE,
  DELETE_USER_CODE_SUCCESS,
  DELETE_USER_CODE_FAILURE,
  GET_USER_CODES_SUCCESS,
  GET_USER_CODES_FAILURE,
  GET_USER_CODE_SUCCESS,
  GET_USER_CODE_FAILURE,
  CODE_FORM_ERRORS,
  USER_CODE_FORM_ERRORS,
} from "./_types";
import handleErrors from "./handleErrors";

export const getCodes = (props = {}) => (dispatch, getState) => {
  dispatch(setCodesLoading());
  vod
    .get(`/api/codes`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_CODES_SUCCESS,
        payload: res.data.codes,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_CODES_FAILURE);
    });
};

export const getUserCodes = (userId, props = {}) => (dispatch, getState) => {
  dispatch(setCodesLoading());
  vod
    .get(`/api/users/${userId}/codes`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_USER_CODES_SUCCESS,
        payload: res.data.codes,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_USER_CODES_FAILURE);
    });
};

export const getCode = (id, props = {}) => (dispatch, getState) => {
  dispatch(setCodesLoading());
  vod
    .get(`/api/codes/${id}`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_CODE_SUCCESS,
        payload: res.data.code,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_CODE_FAILURE);
    });
};

export const getUserCode = (userId, codeId) => (dispatch, getState) => {
  dispatch(setCodesLoading());
  vod
    .get(`/api/users/${userId}/codes/${codeId}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USER_CODE_SUCCESS,
        payload: res.data.code,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_USER_CODE_FAILURE);
    });
};

export const deleteCode = (id) => (dispatch, getState) => {
  dispatch(setCodesLoading());
  vod
    .delete(`/api/codes/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_CODE_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_CODE_FAILURE);
    });
};

export const deleteUserCode = (userId, codeId) => (dispatch, getState) => {
  dispatch(setCodesLoading());
  vod
    .delete(`/api/users/${userId}/codes/${codeId}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_USER_CODE_SUCCESS, payload: codeId });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_USER_CODE_FAILURE);
    });
};

export const renderCodes = (props) => (dispatch, getState) => {
  dispatch(setCodesLoading());
  vod
    .post(`/api/codes/`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: RENDER_CODES_SUCCESS,
        payload: res.data.codes,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, RENDER_CODES_FAILURE, CODE_FORM_ERRORS);
    });
};

export const activateCode = (userId, props) => (dispatch, getState) => {
  dispatch(setCodesLoading());
  vod
    .post(`/api/users/${userId}/codes`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ACTIVATE_CODE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, ACTIVATE_CODE_FAILURE, USER_CODE_FORM_ERRORS);
    });
};

export const updateCode = (codeId, props) => (dispatch, getState) => {
  dispatch(setCodesLoading());
  vod
    .put(`/api/codes/${codeId}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_CODE_SUCCESS,
        payload: res.data.code,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, UPDATE_CODE_FAILURE, CODE_FORM_ERRORS);
    });
};
export const setCodesLoading = () => {
  return {
    type: CODES_LOADING,
  };
};
