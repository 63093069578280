import {
  ADD_BAN_SUCCESS,
  ADD_BAN_FAILURE,
  DELETE_BAN_SUCCESS,
  DELETE_BAN_FAILURE,
  GET_BANS_SUCCESS,
  GET_BANS_FAILURE,
  BANS_LOADING,
  FORM_BANS_FAILURE,
} from "./_types";
import vod, { tokenConfig } from "../apis/vod";
import handleErrors from "./handleErrors";

export const banUser = (props) => (dispatch, getState) => {
  dispatch(setBansLoading());
  vod
    .post(`/api/bans`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_BAN_SUCCESS,
        payload: res.data.ban,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, ADD_BAN_FAILURE, FORM_BANS_FAILURE);
    });
};

export const getBans = (props = {}) => (dispatch, getState) => {
  dispatch(setBansLoading());
  vod
    .get(`/api/bans`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_BANS_SUCCESS,
        payload: res.data.bans,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_BANS_FAILURE);
    });
};

export const deleteBan = (id) => (dispatch, getState) => {
  dispatch(setBansLoading());
  vod
    .delete(`/api/bans/${id}`, tokenConfig(getState))
    .then(() => {
      dispatch({ type: DELETE_BAN_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_BAN_FAILURE);
    });
};

export const setBansLoading = () => {
  return {
    type: BANS_LOADING,
  };
};
