import React from "react";
import Input from "../../../../form-components/Input";
import { Field, reduxForm, change } from "redux-form";
import _ from "lodash";
import { Tooltip, Select, Row, Col, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

class VideoTranslationForm extends React.Component {
  state = {};
  onVideoSelectChange = (v) => {
    this.props.dispatch(
      change("videoTranslationForm", "video_link", v)
    );
  };
  onAccessibilityVideoSelectChange = (v) => {
    this.props.dispatch(
      change("videoTranslationForm", "accessibility_video", v)
    );
  };
  onAutodescVideoChange = (v) => {
    this.props.dispatch(
      change("videoTranslationForm", "autodesc_video", v)
    );
  };
  onTeaserSelectChange = (v) => {
    this.props.dispatch(
      change("videoTranslationForm", "teaser_link", v)
    );
  };
  renderSelect = (func) => {
    return (
      <Select
        defaultValue=""
        allowClear
        style={{ width: "100%" }}
        showSearch
        dropdownMatchSelectWidth={false}
        onChange={func}
      >
        {this.props.vimeoVideos.map((v) => (
          <Select.Option
            key={v.uri}
            value={v.uri}
            disabled={v.status === "NOT_UPLOADED" ? true : false}
          >
            {v.name}
          </Select.Option>
        ))}
      </Select>
    );
  };
  handleFormSubmit = async (formValues) => {
    this.props.onSubmit(
      _.pick(
        formValues,
        "title",
        "subtitle",
        "video_link",
        "description",
        "additional_desc",
        "accessibility_video",
        "autodesc_video",
        "teaser_link",
        "language_code",
        "video_id"
      )
    );
  };
  render() {
    const { mode } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
        <h2>{mode === "edit" ? "Edytuj" : "Dodaj"} tłumaczenie:</h2>
        <Field
          name="language_code"
          component={Input}
          placeholder="Język (np. en)"
          type="text"
          style={{ display: mode === "edit" ? "none" : "block" }}
        />
        <Field
          name="title"
          component={Input}
          placeholder="Tytuł"
          type="text"
        />
        <Field
          name="subtitle"
          component={Input}
          placeholder="Podtytuł"
          type="text"
        />
        <label htmlFor="videoLink">
          Wideo
          <Row>
            <Col xs={10} style={{ marginRight: 15 }}>
              {this.renderSelect(this.onVideoSelectChange)}
            </Col>
            <Col xs={12}>
              <Field
                id="videoLink"
                name="video_link"
                component={Input}
                placeholder="Link do wideo"
                type="text"
                suffix={
                  <Tooltip title="Umieść link, identyfikator wideo bądź kod iframe. Możesz też wybrać wideo z listy.">
                    <InfoCircleOutlined
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Col>
          </Row>
        </label>
        <label htmlFor="accessibilityVideo">
          Wideo z tłumaczem PJM
          <Row>
            <Col xs={10} style={{ marginRight: 15 }}>
              {this.renderSelect(
                this.onAccessibilityVideoSelectChange
              )}
            </Col>
            <Col xs={12}>
              <Field
                id="accessibilityVideo"
                name="accessibility_video"
                component={Input}
                placeholder="Link do wideo"
                type="text"
                suffix={
                  <Tooltip title="Umieść link, identyfikator wideo bądź kod iframe. Możesz też wybrać wideo z listy.">
                    <InfoCircleOutlined
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Col>
          </Row>
        </label>
        <label htmlFor="autodescVideo">
          Wideo z audiodeskrypcją
          <Row>
            <Col xs={10} style={{ marginRight: 15 }}>
              {this.renderSelect(this.onAutodescVideoChange)}
            </Col>
            <Col xs={12}>
              <Field
                id="autodescVideo"
                name="autodesc_video"
                component={Input}
                placeholder="Link do wideo"
                type="text"
                suffix={
                  <Tooltip title="Umieść link, identyfikator wideo bądź kod iframe. Możesz też wybrać wideo z listy.">
                    <InfoCircleOutlined
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Col>
          </Row>
        </label>
        <label htmlFor="teaserLink">
          Teaser
          <Row>
            <Col xs={10} style={{ marginRight: 15 }}>
              {this.renderSelect(this.onTeaserSelectChange)}
            </Col>
            <Col xs={12}>
              <Field
                id="teaserLink"
                name="teaser_link"
                component={Input}
                placeholder="Link do teasera"
                type="text"
                suffix={
                  <Tooltip title="Umieść link, identyfikator wideo bądź kod iframe. Możesz też wybrać wideo z listy.">
                    <InfoCircleOutlined
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Col>
          </Row>
        </label>
        <Field
          name="description"
          placeholder="Opis spektaklu (kod html)"
          component="textarea"
        />
        <Field
          name="additional_desc"
          placeholder="Dodatkowe informacje (kod html)"
          component="textarea"
        />
        <Button type="primary" htmlType="submit" block="true">
          {this.props.mode === "add" ? "Dodaj" : "Zaktualizuj"}
        </Button>
      </form>
    );
  }
}

const validate = ({ title, language_code, subtitle }) => {
  const errors = {};
  if (title && title.length > 100)
    errors.title = "Maksymalnie 100 znaków.";
  if (subtitle && subtitle.length > 200)
    errors.subtitle = "Maksymalnie 200 znaków.";
  if (!language_code) errors.language_code = "Proszę wybrać język.";
  else if (language_code.length !== 2)
    errors.language_code = "Niepoprawny język";
  return errors;
};

export default reduxForm({ form: "videoTranslationForm", validate })(
  VideoTranslationForm
);
