import React from "react";

export const renderPreview = (string) => {
  if (string && string.includes("iframe")) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: string
            .replace("frameborder", "frameBorder")
            .replace("allowfullscreen", "allowFullScreen"),
        }}
      />
    );
  } else if (string && string.includes("http"))
    return (
      <iframe
        title="Spektakl"
        src={string}
        width="100%"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    );
  else if (string && /^\d+$/.test(string))
    return (
      <iframe
        title="Spektakl"
        src={`https://player.vimeo.com/video/${string}`}
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    );
  else return <p>Niepoprawna wartość</p>;
};
