import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import qs from "query-string";
import moment from "moment";
import { Result, Button, Typography, Skeleton } from "antd";
import Wrapper from "../../wrappers/Wrapper";
import { getOrder } from "../../../actions/paymentsActions";
import { withTranslation } from "react-i18next";
import { facebookPixel, googleTagManager } from "../../../theme-variables";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

const { Paragraph } = Typography;

class EventPaymentSummary extends React.Component {
  state = {
    errorCode: null,
  };
  componentDidMount() {
    const errorCode = Number.parseInt(
      qs.parse(this.props.location.search).error
    );
    const extOrderId = this.props.match.params.extOrderId;
    this.setState({ errorCode });
    this.props.getOrder(extOrderId);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.payments.order !== this.props.payments.order &&
      !!this.props.payments.order
    ) {
      const { order } = this.props.payments;
      if (facebookPixel && !this.state.errorCode)
        ReactPixel.track("Purchase", {
          content_ids: [`event-${order.event_id}`],
          content_category: "event",
          content_type: "product",
          contents: [
            {
              id: `event-${order.event_id}`,
              quantity: 1,
            },
          ],
          currency: "PLN",
          value: parseFloat(order.amount),
        });
      if (googleTagManager && !this.state.errorCode) {
        TagManager.dataLayer({ ecommerce: null });
        TagManager.dataLayer({
          dataLayer: {
            ecommerce: {
              purchase: {
                actionField: {
                  id: order.extOrderId,
                  revenue: order.amount,
                  coupon: order.used_code || null,
                },
                products: [
                  {
                    name: order.title,
                    id: "event-" + order.event_id,
                    category: "event",
                    price: order.event_price,
                    quantity: 1,
                  },
                ],
              },
            },
          },
        });
      }
    }
  }
  renderSummary = () => {
    const { t } = this.props;
    if (this.errorCode === 501)
      return (
        <p>
          {t("paymentsumm.error_text_1")}
          <Link to="/"> {t("paymentsumm.error_text_2")}</Link>.<br />
          {t("paymentsumm.error_text_3")}
        </p>
      );
    return (
      <p>
        {t("paymentsumm.success_text_1")} —
        <Link to="/konto"> {t("paymentsumm.success_text_2")}</Link>
      </p>
    );
  };
  render() {
    const { errorCode } = this.state;
    const { t } = this.props;
    if (
      this.props.payments.order &&
      Object.keys(this.props.payments.order).length
    ) {
      const { order } = this.props.payments;
      let date =
        moment(order.date_from).format("DD.MM.YYYY") +
        "–" +
        moment(order.date_to).format("DD.MM.YYYY");
      if (
        moment(order.date_from).format("DD.MM.YYYY") ===
        moment(order.date_to).format("DD.MM.YYYY")
      )
        date = moment(order.date_from).format("DD.MM.YYYY");
      return (
        <Wrapper title={t("paymentsumm.status")}>
          <Result
            status={errorCode === 501 ? "error" : "success"}
            title={
              errorCode === 501
                ? t("paymentsumm.payment_error")
                : t("paymentsumm.payment_success")
            }
            subTitle={
              errorCode === 501
                ? t("paymentsumm.error_subtitle")
                : t("paymentsumm.success_subtitle")
            }
            extra={[
              <Link key="status" to="/konto">
                <Button type="primary" key="account">
                  {t("paymentsumm.payments_status")}
                </Button>
              </Link>,
              <Link key="lista" to="/">
                <Button key="buy">{t("paymentsumm.event_list")}</Button>
              </Link>,
            ]}
          >
            <div className="desc">
              <Paragraph>
                <h4>
                  {t("paymentsumm.payment_number")} {order.extOrderId}:
                </h4>
                <ul>
                  <li key="cena">
                    <b>{t("paymentsumm.price")} — </b>
                    {order.amount} zł
                  </li>
                  <li key="data">
                    <b>{t("paymentsumm.date")} — </b>
                    {moment(order.created_at).format("DD.MM.YYYY H:mm")}
                  </li>
                  <li key="title">
                    <b>{t("paymentsumm.event")} — </b>
                    {order.title} ({date})
                  </li>
                </ul>
              </Paragraph>
              <Paragraph>{this.renderSummary()}</Paragraph>
            </div>
          </Result>
        </Wrapper>
      );
    }
    return (
      <Wrapper title={t("const.error_occured")}>
        <Skeleton />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payments: state.payments,
  };
};

export default connect(mapStateToProps, { getOrder })(
  withTranslation()(EventPaymentSummary)
);
