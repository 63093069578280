import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button } from "antd";
import createFormData from "../../../../../helpers/createFormData";

class FieldFileInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const {
      input: { onChange },
    } = this.props;
    onChange(e.target.files[0]);
  }

  render() {
    const { label, meta } = this.props;
    const error =
      meta.touched && meta.error !== null ? meta.error : null; //whatever props you send to the component from redux-form Field
    return (
      <div>
        <label>{label}</label>
        <div>
          <input
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={this.onChange}
          />
        </div>
        {error ? <p style={{ color: "red" }}>{error}</p> : null}
      </div>
    );
  }
}

class GalleryImageForm extends React.Component {
  onSubmit = async (formValues) => {
    const formData = await createFormData(
      ["image"],
      [
        {
          name: "image",
          options: {
            maxSizeMB: 3,
            maxWidthOrHeight: 2000,
            useWebWorker: true,
          },
        },
      ],
      formValues
    );
    this.props.onSubmit(this.props.galleryId, formData);
  };
  render() {
    return (
      <form
        style={{ margin: "20px 0" }}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <Field
          name="image"
          component={FieldFileInput}
          placeholder="Zdjęcie"
          label="Zdjęcie (maksymalnie 5 zdjęć)"
          type="file"
        />
        <br />
        <Button type="primary" htmlType="submit">
          Dodaj zdjęcie
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  return errors;
};

export default reduxForm({ form: "galleryForm", validate })(
  GalleryImageForm
);
