import React from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import vod from "../../../apis/vod";
import Wrapper from "../../wrappers/Wrapper";
import "./SearchPage.scss";
import { useTranslation, withTranslation } from "react-i18next";

const SearchResult = ({ type, r }) => {
  const { t } = useTranslation();
  const content = r.description || r.content || "Brak treści";
  let link = "";
  switch (type) {
    case "video":
      link = `/wideo/${r.slug || r.id}`;
      break;
    case "event":
      link = `/wydarzenie/${r.slug || r.id}`;
      break;
    case "page":
      link = `/n/${r.slug}`;
      break;
    default:
      break;
  }
  return (
    <div className="search-result" key={r.id}>
      <Link to={link}>
        <h3>{r.title}</h3>
      </Link>
      <p>
        {content.replace(/(<([^>]+)>)/gi, "").substr(0, 200)}
        ...
        <br />
        <Link to={link}>{t("searchpage.read_more")}...</Link>
      </p>
    </div>
  );
};

class SearchPage extends React.Component {
  state = {
    query: qs.parse(this.props.location.search).q,
    videos: [],
    events: [],
    pages: [],
  };
  findContent = () => {
    if (this.state.query && this.state.query.length > 2)
      vod
        .get(`/api/search/all?search=${this.state.query}`, {
          headers: { "x-auth-token": this.props.token },
        })
        .then((res) => {
          const { events, pages, videos } = res.data;
          this.setState({ events, pages, videos });
        });
  };
  componentDidMount() {
    this.findContent();
  }
  render() {
    const { t } = this.props;
    if (
      this.state.videos.length ||
      this.state.events.length ||
      this.state.pages.length
    )
      return (
        <Wrapper>
          <h1>
            {t("searchpage.search")}{" "}
            {this.state.query && this.state.query.length > 2
              ? `"${this.state.query}"`
              : ""}
            :
          </h1>
          {this.state.videos.length ? (
            <div className="search-section">
              <h2>{t("searchpage.video_found")}:</h2>
              {this.state.videos.map((v) => {
                return <SearchResult type="video" r={v} key={v.id} />;
              })}
            </div>
          ) : null}
          {this.state.events.length ? (
            <div className="search-section">
              <h2>{t("searchpage.event_found")}:</h2>
              {this.state.events.map((e) => {
                return <SearchResult type="event" r={e} key={e.id} />;
              })}
            </div>
          ) : null}
          {this.state.pages.length ? (
            <div className="search-section">
              <h2>{t("searchpage.pages_found")}:</h2>
              {this.state.pages.map((p) => {
                return <SearchResult type="page" r={p} key={p.id} />;
              })}
            </div>
          ) : null}
        </Wrapper>
      );
    else
      return (
        <Wrapper>
          <h1>
            {t("searchpage.search")} "{this.state.query}":
          </h1>
          <div className="no-result">
            {this.state.query && this.state.query.length < 3
              ? t("searchpage.min_length")
              : t("searchpage.no_result")}
          </div>
        </Wrapper>
      );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};
export default connect(mapStateToProps)(withTranslation()(SearchPage));
