import React from "react";
import { Card, Form, Input, Button, message, Tooltip } from "antd";
import showErrorMesssage from "../../../../../helpers/showErrorMesssage";
import { InfoCircleOutlined } from "@ant-design/icons";
import "../../managers.scss";

class InvoiceSettings extends React.Component {
  state = {
    contact_email_changed: false,
    contact_email: this.props.config.contact_email,
  };
  changeContactEmail = () => {
    const { contact_email } = this.state;
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        contact_email
      )
    ) {
      message.error("Niepoprawny adres email");
      return;
    }
    this.props
      .changeConfig({ contact_email })
      .then(() => {
        this.setState({ contact_email_changed: false });
      })
      .catch((err) => {
        showErrorMesssage(err, "Email kontaktowy");
      });
  };
  render() {
    const { contact_email_changed, contact_email } = this.state;
    return (
      <Card style={{ marginBottom: "30px" }} title="Ustawienia faktur">
        <p>
          UWAGA! System nie wystawia automatycznie faktur. Poniżej znajduje się
          lista użytkowników, którzy poprosili o wystawienie faktury przy
          zamówieniu. Prosimy przesłać fakturę na podany adres email. W polu{" "}
          <b>Email kontaktowy</b> można podać adres email na który będą
          przychodziły informacje dotyczące faktur.
        </p>
        <Form layout="inline">
          <Form.Item>
            <Input
              name="contact_email"
              value={contact_email}
              type="text"
              placeholder="Email kontaktowy"
              style={{ width: 200, marginRight: 15 }}
              onChange={(e) => {
                this.setState({
                  contact_email: e.target.value,
                  contact_email_changed: true,
                });
              }}
              suffix={
                <Tooltip title="Na podany adres email zostaną wysłane wiadomości z potrzebnymi danymi do wystawienia faktury. Wiadomości będą wysyłane po zaakceptowaniu płatności.">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
            <Button
              type="primary"
              onClick={() => this.changeContactEmail()}
              disabled={!contact_email_changed && !contact_email_changed}
            >
              Zapisz
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default InvoiceSettings;
