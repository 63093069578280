import React from "react";
import vodLogo from "../../static/vod.svg";
import vodLogoBlack from "../../static/vod_black.svg";
import teappLogo from "../../static/teapp_logo.svg";
import teappLogoOrange from "../../static/teapp_logo_orange.svg";
import { authFooterVariant } from "../../theme-variables";
import { useTranslation } from "react-i18next";
import "./MiniFooter.scss";

const MiniFooter = (props) => {
  const { t } = useTranslation();
  if (props.variant === 1 || authFooterVariant === 1)
    return (
      <>
        <a
          href="https://teapp.pl"
          target="_blank"
          rel="noopener noreferrer"
          className="mini-footer"
        >
          <span>
            {t("footer.platform")}
            <img
              src={props.black ? vodLogoBlack : vodLogo}
              alt="Logo VOD"
            />
          </span>

          <img
            src={props.black ? teappLogoOrange : teappLogo}
            alt="Logo Teapp"
          />
        </a>
      </>
    );
  else
    return (
      <div className="spacer" style={{ marginBottom: 20 }}></div>
    );
};

export default MiniFooter;
