import React from "react";
import { connect } from "react-redux";
import { getConfig } from "../../actions/appConfigActions";
import CookieConsent from "react-cookie-consent";
import { withTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";

class CookiePopup extends React.Component {
  componentDidMount() {
    this.props.getConfig(false);
  }
  render() {
    const { t } = this.props;
    return (
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.accept")}
        cookieName="policyCookie"
        id="cookieConsent"
        onAccept={ReactPixel.grantConsent}
        buttonStyle={{
          background: "#e87b22",
          color: "white",
          fontSize: 12,
        }}
        style={{
          background: "black",
          fontSize: 15,
          maxWidth: 700,
          alignItems: "center",
        }}
        expires={150}
      >
        {t("cookies.text_1")}{" "}
        <a
          href={this.props.config.policy_page}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white", textDecoration: "underline" }}
        >
          {t("cookies.text_2")}
        </a>
        .
      </CookieConsent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.appConfig.config,
  };
};

export default connect(mapStateToProps, { getConfig })(
  withTranslation()(CookiePopup)
);
