import {
  GET_SLIDES_SUCCESS,
  GET_SLIDES_FAILURE,
  GET_SLIDE_SUCCESS,
  GET_SLIDE_FAILURE,
  SLIDES_LOADING,
  DELETE_SLIDE_SUCCESS,
  DELETE_SLIDE_FAILURE,
  ADD_SLIDE_SUCCESS,
  ADD_SLIDE_FAILURE,
  UPDATE_SLIDE_SUCCESS,
  UPDATE_SLIDE_FAILURE,
  UPDATE_WEIGHT_FAILURE,
  UPDATE_WEIGHT_SUCCESS,
} from "../actions/_types";

const initialState = {
  slides: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SLIDES_SUCCESS:
    case UPDATE_WEIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        slides: action.payload,
      };
    case GET_SLIDES_FAILURE:
    case UPDATE_WEIGHT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_SLIDE_SUCCESS:
      let found = false;
      let slides = state.slides.map((slide) => {
        if (slide.id === action.payload.id) {
          found = true;
          return action.payload;
        }
        return slide;
      });
      if (!found) slides.push(action.payload);
      return {
        ...state,
        loading: false,
        error: undefined,
        slides: slides,
      };
    case GET_SLIDE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_SLIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        slides: state.slides.filter(
          (slide) => slide.id !== action.payload
        ),
      };
    case DELETE_SLIDE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_SLIDE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        slides: [action.payload, ...state.slides],
      };
    case ADD_SLIDE_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case UPDATE_SLIDE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        slides: state.slides.map((slide) => {
          if (slide.id === action.payload.id) return action.payload;
          return slide;
        }),
      };
    case UPDATE_SLIDE_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case SLIDES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
