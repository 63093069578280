import React from "react";
import { Card, Checkbox } from "antd";
import NoAuthPageSettings from "./NoAuthPageSettings";
import "../../managers.scss";
import { Link } from "react-router-dom";

class ViewsSettings extends React.Component {
  state = {
    view_vod: true,
    view_eventslist: true,
    view_slider: true,
  };
  toggleConfig = (e, field) => {
    let newData = {};
    newData[field] = e.target.checked ? 1 : 0;
    this.props.changeConfig(newData).then(() => {
      let newState = {};
      newState[field] = e.target.checked;
      this.setState(newState);
    });
  };
  render() {
    const {
      view_eventslist,
      view_vod,
      vod_filters,
      noauth_bg_image,
      view_slider,
    } = this.props.config;

    return (
      <Card style={{ marginBottom: "30px" }} title="Zarządzaj widokami">
        <b>Strona logowania / rejestracji</b>
        <NoAuthPageSettings
          token={this.props.token}
          initialValues={{ noauth_bg_image }}
        />
        <b>Strona główna po zalogowaniu: </b>
        <br />
        <br />
        <label htmlFor="userNotification">
          <Checkbox
            style={{ marginRight: "5px" }}
            id="userNotification"
            checked={view_eventslist}
            type="checkbox"
            name="userNotification"
            onChange={(e) => this.toggleConfig(e, "view_eventslist")}
          />
          Pokaż blok "Dostępne materiały - repertuar"
        </label>
        <br />
        <br />
        <label htmlFor="upcomingEventNotification">
          <Checkbox
            style={{ marginRight: "5px" }}
            id="upcomingEventNotification"
            checked={view_vod}
            type="checkbox"
            name="upcomingEventNotification"
            onChange={(e) => this.toggleConfig(e, "view_vod")}
          />
          Pokaż blok "Dostępne materiały - VOD"
        </label>
        <br />
        <br />
        <label htmlFor="showVodFilters">
          <Checkbox
            style={{ marginRight: "5px" }}
            id="showVodFilters"
            checked={vod_filters}
            type="checkbox"
            name="showVodFilters"
            onChange={(e) => this.toggleConfig(e, "vod_filters")}
          />
          Włącz filtry dla VOD
        </label>
        <br />
        <br />
        <label htmlFor="showSlider">
          <Checkbox
            style={{ marginRight: "5px" }}
            id="showSlider"
            checked={view_slider}
            type="checkbox"
            name="showSlider"
            onChange={(e) => this.toggleConfig(e, "view_slider")}
          />
          Włącz slider
        </label>
        <br />
        <br />
        <p>
          Blok festiwalowy można włączyć lub wyłączyć w zakładce:{" "}
          <Link
            onClick={() => {
              this.props.handleChangeView("festival");
            }}
            to={`?view=festival`}
          >
            Festiwal
          </Link>
        </p>
        <p>
          Blok Bilet-Prezent można włączyć lub wyłączyć w zakładce:{" "}
          <Link
            onClick={() => {
              this.props.handleChangeView("gifts");
            }}
            to={`?view=gifts`}
          >
            Prezenty
          </Link>
        </p>
      </Card>
    );
  }
}

export default ViewsSettings;
