import compressImage from "./compressImage";

const createFormData = async (
  fieldsToSend,
  fieldImagesToCompress,
  formValues
) => {
  fieldImagesToCompress.forEach(async (obj) => {
    if (formValues[obj.name])
      formValues[obj.name] = await compressImage(
        formValues[obj.name],
        obj.options
      );
  });

  const formData = new FormData();
  fieldsToSend.forEach((fieldName) => {
    if (
      formValues[fieldName] !== undefined &&
      formValues[fieldName] !== null
    )
      formData.append(fieldName, formValues[fieldName]);
  });

  return formData;
};

export default createFormData;
