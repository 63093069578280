import React from "react";
import moment from "moment";
import { Field, reduxForm, change } from "redux-form";
import { Alert, Tooltip, Button, DatePicker, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Input from "../../../../form-components/Input";
import { makeField } from "../../../../form-components/makeComponent";
import FormItem from "antd/lib/form/FormItem";
import { Link } from "react-router-dom";

class EditCodeForm extends React.Component {
  onProductChange = (v) => {
    this.props.dispatch(change("codeForm", "product_id", v));
  };
  render() {
    const error = this.props.error;
    return (
      <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <Field name="code" component={Input} placeholder="Kod" type="text" />
        <Field
          name="desc"
          component={Input}
          placeholder="Opis kuponu"
          suffix={
            <Tooltip title="Opis powinien zawierać informacje o zniżce oraz wydarzeniu lub wideo, którego dotyczy zniżka">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
          type="text"
        />
        <Field
          name="product_id"
          component={({ meta, input }) => {
            const error =
              meta.touched && meta.error !== null ? meta.error : null;
            return (
              <FormItem
                label="Wybierz produkt:"
                hasFeedback
                help={error}
                validateStatus={error ? "error" : null}
              >
                <Select
                  value={input.value}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  dropdownMatchSelectWidth={false}
                  onChange={this.onProductChange}
                  optionFilterProp={"children"}
                >
                  <Select.Option key="any" value="any">
                    Dowolne wydarzenie/wideo
                  </Select.Option>
                  {this.props.videos.map((v) => {
                    const id = `video-${v.id}`;
                    const label = `Wideo #${v.id}: ${v.title}`;
                    return (
                      <Select.Option key={id} value={id}>
                        {label}{" "}
                        <Link target="_blank" to={`/wideo/${v.slug || v.id}`}>
                          link
                        </Link>
                      </Select.Option>
                    );
                  })}
                  {this.props.events.map((v) => {
                    const id = `event-${v.id}`;
                    const time = v.date_to
                      ? `${moment(v.date_from).format("DD.MM")}-${moment(
                          v.date_to
                        ).format("DD.MM")}`
                      : moment(v.date_from).format("DD.MM");
                    const label = `Wydarzenie #${v.id}: ${v.title} (${time})`;
                    return (
                      <Select.Option key={id} value={id}>
                        {label}{" "}
                        <Link
                          target="_blank"
                          to={`/wydarzenie/${v.slug || v.id}`}
                        >
                          link
                        </Link>
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormItem>
            );
          }}
        />
        <Field
          name="discount"
          label="Zniżka"
          component={Input}
          placeholder="5 / 15%"
          type="text"
          suffix={
            <Tooltip title="Proszę podać kwotę w zł o którą zostanie obniżona cena wyjściowa. Jeżeli obniżka jest procentowa, należy na końcu dopisać symbol '%'">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        {this.props.multipleRender ? (
          <Field
            name="number"
            component={Input}
            label="Liczba kodów do wygenerowania"
            placeholder="10"
            type="number"
          />
        ) : (
          <Field
            name="limit"
            component={Input}
            label="Limit użyć kodu"
            placeholder="500"
            type="number"
          />
        )}
        <Field
          className="datePicker"
          showTime
          name="expiration_date"
          component={makeField(DatePicker, "HH:mm DD.MM.YYYY", false)}
          label={"Data wygaśnięcia kuponu:"}
          hasFeedback
          onChange={this.changeExpirationDate}
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
        />
        {error ? <Alert message={error} type="error" showIcon /> : null}
        <br />
        <Button type="primary" htmlType="submit" block="true">
          Wygeneruj
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  const {
    code,
    desc,
    limit,
    expiration_date,
    discount,
    product_id,
    number,
  } = formValues;

  if (!code) errors.code = "Niepoprawny kod/prefiks";
  if (code && code.length > 30) errors.code = "Maksymalna liczba znaków to 30";

  if (!desc) errors.desc = "Pole wymagane. Prosimy podać opis kodu.";

  if (!limit) errors.limit = "Pole wymagane.";
  if (limit && isNaN(parseInt(limit))) errors.limit = "Niepoprawna wartość.";

  if (!expiration_date) errors.expiration_date = "Niepoprawna data.";

  if (!discount) errors.discount = "Pole wymagane.";
  if (
    discount &&
    !/^\d+(.\d{1,2})?$/.test(discount) &&
    !/^((100)|(\d{1,2}(.\d*)?))%$/.test(discount)
  )
    errors.discount = "Niepoprawna wartość.";

  if (discount && discount == 0) errors.discount = "Niepoprawna wartość.";

  if (!product_id) errors.product_id = "Pole wymagane.";
  else if (!["event-", "video-", "any"].some((v) => product_id.includes(v)))
    errors.product_id = "Niepoprawna wartość.";
  else if (!product_id.includes("any")) {
    const productData = product_id.split("-");
    if (productData.length !== 2 || isNaN(parseInt(productData[1])))
      errors.product_id = "Niepoprawna wartość.";
  }

  if (number && isNaN(parseInt(number)))
    errors.number = "Należy podać liczbę całkowitą lub pozostawić puste pole.";

  return errors;
};

export default reduxForm({ form: "codeForm", validate })(EditCodeForm);
