import vod from "../apis/vod";
import store from "../store";
import showErrorMessage from "./showErrorMesssage";
import { message } from "antd";

export const updateConfig = (props, successMessage) => {
  const token = store.getState().auth.token;
  return vod
    .put(`/api/appconfig`, props, {
      headers: {
        "x-auth-token": token,
      },
    })
    .then((res) => {
      message.success(successMessage || "Zaktualizowano zmiany");
      return res;
    })
    .catch((err) => {
      showErrorMessage(err);
    });
};

export const deleteImgInConfig = (urlField) => {
  const token = store.getState().auth.token;
  return vod
    .delete(`/api/appconfig/image/${urlField}`, {
      headers: {
        "x-auth-token": token,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("Wystąpił błąd: " + err);
    });
};

export const deleteImgInGiftConfig = (urlField) => {
  const token = store.getState().auth.token;
  return vod
    .delete(`/api/gifts/settings/image/${urlField}`, {
      headers: {
        "x-auth-token": token,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("Wystąpił błąd: " + err);
    });
};
