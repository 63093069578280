import React from "react";
import { Redirect, Route } from "react-router-dom";
import { noAuthRedirect } from "../../theme-variables";

const NoAuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (localStorage.getItem("user")) {
        if (
          props.location.pathname === "/logowanie" ||
          props.location.pathname === "/strona-glowna" ||
          props.location.pathname === "/weryfikacja-konta" ||
          props.location.pathname === "/rejestracja" ||
          props.location.pathname === "/rejestracja/sukces" ||
          props.location.pathname === "/resetowanie-hasla" ||
          props.location.pathname === "/nowe-haslo" ||
          props.location.pathname === "/resetowanie-hasla/informacja" ||
          props.location.pathname === "/admin-login"
        )
          return <Redirect to="/" />;

        if (
          props.location.pathname.includes("publiczne") ||
          props.location.pathname.includes("strona-glowna")
        )
          return (
            <Redirect
              to={{
                pathname: props.location.pathname.replace(
                  /publiczne|strona-glowna/gi,
                  ""
                ),
                state: { from: props.location },
              }}
            />
          );
      } else {
        if (
          props.location.pathname === "/strona-glowna" &&
          noAuthRedirect === "/logowanie"
        )
          return <Redirect to={noAuthRedirect} />;
      }
      return <Component {...props} />;
    }}
  />
);

export default NoAuthRoute;
