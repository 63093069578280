import { Table } from "antd";
import React, { Component } from "react";
import {
  getDatetimeWithTz,
  renderTagByPaymentStatus,
} from "../../../helpers/productUtilities";
import Column from "antd/lib/table/Column";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class UserGroupTicketList extends Component {
  render() {
    const { t } = this.props;
    const dataSource = this.props.orders.map((o) => {
      o.key = o.id;
      o.used = o.codes.length > 0 ? (o.codes[0].usage ? true : false) : false;
      return o;
    });
    return (
      <Table
        locale={{ emptyText: t("userpage.no_orders") }}
        className="users-group-ticket-table"
        dataSource={dataSource}
        pagination={{ defaultPageSize: 20 }}
        expandable={{
          expandedRowRender: (r) => {
            return (
              <ul style={{ margin: 0 }}>
                {r.codes && r.codes.length
                  ? r.codes.map((c) => (
                      <li key={c.id}>
                        {c.code}
                        {c.usage ? (
                          <>
                            - aktywowane przez:{" "}
                            <Link to={`/konto/${c.usage.userId}`}>
                              {c.usage.firstname} {c.usage.lastname}
                            </Link>
                          </>
                        ) : null}
                      </li>
                    ))
                  : r.status === "COMPLETED"
                  ? "Wystąpił błąd przy generowaniu kodów"
                  : "Płatność przetwarzana - kody nie zostały jeszcze wygenerowane"}
              </ul>
            );
          },
          rowExpandable: (r) => r.codes && r.codes.length > 0,
        }}
      >
        <Column
          title={t("userpage.order_details")}
          dataIndex="order_details"
          key="order_details"
          render={(v, r) => {
            const date = getDatetimeWithTz(r.created_at);
            return (
              <>
                <b>{t("userpage.order_id")}: </b> {r.extOrderId}
                <br />
                <b>{t("userpage.payu_order_id")}: </b> {r.orderId}
                <br />
                <b>{t("userpage.price")}: </b> {r.amount} zł
                <br />
                <b>{t("userpage.created")}: </b>{" "}
                {date.format("H:mm, DD.MM.YYYY")}
                <br />
              </>
            );
          }}
        />
        <Column title={t("userpage.order_desc")} dataIndex="desc" key="desc" />
        <Column
          title={t("userpage.used_generated_codes")}
          dataIndex="used_generated_codes"
          key="used_generated_codes"
          render={(v, r) => {
            if (r.codes.length)
              return (
                <>
                  {r.numberOfUses}/{r.product_quantity}
                </>
              );
            return t("userpage.waiting_payment");
          }}
        />
        <Column
          title={t("userpage.order_status")}
          dataIndex="status"
          key="status"
          render={(v) => {
            return renderTagByPaymentStatus(v, t);
          }}
        />
      </Table>
    );
  }
}

export default withTranslation()(UserGroupTicketList);
