import vod, { tokenConfig } from "../apis/vod";
import {
  GET_SLIDES_SUCCESS,
  GET_SLIDES_FAILURE,
  GET_SLIDE_SUCCESS,
  GET_SLIDE_FAILURE,
  SLIDES_LOADING,
  DELETE_SLIDE_SUCCESS,
  DELETE_SLIDE_FAILURE,
  ADD_SLIDE_SUCCESS,
  ADD_SLIDE_FAILURE,
  UPDATE_SLIDE_SUCCESS,
  UPDATE_SLIDE_FAILURE,
  SLIDE_FORM_ERRORS,
} from "./_types";
import handleErrors from "./handleErrors";

export const getSlides = (props = {}) => (dispatch, getState) => {
  dispatch(setSlidesLoading());
  vod
    .get(`/api/slides`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_SLIDES_SUCCESS,
        payload: res.data.slides,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_SLIDES_FAILURE);
    });
};

export const getSlide = (id) => (dispatch, getState) => {
  dispatch(setSlidesLoading());
  vod
    .get(`/api/slides/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SLIDE_SUCCESS,
        payload: res.data.slide,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_SLIDE_FAILURE);
    });
};

export const deleteSlide = (id) => (dispatch, getState) => {
  dispatch(setSlidesLoading());
  vod
    .delete(`/api/slides/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_SLIDE_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_SLIDE_FAILURE);
    });
};

export const addSlide = (props) => (dispatch, getState) => {
  dispatch(setSlidesLoading());

  vod
    .post(`/api/slides/`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_SLIDE_SUCCESS, payload: res.data.slide });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        ADD_SLIDE_FAILURE,
        SLIDE_FORM_ERRORS
      );
    });
};

export const updateSlide = (props, id) => (dispatch, getState) => {
  dispatch(setSlidesLoading());
  vod
    .put(`/api/slides/${id}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_SLIDE_SUCCESS,
        payload: res.data.slide,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        UPDATE_SLIDE_FAILURE,
        SLIDE_FORM_ERRORS
      );
    });
};

export const setSlidesLoading = () => {
  return {
    type: SLIDES_LOADING,
  };
};
