import vod, { tokenConfig } from "../apis/vod";
import {
  BUY_VIDEO_TICKET_LOADING,
  BUY_VIDEO_TICKET_SUCCESS,
  BUY_VIDEO_TICKET_FAILURE,
  GET_VIDEO_ORDER_SUCCESS,
  GET_VIDEO_ORDER_FAILURE,
  GET_VIDEO_ORDERS_SUCCESS,
  GET_VIDEO_ORDERS_FAILURE,
  ASSIGN_VIDEO_ACCESS_FAILURE,
  ASSIGN_VIDEO_ACCESS_SUCCESS,
  CLEAR_VIDEO_PAYMENT_ERROR,
} from "./_types";
import handleErrors from "./handleErrors";
import history from "../history";
import { facebookPixel } from "../theme-variables";
import ReactPixel from "react-facebook-pixel";

export const buyTicket = (videoId, props) => (dispatch, getState) => {
  dispatch({
    type: BUY_VIDEO_TICKET_LOADING,
    payload: videoId,
  });
  vod
    .post(`/api/videopayments/${videoId}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: BUY_VIDEO_TICKET_SUCCESS });

      if (facebookPixel && res.data.payu && res.data.order) {
        const { order } = res.data;
        ReactPixel.track("InitiateCheckout", {
          content_ids: [`video-${order.product_id}`],
          content_category: order.type,
          contents: order.contents,
          content_type: "product",
          currency: "PLN",
          value: parseFloat(order.value),
        });
      }
      if (res.data.payu) window.location.href = res.data.payu.redirectUri;
      else history.push(`/wideo/${videoId}`);
    })
    .catch((err) => {
      handleErrors(err, dispatch, BUY_VIDEO_TICKET_FAILURE);
    });
};

export const getOrder = (extOrderId) => (dispatch, getState) => {
  vod
    .get(`/api/videopayments/${extOrderId}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_VIDEO_ORDER_SUCCESS,
        payload: res.data.order,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_VIDEO_ORDER_FAILURE);
    });
};

export const getUserOrders = () => (dispatch, getState) => {
  vod
    .get(`/api/videopayments/user`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_VIDEO_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_VIDEO_ORDERS_FAILURE);
    });
};

export const getOrders = (props) => (dispatch, getState) => {
  vod
    .get(`/api/videopayments/`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_VIDEO_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_VIDEO_ORDERS_FAILURE);
    });
};

export const assignFreeAccess = (videoId, userId) => (dispatch, getState) => {
  vod
    .post(
      `/api/videopayments/free/${videoId}/${userId}`,
      null,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: ASSIGN_VIDEO_ACCESS_SUCCESS });
    })
    .catch((err) => {
      handleErrors(err, dispatch, ASSIGN_VIDEO_ACCESS_FAILURE);
    });
};

export const clearPaymentError = () => (dispatch) => {
  dispatch({ type: CLEAR_VIDEO_PAYMENT_ERROR });
};
