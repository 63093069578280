import React from "react";

const LineBreak = ({ additionalStyles }) => {
  const lineStyles = {
    margin: "10px 0",
    height: "1px",
    width: "100%",
    display: "block",
    backgroundColor: "#000",
    ...additionalStyles,
  };
  return <div style={lineStyles}></div>;
};

export default LineBreak;
