import {
  Input,
  message,
  Modal,
  Popconfirm,
  Table,
  Form,
  Button,
  Tooltip,
} from "antd";
import Column from "antd/lib/table/Column";
import React from "react";
import { connect } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import vod from "../../../../../apis/vod";

class SubtitlesModal extends React.Component {
  state = {
    subtitles: [],
    loading: false,
    form: {
      language: "pl",
      name: "",
      subtitleContent: "",
    },
  };
  fetchSubtitles = () => {
    vod
      .get(`/api/vimeo/${this.props.video.uri}/subtitles`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({ subtitles: res.data.subtitles.data });
      })
      .catch((err) => message.error("Wystąpił błąd: " + err));
  };
  deleteSubtitle = (subtitleId) => {
    vod
      .delete(`/api/vimeo/${this.props.video.uri}/subtitles/${subtitleId}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        message.success("Napisy zostały usunięte");
        this.setState({
          subtitles: this.state.subtitles.filter(
            (s) =>
              s.uri !==
              `/videos/${this.props.video.uri}/texttracks/${subtitleId}`
          ),
        });
      })
      .catch((err) =>
        message.error("Wystąpił błąd: " + err.response.data.message)
      );
  };
  submitForm = () => {
    this.setState({ loading: true });
    vod
      .post(`/api/vimeo/${this.props.video.uri}/subtitles`, this.state.form, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then(() => {
        message.success("Dodano napisy");
        this.setState({
          form: { language: "pl", name: "", subtitleContent: "" },
        });
      })
      .catch((err) =>
        message.error("Wystąpił błąd: " + err.response.data.message)
      )
      .then(() => {
        this.fetchSubtitles();
        this.setState({ loading: false });
      });
  };
  componentDidMount() {
    this.fetchSubtitles();
  }
  render() {
    const { video, visible, closeModal } = this.props;
    const { name, subtitleContent, language } = this.state.form;
    return (
      <Modal
        title={`${video.name}: zarządzaj napisami`}
        visible={visible}
        footer={null}
        onCancel={closeModal}
      >
        <Form>
          <Form.Item name="name">
            <Input
              placeholder="Nazwa"
              type="text"
              value={name}
              onChange={(e) =>
                this.setState({
                  form: { ...this.state.form, name: e.target.value },
                })
              }
            />
          </Form.Item>
          <Form.Item name="language">
            <Input
              placeholder="Język (np. pl, en, de)"
              type="text"
              value={language}
              onChange={(e) =>
                this.setState({
                  form: {
                    ...this.state.form,
                    language: e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item name="subtitleContent">
            <Input.TextArea
              placeholder="Napisy w formacie vtt (proszę wkleić zawartość pliku)"
              value={subtitleContent}
              onChange={(e) =>
                this.setState({
                  form: {
                    ...this.state.form,
                    subtitleContent: e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            loading={this.state.loading}
            block="true"
            style={{ marginBottom: 40 }}
            onClick={this.submitForm}
          >
            Dodaj napisy
          </Button>
        </Form>
        <Table
          size="small"
          dataSource={this.state.subtitles.map((subtitle) => {
            subtitle.key = subtitle.uri;
            return subtitle;
          })}
        >
          <Column
            title="Nazwa / język"
            dataIndex="name"
            key="name"
            render={(v, r) => (v ? `${v} (${r.language})` : r.language)}
          />
          <Column
            title="Operacje"
            key="action"
            render={(v, r) => (
              <Tooltip title="Usuń napisy">
                <Popconfirm
                  title={`Czy na pewno chcesz usunąć napisy?`}
                  onConfirm={() =>
                    this.deleteSubtitle(
                      r.uri.substring(r.uri.lastIndexOf("/") + 1, r.uri.length)
                    )
                  }
                  okText="Tak"
                  cancelText="Nie"
                >
                  <DeleteOutlined key="delete" />
                </Popconfirm>
              </Tooltip>
            )}
          />
        </Table>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return { token: state.auth.token };
};

export default connect(mapStateToProps)(SubtitlesModal);
