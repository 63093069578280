import {
  GET_VIMEO_VIDEOS_FAILURE,
  GET_VIMEO_VIDEOS_SUCCESS,
  GET_VIMEO_VIDEO_FAILURE,
  GET_VIMEO_VIDEO_SUCCESS,
  DELETE_VIMEO_VIDEO_FAILURE,
  DELETE_VIMEO_VIDEO_SUCCESS,
  ADD_VIMEO_VIDEO_FAILURE,
  ADD_VIMEO_VIDEO_SUCCESS,
  UPDATE_VIMEO_VIDEO_SUCCESS,
  UPDATE_VIMEO_VIDEO_FAILURE,
} from "../actions/_types";

const initialState = {
  videos: [],
  newvideo: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VIMEO_VIDEOS_SUCCESS:
      return {
        ...state,
        error: undefined,
        videos: action.payload,
      };
    case GET_VIMEO_VIDEOS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_VIMEO_VIDEO_SUCCESS:
      let found = false;
      let videos = state.videos.map((video) => {
        if (video.id === action.payload.id) {
          found = true;
          return action.payload;
        }
        return video;
      });
      if (!found) videos.push(action.payload);
      return {
        ...state,
        error: undefined,
        videos: videos,
      };
    case GET_VIMEO_VIDEO_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_VIMEO_VIDEO_SUCCESS:
      return {
        ...state,
        error: undefined,
        videos: state.videos.filter(
          (video) => video.id !== action.payload
        ),
      };
    case DELETE_VIMEO_VIDEO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_VIMEO_VIDEO_SUCCESS:
      return {
        ...state,
        success: true,
        error: undefined,
        newvideo: action.payload,
        videos: [action.payload, ...state.videos],
      };
    case ADD_VIMEO_VIDEO_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case UPDATE_VIMEO_VIDEO_SUCCESS:
      return {
        ...state,
        success: true,
        error: undefined,
        videos: state.videos.map((v) => {
          if (v.id === action.payload.id) return action.payload;
          return v;
        }),
      };
    case UPDATE_VIMEO_VIDEO_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
