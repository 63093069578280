import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { Button, DatePicker, Card } from "antd";
import Input from "../../../../form-components/Input";
import Checkbox from "../../../../form-components/Checkbox";
import { withTranslation } from "react-i18next";
import FileField from "../../../../form-components/FileField";
import { makeField } from "../../../../form-components/makeComponent";
import createFormData from "../../../../../helpers/createFormData";
import { deleteImgInGiftConfig } from "../../../../../helpers/updateConfig";

class GiftConfig extends React.Component {
  state = {
    mobile_gift_image: this.props.mobile_gift_image,
    tablet_gift_image: this.props.tablet_gift_image,
    desktop_gift_image: this.props.desktop_gift_image,
    any_gift_checked: this.props.any_gift_available,
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.mobile_gift_image !== prevProps.mobile_gift_image)
      this.setState({ mobile_gift_image: this.props.mobile_gift_image });
    if (this.props.tablet_gift_image !== prevProps.tablet_gift_image)
      this.setState({ tablet_gift_image: this.props.tablet_gift_image });
    if (this.props.desktop_gift_image !== prevProps.desktop_gift_image)
      this.setState({ desktop_gift_image: this.props.desktop_gift_image });
  }
  deleteImageFromServer = (urlField) => {
    deleteImgInGiftConfig(urlField).then(() => {
      this.props.dispatch(change("giftConfigForm", urlField, ""));
    });
  };
  onSubmit = async (formValues) => {
    const formData = await createFormData(
      [
        "gift_available",
        "any_gift_available",
        "gift_label",
        "gift_label_en",
        "gift_description",
        "gift_description_en",
        "gift_expiration",
        "gift_price",
        "mobile_gift_image",
        "tablet_gift_image",
        "desktop_gift_image",
        "mobile_gift_image_data",
        "tablet_gift_image_data",
        "desktop_gift_image_data",
      ],
      [
        {
          name: "mobile_gift_image_data",
          options: {
            maxSizeMB: 1,
            maxWidthOrHeight: 1000,
          },
        },
        {
          name: "tablet_gift_image_data",
          options: {
            maxSizeMB: 2,
            maxWidthOrHeight: 1500,
          },
        },
        {
          name: "desktop_gift_image_data",
          options: {
            maxSizeMB: 3,
            maxWidthOrHeight: 3000,
          },
        },
      ],
      formValues
    );

    this.props.onSubmit(formData);
  };
  setImageUrl = (url, fieldName) => {
    const newStateValue = {};
    newStateValue[fieldName] = url;
    this.setState(newStateValue);
  };
  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Card
          style={{ marginBottom: "30px" }}
          title="Ustawienia zakupu prezentów na treści w repertuarze oraz w sekcji VOD"
          className="gift-config"
        >
          <Field
            name="gift_available"
            component={Checkbox}
            type="checkbox"
            label="Włącz możliwość zakupu dostępu w formie prezentu przy każdym wydarzeniu w repertaurze oraz wideo w VOD"
            size="big"
          />
          <br />
          <Button type="primary" htmlType="submit">
            Zapisz
          </Button>
        </Card>
        <Card
          style={{ marginBottom: "30px" }}
          title="Ustawienia zakupu prezentów na dowolny produkt"
          className="gift-config"
        >
          <Field
            name="any_gift_available"
            component={Checkbox}
            onChange={(e) => {
              this.setState({ any_gift_checked: e.target.checked });
            }}
            type="checkbox"
            label="Włącz możliwość zakupu prezentu na dowolny produkt (strona główna)"
            size="big"
          />
          <br />
          <Field
            showTime
            name="gift_expiration"
            component={makeField(DatePicker, "HH:mm DD.MM.YYYY", false)}
            label={
              "Data do kiedy ma być widoczny widok zakupu prezentu na dowolny produkt:"
            }
            hasFeedback
            onFocus={(e) => e.preventDefault()}
            onBlur={(e) => e.preventDefault()}
            formStyle={{ display: "block", maxWidth: 500 }}
          />
          <Field
            name="gift_price"
            component={Input}
            label="Cena prezentu na dowolny produkt"
            placeholder="np. 10"
            suffix={" zł"}
            type="number"
            step="0.01"
            style={{ display: "block", maxWidth: 300 }}
          />
          <Field
            name="gift_label"
            component={Input}
            label="Tytuł widoku"
            placeholder="Tytuł widoku"
            type="text"
            style={{ maxWidth: 250, marginRight: 40 }}
          />
          <Field
            name="gift_label_en"
            component={Input}
            label="Tytuł widoku (angielski)"
            placeholder="Tytuł widoku (angielski)"
            type="text"
            style={{ maxWidth: 250, marginRight: 40 }}
          />
          <label style={{ maxWidth: 350, marginRight: 40, minHeight: 100 }}>
            Opis prezentu
            <br />
            <Field
              name="gift_description"
              label="Opis prezentu"
              placeholder="Opis prezentu (kod html)"
              component="textarea"
              rows={3}
              style={{
                maxWidth: 350,
                marginRight: 40,
                minHeight: 100,
                marginTop: 5,
              }}
            />
          </label>
          <br />
          <label style={{ maxWidth: 350, marginRight: 40, minHeight: 100 }}>
            Opis prezentu po angielsku
            <br />
            <Field
              name="gift_description_en"
              label="Opis prezentu po angielsku"
              placeholder="Opis prezentu po angielsku (kod html)"
              component="textarea"
              rows={3}
              style={{
                maxWidth: 350,
                marginRight: 40,
                minHeight: 100,
                marginTop: 5,
              }}
            />
          </label>
          <FileField
            setImageUrl={this.setImageUrl}
            name="mobile_gift_image_data"
            urlField="mobile_gift_image"
            label="Zdjęcie prezentu wyświetlane na urządzeniach mobilnych"
            deleteImageFromServer={this.deleteImageFromServer}
            currentImage={this.state.mobile_gift_image}
            maxWidth={1000}
            maxHeight={1000}
            maxWeight={1200}
          />
          <FileField
            setImageUrl={this.setImageUrl}
            name="tablet_gift_image_data"
            urlField="tablet_gift_image"
            label="Zdjęcie prezentu wyświetlane na tabletach"
            deleteImageFromServer={this.deleteImageFromServer}
            currentImage={this.state.tablet_gift_image}
            maxWidth={1500}
            maxHeight={1500}
            maxWeight={2200}
          />
          <FileField
            setImageUrl={this.setImageUrl}
            name="desktop_gift_image_data"
            urlField="desktop_gift_image"
            label="Zdjęcie prezentu wyświetlane na komputerach"
            deleteImageFromServer={this.deleteImageFromServer}
            currentImage={this.state.desktop_gift_image}
            maxWidth={3000}
            maxHeight={3000}
            maxWeight={3200}
          />
          <Button type="primary" htmlType="submit">
            Zapisz
          </Button>
        </Card>
      </form>
    );
  }
}

const validate = ({
  gift_price,
  gift_label,
  gift_label_en,
  gift_description,
  gift_description_en,
  gift_expiration,
}) => {
  const errors = {};
  if (!gift_price) errors.gift_price = "Niepoprawna wartość.";
  if (gift_price && (!/^\d+(.\d{1,2})?$/.test(gift_price) || gift_price == 0))
    errors.gift_price = "Proszę podać wartość większą od 0";

  if (!gift_label) errors.gift_label = "Proszę podać tytuł.";

  if (gift_label && gift_label.length > 100)
    errors.gift_label = "Maksymalnie 100 znaków";
  if (gift_label_en && gift_label_en.length > 100)
    errors.gift_label_en = "Maksymalnie 100 znaków";

  if (!gift_description) errors.gift_description = "Proszę podać tytuł.";
  if (gift_description && gift_description.length > 300)
    errors.gift_description = "Maksymalnie 300 znaków";
  if (gift_description_en && gift_description_en.length > 300)
    errors.gift_description_en = "Maksymalnie 300 znaków";
  return errors;
};

export default reduxForm({
  form: "giftConfigForm",
  enableReinitialize: true,
  validate,
})(withTranslation()(GiftConfig));
