import React from "react";
import moment from "moment";
import { Table, Popconfirm, Tag, Tooltip } from "antd";
import {
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { getPaymentStatus } from "../../../../../helpers/productUtilities";
import { Link } from "react-router-dom";

class InvoiceList extends React.Component {
  state = { dataSource: [] };
  prepareDataSource = (invoices, onlyCompleted, notSent) => {
    const dataSource = invoices
      .filter((i) => {
        if (onlyCompleted && i.paymentStatus !== "COMPLETED") return false;
        if (notSent && i.sent) return false;
        return true;
      })
      .sort((a, b) =>
        moment(a.created_at).isBefore(moment(b.created_at))
          ? 1
          : moment(b.created_at).isBefore(moment(a.created_at))
          ? -1
          : 0
      )
      .map((i) => {
        i.key = `invoice-${i.id}`;
        return i;
      });
    return dataSource;
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.invoices !== this.props.invoices ||
      prevProps.notSent !== this.props.notSent ||
      prevProps.onlyCompleted !== this.props.onlyCompleted
    ) {
      this.setState({
        dataSource: this.prepareDataSource(
          this.props.invoices,
          this.props.onlyCompleted,
          this.props.notSent
        ),
      });
    }
  }
  componentDidMount() {
    this.setState({
      dataSource: this.prepareDataSource(
        this.props.invoices,
        this.props.onlyCompleted,
        this.props.notSent
      ),
    });
  }
  render() {
    const columns = [
      {
        title: "Dane do faktury",
        key: "data",
        width: "25%",
        dataIndex: "data",
        render: (v, r) => {
          const data = JSON.parse(v);
          return (
            <>
              {r.userId ? (
                <>
                  <Link to={`/konto/${r.userId}`}>{r.email}</Link>
                  <br />
                </>
              ) : null}
              {data.company ? (
                <>
                  {data.company}
                  <br />
                  NIP: {data.nip}
                </>
              ) : (
                `${data.firstname} ${data.lastname}`
              )}
              <br />
              {data.street}
              <br />
              {data.city}, {data.state}
              <br />
              {data.postal_code}, {data.country_code}
            </>
          );
        },
      },
      {
        title: "Opis",
        dataIndex: "description",
        key: "description",
        render: (v, r) => {
          return (
            <>
              <b>Data utworzenia:</b>{" "}
              {moment(r.created_at).format("H:mm, DD.MM.YYYY")}
              <br />
              <b>Płatność:</b> {r.extOrderId}
              <br />
              <b>Status płatności:</b>{" "}
              {getPaymentStatus(r.paymentStatus) || "nie znaleziono"}
              <br />
              <b>Koszt:</b> {r.amount} zł
              <br />
              <b>Status faktury:</b> {r.sent ? "Wysłana" : "Niewysłana"}
              <br />
              {r.description}
            </>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "sent",
        key: "status",
        render: (v, r) => {
          return v ? (
            <Tag key="invoice_sent" color="green">
              Wysłana
            </Tag>
          ) : (
            <Tag key="invoice_notsent" color="volcano">
              Niewysłana
            </Tag>
          );
        },
      },
      {
        title: "Akcje",
        key: "action",
        width: "15%",
        render: (text, record) => (
          <>
            <Tooltip title="Usuń wiersz" key="delete">
              <Popconfirm
                title={`Czy na pewno chcesz usunąć fakturę?`}
                onConfirm={() => this.props.deleteInvoiceClick(record.id)}
                okText="Tak"
                cancelText="Nie"
              >
                <DeleteOutlined
                  key="delete-icon"
                  style={{ fontSize: "20px", margin: "5px 10px" }}
                />
              </Popconfirm>
            </Tooltip>
            {!record.sent ? (
              <Tooltip
                title="Zmień status faktury na wysłaną"
                key="changeStatusSent"
              >
                <CheckCircleOutlined
                  onClick={() =>
                    this.props.updateInvoice({ sent: true }, record.id)
                  }
                  key="check"
                  style={{ fontSize: "20px", margin: "5px 10px" }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title="Zmień status faktury na niewysłaną"
                key="changeStatusNotSent"
              >
                <CloseCircleOutlined
                  key="close"
                  onClick={() =>
                    this.props.updateInvoice({ sent: false }, record.id)
                  }
                  style={{ fontSize: "20px", margin: "5px 10px" }}
                />
              </Tooltip>
            )}
          </>
        ),
      },
    ];
    return (
      <>
        <Table
          pagination={false}
          dataSource={this.state.dataSource}
          columns={columns}
        />
      </>
    );
  }
}

export default InvoiceList;
