import React from "react";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { Drawer, Skeleton, Card, Button } from "antd";
import {
  getSlides,
  deleteSlide,
  addSlide,
  updateSlide,
} from "../../../../actions/slidesActions";
import SlideList from "./lists/SlideList";
import SlideForm from "./forms/SlideForm";
import "../managers.scss";
import SlideTranslationsModal from "./others/SlideTranslationsModal";

class SlidesManager extends React.Component {
  state = {
    visible: false,
    mode: "add",
    chosenSlide: null,
    success: true,
    showTranslations: false,
  };
  manageTranslationsClick = (id) => {
    this.setState({
      chosenSlide: id,
      showTranslations: true,
    });
  };
  componentDidMount() {
    this.props.getSlides();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.slideSuccess && prevState.success) {
      return { visible: false };
    }
    return null;
  }
  handleAdd = (formValues) => {
    this.setState({ success: true });
    this.props.addSlide(formValues);
  };
  handleEdit = (formValues) => {
    this.setState({ success: true });
    this.props.updateSlide(formValues, this.state.chosenSlide);
  };
  handleSearch = (props) => {
    this.props.getSlides(props);
  };
  addSlideClick = () => {
    this.setState({
      visible: true,
      mode: "add",
      chosenSlide: null,
      success: false,
    });
  };
  editSlideClick = (id) => {
    this.setState({
      visible: true,
      mode: "edit",
      chosenSlide: id,
      success: false,
    });
  };
  deleteSlideClick = (id) => {
    this.props.deleteSlide(id);
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const slides = this.props.slides;
    const loading = this.props.loading;
    const initialValues = this.state.chosenSlide
      ? _.pick(
          slides.filter((v) => v.id === this.state.chosenSlide)[0],
          "title",
          "text",
          "published",
          "bg_image",
          "url"
        )
      : {
          published: true,
        };
    if (initialValues.text)
      initialValues.text = initialValues.text.replace(/<br \/>/gi, "\n");

    return (
      <>
        {!loading ? (
          <Card
            title="Lista slajdów"
            style={{ marginBottom: 30 }}
            extra={
              <Button type="primary" onClick={this.addSlideClick}>
                Dodaj slajd
              </Button>
            }
            className="slide-list"
          >
            <SlideList
              addSlideClick={this.addSlideClick}
              editSlideClick={this.editSlideClick}
              deleteSlideClick={this.deleteSlideClick}
              manageTranslationsClick={this.manageTranslationsClick}
              updateSlide={this.props.updateSlide}
              onSubmit={this.handleSearch}
              slides={this.props.slides
                .map((s) => {
                  s.key = s.id;
                  s.index = s.weight || s.id;
                  return s;
                })
                .sort((a, b) =>
                  moment(a.updated_at).isBefore(moment(b.updated_at))
                    ? 1
                    : moment(b.updated_at).isBefore(moment(a.updated_at))
                    ? -1
                    : 0
                )}
            />
          </Card>
        ) : (
          <Skeleton />
        )}
        <Drawer
          className="formDrawer"
          width="90%"
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
          maskClosable={false}
        >
          <h2>{this.state.mode === "add" ? "Dodaj slajd" : "Edytuj slajd"}</h2>
          {this.state.visible ? (
            <SlideForm
              mode={this.state.mode}
              onSubmit={
                this.state.mode === "add" ? this.handleAdd : this.handleEdit
              }
              initialValues={initialValues}
            />
          ) : null}
        </Drawer>
        {this.state.showTranslations ? (
          <SlideTranslationsModal
            visible={this.state.showTranslations}
            slide={_.pick(
              this.props.slides.filter(
                (s) => s.id === this.state.chosenSlide
              )[0],
              "id",
              "title",
              "text"
            )}
            closeModal={() => this.setState({ showTranslations: false })}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slideSuccess: state.slides.success,
    slideError: state.slides.error,
    slides: state.slides.slides,
    loading: state.slides.loading,
  };
};

export default connect(mapStateToProps, {
  addSlide,
  updateSlide,
  getSlides,
  deleteSlide,
})(SlidesManager);
