import {
  GALLERIES_LOADING,
  CREATE_GALLERY_SUCCESS,
  CREATE_GALLERY_FAILURE,
  DELETE_GALLERY_SUCCESS,
  DELETE_GALLERY_FAILURE,
  GET_GALLERIES_SUCCESS,
  GET_GALLERIES_FAILURE,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_FAILURE,
  DELETE_GALLERY_IMAGE_SUCCESS,
  DELETE_GALLERY_IMAGE_FAILURE,
  ADD_GALLERY_IMAGE_SUCCESS,
  ADD_GALLERY_IMAGE_FAILURE,
  UPDATE_GALLERY_SUCCESS,
  UPDATE_GALLERY_FAILURE,
} from "../actions/_types";

const initialState = {
  galleries: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GALLERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        galleries: action.payload,
      };
    case DELETE_GALLERY_IMAGE_SUCCESS:
    case ADD_GALLERY_IMAGE_SUCCESS:
    case GET_GALLERY_SUCCESS:
      let found = false;
      let galleries = state.galleries.map((gallery) => {
        if (gallery.id === action.payload.id) {
          found = true;
          return action.payload;
        }
        return gallery;
      });
      if (!found) galleries.push(action.payload);
      return {
        ...state,
        loading: false,
        error: undefined,
        galleries: galleries,
      };
    case DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        galleries: state.galleries.filter(
          (gallery) => gallery.id !== action.payload
        ),
      };
    case CREATE_GALLERY_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        galleries: [action.payload, ...state.galleries],
      };
    case UPDATE_GALLERY_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        galleries: state.galleries.map((gallery) => {
          if (gallery.id === action.payload.id) return action.payload;
          return gallery;
        }),
      };
    case UPDATE_GALLERY_FAILURE:
    case ADD_GALLERY_IMAGE_FAILURE:
    case DELETE_GALLERY_IMAGE_FAILURE:
    case CREATE_GALLERY_FAILURE:
    case GET_GALLERY_FAILURE:
    case GET_GALLERIES_FAILURE:
    case DELETE_GALLERY_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };
    case GALLERIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
