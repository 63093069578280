import {
  BUY_GIFT_LOADING,
  BUY_GIFT_SUCCESS,
  BUY_GIFT_FAILURE,
  GET_GIFT_ORDER_SUCCESS,
  GET_GIFT_ORDER_FAILURE,
  GET_GIFT_ORDERS_FAILURE,
  GET_GIFT_ORDERS_SUCCESS,
  CLEAR_GIFT_PAYMENT_ERROR,
  GET_GIFT_CONFIG_SUCCESS,
  GET_GIFT_CONFIG_FAILURE,
  GIFT_CONFIG_LOADING,
  UPDATE_GIFT_CONFIG_SUCCESS,
  UPDATE_GIFT_CONFIG_FAILURE,
  CLEAR_GIFT_CONFIG_STATUS,
} from "../actions/_types";

const initialState = {
  config: {},
  order: {},
  orders: [],
  refunds: 0,
  loading: false,
  configLoading: true,
  updateSuccess: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BUY_GIFT_LOADING:
      return {
        ...state,
        loading: true,
        eventId: action.payload,
      };
    case BUY_GIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        eventId: undefined,
      };
    case BUY_GIFT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        eventId: undefined,
      };
    case GET_GIFT_ORDER_SUCCESS:
      return {
        ...state,
        success: true,
        order: action.payload,
      };
    case GET_GIFT_ORDER_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case GET_GIFT_ORDERS_SUCCESS:
      return {
        ...state,
        success: true,
        orders: action.payload.orders,
        refunds: action.payload.refunds,
      };
    case GET_GIFT_ORDERS_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case CLEAR_GIFT_PAYMENT_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case GET_GIFT_CONFIG_SUCCESS:
      return {
        ...state,
        configLoading: false,
        error: undefined,
        getSuccess: true,
        config: action.payload,
      };
    case GET_GIFT_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload,
        getSuccess: false,
        configLoading: false,
      };
    case UPDATE_GIFT_CONFIG_SUCCESS:
      return {
        ...state,
        error: undefined,
        updateSuccess: true,
        config: action.payload,
      };
    case UPDATE_GIFT_CONFIG_FAILURE:
      return {
        ...state,
        updateSuccess: false,
        error: action.payload,
      };
    case CLEAR_GIFT_CONFIG_STATUS:
      return {
        ...state,
        updateSuccess: false,
        error: false,
      };
    case GIFT_CONFIG_LOADING:
      return {
        ...state,
        configLoading: true,
      };
    default:
      return state;
  }
}
