import { setErrorPage } from "./errorPagesActions";
import { signOutUser } from "./authActions";

export const checkStatusCode = (
  statusCode,
  dispatch,
  message,
  link = undefined
) => {
  switch (statusCode) {
    case 500:
    case 404:
      dispatch(setErrorPage(statusCode, message, link));
      return null;
    case 401:
    case 400:
    case 403:
      if (localStorage.getItem("user")) {
        dispatch(signOutUser());
        break;
      } else return message;
    default:
      return message;
  }
};

export const dispatchFormFailure = (type, payload, dispatch) => {
  dispatch({ type, payload });
};

const handleErrors = (err, dispatch, errorType, formErrorType) => {
  let { statusCode, message, formError } = err.response.data;
  if (formError && formErrorType)
    dispatchFormFailure(formErrorType, formError, dispatch);
  message = checkStatusCode(statusCode, dispatch, message);
  dispatch({ type: errorType, payload: message });
};

export default handleErrors;
