import React from "react";
import Input from "../../../../form-components/Input";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { Button } from "antd";

class EventTranslationForm extends React.Component {
  state = {};
  handleFormSubmit = async (formValues) => {
    this.props.onSubmit(
      _.pick(
        formValues,
        "title",
        "language_code",
        "event_id",
        "subtitle"
      )
    );
  };
  render() {
    const { mode } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
        <h2>{mode === "edit" ? "Edytuj" : "Dodaj"} tłumaczenie:</h2>
        <Field
          name="language_code"
          component={Input}
          placeholder="Język (np. en)"
          type="text"
          style={{ display: mode === "edit" ? "none" : "block" }}
        />
        <Field
          name="title"
          component={Input}
          placeholder="Tytuł"
          type="text"
        />
        <Field
          name="subtitle"
          component={Input}
          placeholder="Podtytuł"
          type="text"
        />
        <Button type="primary" htmlType="submit" block="true">
          {this.props.mode === "add" ? "Dodaj" : "Zaktualizuj"}
        </Button>
      </form>
    );
  }
}

const validate = ({ title, language_code, subtitle }) => {
  const errors = {};
  if (title && title.length > 100)
    errors.title = "Maksymalnie 100 znaków.";
  if (subtitle && subtitle.length > 200)
    errors.subtitle = "Maksymalnie 200 znaków.";
  if (!language_code) errors.language_code = "Proszę wybrać język.";
  else if (language_code.length !== 2)
    errors.language_code = "Niepoprawny język";
  return errors;
};

export default reduxForm({ form: "eventTranslationForm", validate })(
  EventTranslationForm
);
