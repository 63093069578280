import {
  BUY_GROUP_TICKET_LOADING,
  BUY_GROUP_TICKET_SUCCESS,
  BUY_GROUP_TICKET_FAILURE,
  GET_GROUP_TICKET_ORDER_SUCCESS,
  GET_GROUP_TICKET_ORDER_FAILURE,
  GET_GROUP_TICKET_ORDERS_FAILURE,
  GET_GROUP_TICKET_ORDERS_SUCCESS,
  CLEAR_GROUP_TICKET_PAYMENT_ERROR,
  GET_GROUP_TICKET_CONFIG_SUCCESS,
  GET_GROUP_TICKET_CONFIG_FAILURE,
  GROUP_TICKET_CONFIG_LOADING,
  UPDATE_GROUP_TICKET_CONFIG_SUCCESS,
  UPDATE_GROUP_TICKET_CONFIG_FAILURE,
  CLEAR_GROUP_TICKET_CONFIG_STATUS,
} from "../actions/_types";

const initialState = {
  order: {},
  orders: [],
  refunds: 0,
  loading: false,
  configLoading: true,
  updateSuccess: false,
  config: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BUY_GROUP_TICKET_LOADING:
      return {
        ...state,
        loading: true,
        eventId: action.payload,
      };
    case BUY_GROUP_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        eventId: undefined,
      };
    case BUY_GROUP_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        eventId: undefined,
      };
    case GET_GROUP_TICKET_ORDER_SUCCESS:
      return {
        ...state,
        success: true,
        order: action.payload,
      };
    case GET_GROUP_TICKET_ORDER_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case GET_GROUP_TICKET_ORDERS_SUCCESS:
      return {
        ...state,
        success: true,
        orders: action.payload.orders,
        refunds: action.payload.refunds,
      };
    case GET_GROUP_TICKET_ORDERS_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case CLEAR_GROUP_TICKET_PAYMENT_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case GET_GROUP_TICKET_CONFIG_SUCCESS:
      return {
        ...state,
        configLoading: false,
        error: undefined,
        getSuccess: true,
        config: action.payload,
      };
    case GET_GROUP_TICKET_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload,
        getSuccess: false,
        configLoading: false,
      };
    case UPDATE_GROUP_TICKET_CONFIG_SUCCESS:
      return {
        ...state,
        error: undefined,
        config: action.payload,
        updateSuccess: true,
      };
    case UPDATE_GROUP_TICKET_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload,
        updateSuccess: false,
      };
    case CLEAR_GROUP_TICKET_CONFIG_STATUS:
      return {
        ...state,
        updateSuccess: false,
        error: false,
      };
    case GROUP_TICKET_CONFIG_LOADING:
      return {
        ...state,
        configLoading: true,
      };
    default:
      return state;
  }
}
