import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { Alert, Button, Row, Col } from "antd";
import createFormData from "../../../../../helpers/createFormData";
import Checkbox from "../../../../form-components/Checkbox";
import FileField from "../../../../form-components/FileField";
import Input from "../../../../form-components/Input";

class AdvertisementForm extends React.Component {
  state = {
    mobile_free_ad_image: this.props.initialValues
      .mobile_free_ad_image,
    tablet_free_ad_image: this.props.initialValues
      .tablet_free_ad_image,
    desktop_free_ad_image: this.props.initialValues
      .desktop_free_ad_image,
    mobile_paid_ad_image: this.props.initialValues
      .mobile_paid_ad_image,
    tablet_paid_ad_image: this.props.initialValues
      .tablet_paid_ad_image,
    desktop_paid_ad_image: this.props.initialValues
      .desktop_paid_ad_image,
    free_ad_url: this.props.initialValues.free_ad_url,
    paid_ad_url: this.props.initialValues.paid_ad_url,
    show_advertisement: this.props.initialValues.show_advertisement,
  };

  setImageUrl = (url, fieldName) => {
    const newStateValue = {};
    newStateValue[fieldName] = url;
    this.setState(newStateValue);
  };
  deleteImageFromServer = (urlField) => {
    this.props.deleteImgInConfig(urlField).then(() => {
      this.props.dispatch(change("advertisementForm", urlField, ""));
    });
  };
  onSubmit = async (formValues) => {
    const formData = await createFormData(
      [
        "show_advertisement",
        "free_ad_url",
        "paid_ad_url",
        "mobile_free_ad_image",
        "tablet_free_ad_image",
        "desktop_free_ad_image",
        "mobile_paid_ad_image",
        "tablet_paid_ad_image",
        "desktop_paid_ad_image",
        "mobile_free_img_data",
        "tablet_free_img_data",
        "desktop_free_img_data",
        "mobile_paid_img_data",
        "tablet_paid_img_data",
        "desktop_paid_img_data",
      ],
      [
        {
          name: "mobile_free_img_data",
          options: {
            maxSizeMB: 1,
            maxWidthOrHeight: 1000,
          },
        },
        {
          name: "mobile_paid_img_data",
          options: {
            maxSizeMB: 1,
            maxWidthOrHeight: 1000,
          },
        },
        {
          name: "tablet_free_img_data",
          options: {
            maxSizeMB: 2,
            maxWidthOrHeight: 1500,
          },
        },
        {
          name: "tablet_paid_img_data",
          options: {
            maxSizeMB: 2,
            maxWidthOrHeight: 1500,
          },
        },
        {
          name: "desktop_free_img_data",
          options: {
            maxSizeMB: 3,
            maxWidthOrHeight: 3000,
          },
        },
        {
          name: "desktop_paid_img_data",
          options: {
            maxSizeMB: 3,
            maxWidthOrHeight: 3000,
          },
        },
      ],
      formValues
    );

    this.props.onSubmit(formData);
  };
  render() {
    const error = this.props.error;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          name="show_advertisement"
          component={Checkbox}
          type="checkbox"
          label="Czy wyświetlać reklamy pod odtwarzaczem?"
          size="big"
        />
        <br />
        <Row>
          <Col xs={24} md={12}>
            <h3>
              <b>Reklama wyświetlana pod darmowymi filmami</b>
            </h3>
            <Field
              name="free_ad_url"
              component={Input}
              placeholder="Odnośnik reklamy"
              onChange={(e) => {
                this.setState({ free_ad_url: e.target.value });
              }}
              style={{ maxWidth: 300 }}
              type="text"
            />
            <FileField
              name="mobile_free_img_data"
              urlField="mobile_free_ad_image"
              label="Reklama wyświetlana na smartfonach"
              setImageUrl={this.setImageUrl}
              deleteImageFromServer={this.deleteImageFromServer}
              currentImage={this.state.mobile_free_ad_image}
              maxWidth={1000}
              maxHeight={1000}
              maxWeight={1200}
            />
            <FileField
              name="tablet_free_img_data"
              urlField="tablet_free_ad_image"
              label="Reklama wyświetlana na tabletach"
              setImageUrl={this.setImageUrl}
              deleteImageFromServer={this.deleteImageFromServer}
              currentImage={this.state.tablet_free_ad_image}
              maxWidth={1500}
              maxHeight={1500}
              maxWeight={2200}
            />
            <FileField
              name="desktop_free_img_data"
              urlField="desktop_free_ad_image"
              label="Reklama wyświetlana na komputerach"
              setImageUrl={this.setImageUrl}
              deleteImageFromServer={this.deleteImageFromServer}
              currentImage={this.state.desktop_free_ad_image}
              maxWidth={3000}
              maxHeight={3000}
              maxWeight={3200}
            />
          </Col>
          <Col xs={24} md={12}>
            <h3>
              <b>Reklama wyświetlana pod płatnymi filmami</b>
            </h3>
            <Field
              name="paid_ad_url"
              component={Input}
              placeholder="Odnośnik reklamy"
              onChange={(e) => {
                this.setState({ paid_ad_url: e.target.value });
              }}
              style={{ maxWidth: 300 }}
              type="text"
            />
            <FileField
              name="mobile_paid_img_data"
              urlField="mobile_paid_ad_image"
              label="Reklama wyświetlana na smartfonach"
              setImageUrl={this.setImageUrl}
              deleteImageFromServer={this.deleteImageFromServer}
              currentImage={this.state.mobile_paid_ad_image}
              maxWidth={1000}
              maxHeight={1000}
              maxWeight={1200}
            />
            <FileField
              name="tablet_paid_img_data"
              urlField="tablet_paid_ad_image"
              label="Reklama wyświetlana na tabletach"
              setImageUrl={this.setImageUrl}
              deleteImageFromServer={this.deleteImageFromServer}
              currentImage={this.state.tablet_paid_ad_image}
              maxWidth={1500}
              maxHeight={1500}
              maxWeight={2200}
            />
            <FileField
              name="desktop_paid_img_data"
              urlField="desktop_paid_ad_image"
              label="Reklama wyświetlana na komputerach"
              setImageUrl={this.setImageUrl}
              deleteImageFromServer={this.deleteImageFromServer}
              currentImage={this.state.desktop_paid_ad_image}
              maxWidth={3000}
              maxHeight={3000}
              maxWeight={3200}
            />
          </Col>
        </Row>

        {error ? (
          <Alert message={error} type="error" showIcon />
        ) : null}
        <br />
        <Button type="primary" htmlType="submit">
          Zaktualizuj
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (
    formValues.paid_ad_url &&
    !/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/.test(
      formValues.paid_ad_url
    )
  )
    errors.paid_ad_url = "Niepoprawny odnośnik";
  if (
    formValues.free_ad_url &&
    !/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/.test(
      formValues.free_ad_url
    )
  )
    errors.free_ad_url = "Niepoprawny odnośnik";
  return errors;
};

export default reduxForm({ form: "advertisementForm", validate })(
  AdvertisementForm
);
