import React, { Component } from "react";
import Slider from "react-slick";
import Slide from "./Slide";
import {
  sliderAutoplay,
  slidesTransition,
  changeSlideTime,
  sliderStyle,
} from "../../../theme-variables";
import "./slick-theme.scss";
import "./Slider.scss";

export default class SlickSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: sliderAutoplay,
      autoplaySpeed: changeSlideTime,
      speed: slidesTransition,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: false,
      nextArrow: false,
    };
    let { slides, loading } = this.props.slides;
    slides = slides.filter(
      (slide) => slide.published === 1 || slide.published === true
    );
    if (!loading)
      return (
        <div>
          <Slider className={`slider-${sliderStyle}`} {...settings}>
            {slides.map((slide) => {
              if (slide.url)
                return (
                  <a
                    style={{ cursor: "pointer" }}
                    key={slide.id}
                    onClick={() => {
                      if (slide.url.includes("#"))
                        document.querySelector(slide.url).scrollIntoView({
                          block: "start",
                          behavior: "smooth",
                        });
                      else {
                        const newWindow = window.open(slide.url, "_blank");
                        newWindow.focus();
                      }
                    }}
                  >
                    <Slide data={slide} />
                  </a>
                );
              return <Slide data={slide} key={slide.id} />;
            })}
          </Slider>
        </div>
      );
    else return null;
  }
}
