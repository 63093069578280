import React from "react";
import { Field, reduxForm } from "redux-form";
import { Alert, Tooltip, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Input from "../../../../form-components/Input";
import FileField from "../../../../form-components/FileField";
import Checkbox from "../../../../form-components/Checkbox";
import createFormData from "../../../../../helpers/createFormData";

const renderTextArea = ({ input, meta: { touched, error } }) => (
  <div>
    <textarea
      {...input}
      placeholder="Tekst slajdu"
      style={{ width: "100%", minHeight: 100 }}
    ></textarea>
    {touched && error && (
      <span style={{ color: "red " }}>{error}</span>
    )}
    <br />
    <br />
  </div>
);

class SlideForm extends React.Component {
  state = {
    bg_image: this.props.initialValues.bg_image,
  };

  onSubmit = async (formValues) => {
    const formData = await createFormData(
      [
        "title",
        "bg_image",
        "bg_image_data",
        "text",
        "published",
        "url",
      ],
      [
        {
          name: "bg_image",
          options: {
            maxSizeMB: 4,
            maxWidthOrHeight: 5000,
            useWebWorker: true,
          },
        },
      ],
      formValues
    );
    this.props.onSubmit(formData);
  };

  setImageUrl = (url, fieldName) => {
    const newStateValue = {};
    newStateValue[fieldName] = url;
    this.setState(newStateValue);
  };
  render() {
    const error = this.props.error;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          name="title"
          component={Input}
          placeholder="Tytuł slajdu"
          type="text"
        />
        <Field
          name="url"
          component={Input}
          placeholder="Odnośnik"
          type="text"
        />
        <Field
          name="text"
          component={renderTextArea}
          placeholder="Tekst slajdu"
        />
        <FileField
          name="bg_image_data"
          urlField="bg_image"
          label="Zdjęcie"
          setImageUrl={this.setImageUrl}
          currentImage={this.state.bg_image}
          maxWidth={4000}
          maxHeight={4000}
          maxWeight={5200}
        />
        <Field
          name="published"
          component={Checkbox}
          type="checkbox"
          label="Opublikowany"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli slajd ma wyświetlić się na stronie głównej">
              <InfoCircleOutlined
                style={{ color: "rgba(0,0,0,.45)" }}
              />
            </Tooltip>
          }
        />
        {error ? (
          <Alert message={error} type="error" showIcon />
        ) : null}
        <br />
        <Button type="primary" htmlType="submit" block="true">
          {this.props.mode === "add" ? "Dodaj" : "Zaktualizuj"}
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.title) errors.title = "Proszę podać tytuł.";

  if (!formValues.text) errors.text = "Proszę podać tekst slajdu";

  return errors;
};

export default reduxForm({ form: "slideForm", validate })(SlideForm);
