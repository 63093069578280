import React, { Component } from "react";
import { Modal, Form, Select, Button, DatePicker } from "antd";
import moment from "moment";
import countries from "../../../../../helpers/countries";

class PaymentModal extends Component {
  state = {
    loading: false,
    country: this.props.payment ? this.props.payment.country : "PL",
    paymentStatus: this.props.payment ? this.props.payment.status : null,
    expirationDate: this.props.editExpirationDate
      ? this.props.payment && this.props.payment.expiration_date
        ? moment(this.props.payment.expiration_date)
        : null
      : null,
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.payment !== this.props.payment) {
      this.setState({
        paymentStatus: this.props.payment ? this.props.payment.status : null,
        expirationDate: this.props.editExpirationDate
          ? this.props.payment && this.props.payment.expiration_date
            ? moment(this.props.payment.expiration_date)
            : null
          : null,
        country: this.props.payment ? this.props.payment.country : null,
      });
    }
  }
  onSubmit = () => {
    let formValues = {
      status: this.state.paymentStatus,
      country: this.state.country,
    };
    if (this.props.editExpirationDate)
      formValues.expiration_date = this.state.expirationDate;

    this.props.editPayment(formValues);
  };
  render() {
    return (
      <Modal
        title={`Zarządzaj płatnością`}
        visible={this.props.visible}
        footer={null}
        onCancel={this.props.onClose}
      >
        <Form>
          <div>
            <label style={{ display: "inline-block" }}>
              Status zamówienia:
            </label>
            <br />
            <Select
              name="status"
              value={this.state.paymentStatus}
              onChange={(val) => {
                this.setState({ paymentStatus: val });
              }}
            >
              <Select.Option disabled value="NOT_STARTED">
                Płatność rozpoczęta
              </Select.Option>
              <Select.Option disabled value="WAITING_FOR_REFUND">
                Oczekiwanie na zwrot
              </Select.Option>
              <Select.Option disabled value="WAITING_FOR_CONFIRMATION">
                Oczekiwanie na potwierdzenie
              </Select.Option>
              <Select.Option value="COMPLETED">Zakończone</Select.Option>
              <Select.Option value="CANCELED">Anulowane</Select.Option>
              <Select.Option value="REFUNDED">Zwrócone</Select.Option>
              <Select.Option value="PENDING">Przetwarzane</Select.Option>
            </Select>
          </div>
          <br />
          <label style={{ display: "inline-block" }}>Kraj:</label>
          <br />
          <Select
            allowClear
            showSearch
            placeholder={"Kraj"}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            dropdownMatchSelectWidth={false}
            value={this.state.country}
            onChange={(val) => {
              this.setState({ country: val });
            }}
          >
            {countries.map((v) => (
              <Select.Option key={v.code} value={v.code}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
          {this.props.editExpirationDate ? (
            <>
              <br />
              <br />
              <label style={{ display: "inline-block" }}>
                Data wygaśnięcia dostępu:
              </label>
              <br />
              <DatePicker
                onChange={(val) => {
                  this.setState({ expirationDate: val });
                }}
                value={this.state.expirationDate}
                showTime
                onFocus={(e) => e.preventDefault()}
                onBlur={(e) => e.preventDefault()}
                name="expiration_date"
              />
            </>
          ) : null}
          <br />
          <br />
          <Button
            htmlType="submit"
            type="primary"
            loading={this.state.loading}
            block="true"
            onClick={this.onSubmit}
          >
            Zapisz
          </Button>
        </Form>
      </Modal>
    );
  }
}

export default PaymentModal;
