import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Input, Button, Popconfirm, Form, message, Tooltip } from "antd";
import { DeleteOutlined, FileImageOutlined } from "@ant-design/icons";

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class GalleriesList extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Nazwa galerii",
        dataIndex: "name",
        editable: true,
      },
      {
        title: "Operacje",
        dataIndex: "operation",
        render: (text, record) =>
          this.props.galleries.length >= 1 ? (
            <>
              <Tooltip title="Zarządzaj zdjęciami">
                <FileImageOutlined
                  key="edit"
                  onClick={() => this.props.manageImagesClick(record.id)}
                  style={{ fontSize: 20, margin: "0 10px" }}
                />
              </Tooltip>
              <Tooltip title="Usuń galerię">
                <Popconfirm
                  title="Czy chcesz usunąć galerię?"
                  onConfirm={() => this.handleDelete(record.key)}
                >
                  <DeleteOutlined
                    key="delete"
                    style={{ fontSize: 20, margin: "0 10px" }}
                  />
                </Popconfirm>
              </Tooltip>
            </>
          ) : null,
      },
    ];
  }
  componentDidMount() {
    this.props.getGalleries();
  }
  handleDelete = (key) => {
    this.props.deleteGallery(key);
  };

  handleAdd = () => {
    this.props.createGallery({ name: "Nowa galeria" });
  };

  handleSave = (row) => {
    if (!/^[0-9A-Za-zzżźćńółęąśŻŹĆĄŚĘŁÓŃ\\s()!-+#?%@.]*$/.test(row.name))
      message.error("Niepoprawna nazwa. Dozwolone litery, cyfry i spacje");
    if (this.props.loading)
      message.error("Proszę spróbować ponownie za kilka sekund.");
    else this.props.updateGallery({ name: row.name }, row.id);
  };

  render() {
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    let dataSource = [];
    if (this.props.galleries)
      dataSource = this.props.galleries.map((c) => {
        c.key = c.id;
        return c;
      });
    return (
      <div>
        <Button
          onClick={this.handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          Dodaj galerię
        </Button>
        <Table
          pagination={false}
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <ul style={{ margin: 0 }}>
                {record.videos.map((v) => (
                  <li key={v.id}>
                    <Link to={`/wideo/${v.slug || v.id}`}>{v.title}</Link>
                  </li>
                ))}
              </ul>
            ),
            rowExpandable: (record) =>
              record.videos && record.videos.length > 0,
          }}
        />
      </div>
    );
  }
}

export default GalleriesList;
