import React from "react";
import FormItem from "antd/lib/form/FormItem";
import { useTranslation } from "react-i18next";

const CustomSelect = ({
  input,
  meta,
  children,
  hasFeedback,
  label,
  ...rest
}) => {
  const { t } = useTranslation();
  const error = meta.touched && meta.error !== null ? meta.error : null;
  return (
    <FormItem
      label={label}
      hasFeedback
      help={error ? t(error) : null}
      validateStatus={error ? "error" : null}
    >
      <select
        {...input}
        {...rest}
        style={{
          border: "1px solid black",
          background: "transparent",
          padding: "5px 10px",
        }}
      >
        {children}
      </select>
    </FormItem>
  );
};

export default CustomSelect;
