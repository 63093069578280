import vod, { tokenConfig } from "../apis/vod";
import {
  GET_VIMEO_VIDEOS_FAILURE,
  GET_VIMEO_VIDEOS_SUCCESS,
  GET_VIMEO_VIDEO_FAILURE,
  GET_VIMEO_VIDEO_SUCCESS,
  DELETE_VIMEO_VIDEO_FAILURE,
  DELETE_VIMEO_VIDEO_SUCCESS,
  ADD_VIMEO_VIDEO_FAILURE,
  ADD_VIMEO_VIDEO_SUCCESS,
  FORM_VIMEO_VIDEO_FAILURE,
  UPDATE_VIMEO_VIDEO_SUCCESS,
  UPDATE_VIMEO_VIDEO_FAILURE,
} from "./_types";
import handleErrors from "./handleErrors";

export const getVideos = (props = {}) => (dispatch, getState) => {
  vod
    .get(`/api/vimeo`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_VIMEO_VIDEOS_SUCCESS,
        payload: res.data.videos,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_VIMEO_VIDEOS_FAILURE);
    });
};

export const getVideo = (id) => (dispatch, getState) => {
  vod
    .get(`/api/vimeo/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_VIMEO_VIDEO_SUCCESS,
        payload: res.data.video,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_VIMEO_VIDEO_FAILURE);
    });
};

export const deleteVideo = (id) => (dispatch, getState) => {
  vod
    .delete(`/api/vimeo/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_VIMEO_VIDEO_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_VIMEO_VIDEO_FAILURE);
    });
};

export const addVideo = (props) => (dispatch, getState) => {
  vod
    .post(`/api/vimeo/`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_VIMEO_VIDEO_SUCCESS,
        payload: res.data.event,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        ADD_VIMEO_VIDEO_FAILURE,
        FORM_VIMEO_VIDEO_FAILURE
      );
    });
};

export const updateVideo = (props, id) => (dispatch, getState) => {
  vod
    .put(`/api/vimeo/${id}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_VIMEO_VIDEO_SUCCESS,
        payload: res.data.video,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        UPDATE_VIMEO_VIDEO_FAILURE,
        FORM_VIMEO_VIDEO_FAILURE
      );
    });
};
