import vod, { tokenConfig } from "../apis/vod";
import {
  BUY_GROUP_TICKET_LOADING,
  BUY_GROUP_TICKET_SUCCESS,
  BUY_GROUP_TICKET_FAILURE,
  GET_GROUP_TICKET_ORDER_SUCCESS,
  GET_GROUP_TICKET_ORDER_FAILURE,
  GET_GROUP_TICKET_ORDERS_SUCCESS,
  GET_GROUP_TICKET_ORDERS_FAILURE,
  CLEAR_GROUP_TICKET_PAYMENT_ERROR,
  GROUP_TICKET_CONFIG_LOADING,
  GET_GROUP_TICKET_CONFIG_SUCCESS,
  GET_GROUP_TICKET_CONFIG_FAILURE,
  UPDATE_GROUP_TICKET_CONFIG_SUCCESS,
  UPDATE_GROUP_TICKET_CONFIG_FAILURE,
  GROUP_TICKET_FORM_ERRORS,
  CLEAR_GROUP_TICKET_CONFIG_STATUS,
} from "./_types";
import handleErrors from "./handleErrors";
import history from "../history";
import { facebookPixel, googleTagManager } from "../theme-variables";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

export const buyGroupTicket = (productId, productType, units, props) => (
  dispatch,
  getState
) => {
  dispatch({
    type: BUY_GROUP_TICKET_LOADING,
    payload: productId,
  });
  vod
    .post(
      `/api/groupTickets/`,
      {
        ...props,
        productSlug: `${productType}-${productId}-${units}`,
      },
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: BUY_GROUP_TICKET_SUCCESS });
      if (facebookPixel && res.data.payu && res.data.order) {
        const { order } = res.data;
        ReactPixel.track("InitiateCheckout", {
          content_ids: [order.product_id],
          content_category: order.type,
          contents: order.contents,
          content_type: "product",
          currency: "PLN",
          value: parseFloat(order.value),
        });
      }

      if (googleTagManager && res.data.payu && res.data.order) {
        const { order } = res.data;
        TagManager.dataLayer({ ecommerce: null });
        TagManager.dataLayer({
          event: "checkout",
          ecommerce: {
            checkout: {
              actionField: {
                step: 1,
                option: parseFloat(order.value) == 0 ? "free" : "payu",
              },
              products: [
                {
                  name: order.title,
                  id: order.product_id,
                  price: order.value,
                  category: order.type,
                  quantity: order.contents.productQuantity,
                },
              ],
            },
          },
          eventCallback: function () {
            document.location = res.data.payu.redirectUri;
          },
        });
      }
      if (res.data.payu) window.location.href = res.data.payu.redirectUri;
      else history.go(0);
    })
    .catch((err) => {
      handleErrors(err, dispatch, BUY_GROUP_TICKET_FAILURE);
    });
};

export const getOrder = (extOrderId) => (dispatch, getState) => {
  vod
    .get(`/api/groupTickets/${extOrderId}`, {
      headers: {
        "x-auth-token": getState().auth.token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_GROUP_TICKET_ORDER_SUCCESS,
        payload: res.data.order,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GROUP_TICKET_ORDER_FAILURE);
    });
};

export const getUserOrders = () => (dispatch, getState) => {
  vod
    .get(`/api/groupTickets/user`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_GROUP_TICKET_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GROUP_TICKET_ORDERS_FAILURE);
    });
};

export const getOrders = (props) => (dispatch, getState) => {
  vod
    .get(`/api/groupTickets/`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_GROUP_TICKET_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GROUP_TICKET_ORDERS_FAILURE);
    });
};

export const getGroupTicketSettings = (props) => (dispatch, getState) => {
  dispatch({ type: GROUP_TICKET_CONFIG_LOADING });
  vod
    .get(`/api/groupTickets/settings`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_GROUP_TICKET_CONFIG_SUCCESS,
        payload: res.data.config,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_GROUP_TICKET_CONFIG_FAILURE);
    });
};

export const updateGroupTicketSettings = (props) => (dispatch, getState) => {
  vod
    .put(`/api/groupTickets/settings`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_GROUP_TICKET_CONFIG_SUCCESS,
        payload: res.data.config,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        UPDATE_GROUP_TICKET_CONFIG_FAILURE,
        GROUP_TICKET_FORM_ERRORS
      );
    });
};

export const clearGroupTicketPaymentError = () => (dispatch) => {
  dispatch({ type: CLEAR_GROUP_TICKET_PAYMENT_ERROR });
};

export const clearGroupTicketConfigStatus = () => (dispatch) => {
  dispatch({ type: CLEAR_GROUP_TICKET_CONFIG_STATUS });
};
