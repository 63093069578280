import {
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_RESET_PASSWORD_SUCCESS,
  VERIFY_RESET_PASSWORD_FAILURE,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  RESET_PASSWORD_LOADING,
} from "../actions/_types";

const initialState = {
  reset: { error: null },
  verify: { error: null },
  newPassword: { error: null },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        reset: {
          loading: true,
        },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset: {
          success: true,
          error: null,
        },
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        reset: {
          success: false,
          error: action.payload,
        },
      };
    case VERIFY_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        verify: {
          success: true,
          error: null,
        },
      };
    case VERIFY_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        verify: {
          success: false,
          error: action.payload,
        },
      };
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPassword: {
          success: true,
          error: null,
        },
      };

    case SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        newPassword: {
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
