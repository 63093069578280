import React from "react";
import { withTranslation } from "react-i18next";
import { Result, Button } from "antd";
import imageMaintenance from "../../static/maintenance.png";
import logo from "../../static/logo.svg";
import {
  maintenanceSign,
  maintenanceSignEn,
  maintenanceImgHeight,
  maintenanceSignUrl,
  maintenanceLogoInsteadOfSign,
} from "../../theme-variables";

const MaintenanceImage = () => {
  return (
    <img
      src={imageMaintenance}
      width={maintenanceImgHeight || 200}
      height="auto"
      alt="Ikona prac konserwacyjnych"
    />
  );
};

class Maitenance extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Result
        icon={<MaintenanceImage />}
        title={t(
          "maintenance.text",
          "Aktualnie pracujemy nad platformą. Do zobaczenia wkrótce!"
        )}
        extra={
          maintenanceLogoInsteadOfSign ? (
            <img src={logo} height={100} alt="logo instytucji" />
          ) : (
            <Button type="primary">
              <a href={maintenanceSignUrl || "#"}>
                {this.props.i18n.language === "pl"
                  ? maintenanceSign
                  : maintenanceSignEn ||
                    t("maintenance.team", "Zespół Teapp.pl")}
              </a>
            </Button>
          )
        }
      />
    );
  }
}

export default withTranslation()(Maitenance);
