import React from "react";
import { Card } from "antd";
import "../managers.scss";

class Help extends React.Component {
  render() {
    return (
      <>
        <Card
          style={{ marginBottom: "30px" }}
          title="FAQ"
          className="dictionaryCard"
        >
          <a
            href="https://teapp.pl/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            Przejdź na stronę z FAQ
          </a>
        </Card>
        <Card
          style={{ marginBottom: "30px" }}
          title="Panel zgłoszeń"
          className="dictionaryCard"
        >
          <a
            href="https://panel.studiofnc.pl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Przejdź do panelu zgłoszeń
          </a>
        </Card>
        <Card
          style={{ marginBottom: "30px" }}
          title="Słownik pojęć"
          className="dictionaryCard"
        >
          <p>
            <b>Widok repertuarowy (repertuar)</b> – umożliwia planowanie
            wydarzeń dostępnych o określonej porze, określonego dnia. Jest
            odwzorowaniem standardowego repertuaru teatralnego. Użytkownik,
            który kupił dostęp do treści w ramach repertuaru wykupuje dostęp na
            wcześniej zdefiniowany zakres. Użytkownik kupuje dostęp na konkretne
            wydarzenie na określoną datę i godzinę. <br />
            <br />
            Jeżeli administrator systemu chce zaplanować jedno wydarzenie na
            kilka dni (czego nie zalecamy) – wydarzenie będzie dostępne w
            konkretnym zakresie godzinowym określonym przez administratora
            (każdego dnia w takim samym zakresie).
          </p>
          <p>
            <b>Widok VOD</b> – umożliwia udostępnianie filmów z założenia
            dostępnych na dłużej (albo na zawsze). Administrator może zaplanować
            datę rozpoczęcia i zakończenia udostępniania treści. Treści
            podlegają ograniczeniom definiowanych w panelu administracyjnym w
            zakładce ograniczenia, w sekcji „Czas ważności biletów dla treści
            VOD” – administrator może zdefiniować jak długo od czasu zakupu
            dostępu do treści VOD użytkownik będzie miał dostęp.{" "}
          </p>
          <p>
            <b>Widok festiwalowy (festiwal)</b> – jest rozbudowaniem widoku
            repertuarowego, poszerzony jest o więcej opcji oraz posiada inny
            widok graficzny na stronie głównej. Aby włączyć widok festiwalowy w
            panelu administracyjnym w zakładce „Festiwal” administrator musi
            zaznaczyć opcję „Pokaż blok „Festiwal” na stronie głównej”.
            Natomiast umieszczanie wydarzeń w widoku festiwalowym odbywa się
            poprzez dodawanie wydarzeń i zaznaczanie opcji „wydarzenie
            festiwalowe”.
          </p>
          <p>
            <b>Wydarzenie</b> – posiada swoją datę i godzinę w których jest
            dostępne. Wydarzenia dostępne są w widoku repertuarowym bądź w
            widoku festiwalowym. Wydarzenia dodaje się poprzez zakładkę
            „Repertuar” w panelu administracyjnym.{" "}
          </p>
          <p>
            <b>Wideo</b> – występuje w sekcji VOD, może mieć zdefiniowane
            zakresy dostępności treści. Treści wideo dodaje się poprzez zakładkę
            „Dodawanie wideo” i kliknięcie przycisku „Dodaj wideo”.{" "}
          </p>
        </Card>
      </>
    );
  }
}

export default Help;
