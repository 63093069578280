import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { checkEventStatusByDate } from "../../../helpers/productUtilities";
import FestivalEventBlock from "./FestivalEventBlock";
import "./FestivalEvents.scss";

class FestivalEvents extends React.Component {
  state = {};
  render() {
    const { t, shortDates, showFinished, reverseFinished } = this.props;
    let events = this.props.events.map((event) => {
      event.eventStatus = checkEventStatusByDate(
        event.date_from,
        event.date_to,
        event.full_day,
        event.time_from,
        event.time_to
      );
      return event;
    });
    if (!showFinished)
      events = events.filter((event) => event.eventStatus !== -1);
    if (showFinished && reverseFinished)
      events = events.sort((a, b) => b.eventStatus - a.eventStatus);

    if (events.length)
      return (
        <div className="festival-list">
          {events
            ? events.map((event) => {
                if (event.published)
                  return (
                    <FestivalEventBlock
                      key={event.id}
                      userRole={this.props.user.role || undefined}
                      loading={this.props.paymentStatus.loading}
                      event={event}
                      shortDate={shortDates}
                    />
                  );
                return null;
              })
            : null}
          <div className="festival-card gap"></div>
          <div className="festival-card gap"></div>
          <div className="festival-card gap"></div>
        </div>
      );
    else return <h2>{t("dashboard.new_events_soon")}</h2>;
  }
}

const mapStateToProps = (state) => {
  return {
    paymentStatus: state.payments,
    user: state.auth.user || {},
  };
};

export default connect(mapStateToProps)(withTranslation()(FestivalEvents));
