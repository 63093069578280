import axios from "axios";
import { vodApiUrl } from "../theme-variables";

export default axios.create({
  baseURL: vodApiUrl,
});

export const tokenConfig = (getState) => {
  const token = getState().auth.token;
  const config = {};
  config.headers = {};
  config.headers["Content-type"] = "application/json";
  if (token) config.headers["x-auth-token"] = token;
  return config;
};

export const baseUrl = vodApiUrl;
