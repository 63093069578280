import React from "react";
import { Field, reduxForm } from "redux-form";
import { Tooltip, Button } from "antd";
import Input from "../../../../form-components/Input";
import Checkbox from "../../../../form-components/Checkbox";
import { InfoCircleOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";

class GroupTicketConfig extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          name="event_available"
          component={Checkbox}
          type="checkbox"
          label="Włącz sprzedaż biletów grupowych na wydarzeniach (repertuar)"
          size="big"
        />
        <Field
          name="video_available"
          component={Checkbox}
          type="checkbox"
          label="Włącz sprzedaż biletów grupowych na dostęp do wideo (sekcja VOD)"
          size="big"
        />
        <Field
          name="one_free"
          component={Checkbox}
          type="checkbox"
          label="Darmowy kod dla zamawiającego"
          size="big"
          suffix={
            <Tooltip title="Zaznacz jeżeli jeden z kodów ma być darmowy. Cena całkowita zostanie obniżona o koszt pojedynczego biletu.">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
        <Field
          name="min_units"
          component={Input}
          label="Minimalna liczba biletów przy zakupie"
          placeholder="np. 10"
          suffix={" sztuk"}
          type="number"
          step="1"
          style={{ display: "block", maxWidth: 300 }}
        />
        <Field
          name="max_units"
          component={Input}
          label="Maksymalna liczba biletów przy zakupie"
          placeholder="np. 10"
          suffix={" sztuk"}
          type="number"
          step="1"
          style={{ display: "block", maxWidth: 300 }}
        />
        <Field
          name="discount"
          component={Input}
          label="Zniżka przy zakupie grupowym"
          placeholder="np. 10"
          type="number"
          step="1"
          style={{ display: "block", maxWidth: 300 }}
          suffix={
            <>
              <Tooltip title="Całkowity koszt zamówienia zostanie obniżona o podany procent">
                %
                <InfoCircleOutlined
                  style={{ color: "rgba(0,0,0,.45)", marginLeft: 5 }}
                />
              </Tooltip>
            </>
          }
        />
        <Button type="primary" htmlType="submit">
          Zapisz
        </Button>
      </form>
    );
  }
}

const validate = ({ min_units, max_units, discount }) => {
  const errors = {};
  if (!min_units) errors.min_units = "Proszę podać minimalną liczbę biletów.";
  if (!max_units) errors.max_units = "Proszę podać maksymalną liczbę biletów.";
  if (min_units && isNaN(min_units))
    errors.min_units = "Nieprawidłowa wartość.";
  if (max_units && isNaN(max_units))
    errors.max_units = "Nieprawidłowa wartość.";
  if (max_units && max_units < min_units)
    errors.max_units = "Nieprawidłowa wartość.";

  if (!discount && !isNaN(discount) && (discount < 0 || discount >= 100))
    errors.discount = "Proszę podać wartość z zakresu 0-99";
  return errors;
};

export default reduxForm({
  form: "groupTicketConfigForm",
  enableReinitialize: true,
  validate,
})(withTranslation()(GroupTicketConfig));
