import React from "react";
import qs from "query-string";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Alert, Result, Skeleton } from "antd";
import {
  resendVerification,
  verifyEmail,
} from "../../../actions/authActions";
import { getConfig } from "../../../actions/appConfigActions";
import Wrapper from "../../wrappers/Wrapper";
import SingleButton from "../../form-components/SingleButton";
import { withTranslation } from "react-i18next";

class SignUpVerify extends React.Component {
  componentDidMount() {
    const { email, token } = qs.parse(this.props.location.search);
    this.email = email;
    this.props.verifyEmail({ email, token });
    this.props.getConfig(false);
  }
  resendEmail = () => {
    this.props.resendVerification({ email: this.email });
  };
  render() {
    const { t } = this.props;
    const { success, resend, type } = this.props.verification;
    const {
      loading,
      resendSuccess,
      resendStatus,
    } = this.props.verificationResend;
    if (!loading && success)
      return (
        <Wrapper
          title={t("verify.title")}
          bg={this.props.appConfig.noauth_bg_image}
          authLayout={true}
        >
          <Result
            status="success"
            title={t("verify.success")}
            extra={[
              <Link to="/logowanie">
                <Button type="primary">{t("signin.title")}</Button>
              </Link>,
            ]}
          />
        </Wrapper>
      );
    else if (!loading)
      return (
        <Wrapper
          title={t("verify.title")}
          bg={this.props.appConfig.noauth_bg_image}
          authLayout={true}
        >
          <Result status="error" title={t(type)} />
          <br />
          <div style={{ textAlign: "center" }}>
            {resend && !resendStatus ? (
              <SingleButton
                type="primary"
                onClick={this.resendEmail}
                loading={loading}
                className="primary"
              >
                {t("verify.resend")}
              </SingleButton>
            ) : null}
            <br />
            {resendSuccess ? (
              <Alert
                type="success"
                message={t("verify.resend_success")}
                showIcon
              />
            ) : null}
            <br />
            <Link to="/logowanie">
              <Button type="default">{t("signin.title")}</Button>
            </Link>
          </div>
        </Wrapper>
      );
    else {
      return (
        <Wrapper authLayout={true} title={t("verify.title")}>
          <Skeleton />
        </Wrapper>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    verificationResend: state.auth.verificationResend,
    verification: state.auth.verification,
    appConfig: state.appConfig.config,
  };
};

export default connect(mapStateToProps, {
  verifyEmail,
  resendVerification,
  getConfig,
})(withTranslation()(SignUpVerify));
