import {
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  CATEGORIES_LOADING,
  FORM_CATEGORIES_FAILURE,
} from "./_types";
import vod, { tokenConfig } from "../apis/vod";
import handleErrors from "./handleErrors";

export const createCategory = (props) => (dispatch, getState) => {
  dispatch(setCategoriesLoading());
  vod
    .post(`/api/categories`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_CATEGORY_SUCCESS,
        payload: res.data.category,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        ADD_CATEGORY_FAILURE,
        FORM_CATEGORIES_FAILURE
      );
    });
};

export const getCategories = (props = {}) => (dispatch, getState) => {
  dispatch(setCategoriesLoading());
  vod
    .get(`/api/categories`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: res.data.categories,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_CATEGORIES_FAILURE);
    });
};

export const deleteCategory = (id) => (dispatch, getState) => {
  dispatch(setCategoriesLoading());
  vod
    .delete(`/api/categories/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: id });
    })
    .catch((err) => {
      handleErrors(err, dispatch, DELETE_CATEGORY_FAILURE);
    });
};

export const updateCategory = (props, id) => (dispatch, getState) => {
  dispatch(setCategoriesLoading());
  vod
    .put(`/api/categories/${id}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_CATEGORY_SUCCESS,
        payload: res.data.category,
      });
    })
    .catch((err) => {
      handleErrors(
        err,
        dispatch,
        UPDATE_CATEGORY_FAILURE,
        FORM_CATEGORIES_FAILURE
      );
    });
};
export const setCategoriesLoading = () => {
  return {
    type: CATEGORIES_LOADING,
  };
};
