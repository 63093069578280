import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  CATEGORIES_LOADING,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
  ADD_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_SUCCESS,
} from "../actions/_types";

const initialState = {
  categories: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        categories: action.payload,
      };
    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        categories: state.categories.filter(
          (category) => category.id !== action.payload
        ),
      };
    case DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        categories: [action.payload, ...state.categories],
      };
    case ADD_CATEGORY_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        categories: state.categories.map((category) => {
          if (category.id === action.payload.id)
            return action.payload;
          return category;
        }),
      };
    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };
    case CATEGORIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
