import React from "react";
import { Link } from "react-router-dom";

import "./AdminMenu.scss";
import data from "./adminMenuItems.js";

class AdminMenu extends React.Component {
  state = {};
  handleClick = (key) => {
    this.props.handleChange(key);
  };
  render() {
    return (
      <ul className="admin-menu">
        {data
          .filter((i) => !i.hide)
          .map((i) => (
            <li
              className={this.props.active === i.key ? "active" : ""}
              key={i.key}
              onClick={() => this.handleClick(i.key)}
            >
              <Link to={`?view=${i.key}`}>
                <img src={i.icon} alt={`Ikona ${i.label}`} />
                {i.label}
              </Link>
            </li>
          ))}
      </ul>
    );
  }
}

export default AdminMenu;
