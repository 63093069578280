import React from "react";
import { Link } from "react-router-dom";
import { Popconfirm, Table, Skeleton } from "antd";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { getDatetimeWithTz } from "../../../../../helpers/productUtilities";
import moment from "moment-timezone";

class BansList extends React.Component {
  render() {
    const { bans, loading } = this.props.bans;
    const dataSource = bans.map((ban) => {
      ban.key = ban.id;
      return ban;
    });
    if (!loading)
      return (
        <Table dataSource={dataSource} size="small" pagination={false}>
          <Column
            title="Id"
            dataIndex="id"
            key="id"
            sorter={(a, b) => a.id - b.id}
          />
          <Column
            title="Imię i nazwisko"
            dataIndex="name"
            key="name"
            render={(v, r) => {
              return (
                <span>
                  {r.firstname} {r.lastname}
                </span>
              );
            }}
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
            sorter={(a, b) => a.email.localeCompare(b.email)}
          />
          <Column
            title="Kraj"
            dataIndex="country_code"
            key="country_code"
            sorter={(a, b) => a.country_code.localeCompare(b.country_code)}
            render={(v, r) => {
              return (
                <>
                  {r.country_code || "PL"}
                  <img
                    alt={`Flaga ${r.country_code}`}
                    height="17"
                    style={{ margin: "0 5px" }}
                    src={`https://cdn.ipregistry.co/flags/twemoji/${(
                      r.country_code || "PL"
                    ).toLowerCase()}.svg`}
                  />
                </>
              );
            }}
          />
          <Column title="Powód blokady" dataIndex="reason" key="reason" />
          <Column
            title="Data wygaśnięcia"
            dataIndex="expiration_date"
            key="expiration_date"
            sorter={(a, b) =>
              moment(a.expiration_date).unix() -
              moment(b.expiration_date).unix()
            }
            render={(v, r) => {
              if (r.forever) return "na stałe";
              else if (v)
                return getDatetimeWithTz(v).format("H:mm, DD.MM.YYYY");
            }}
          />
          <Column
            title="Akcje"
            key="action"
            render={(v, r) => (
              <>
                <Link to={`/konto/${r.user_id}`} style={{ color: "black" }}>
                  <EyeOutlined key="see" />
                </Link>
                <Popconfirm
                  title={`Czy na pewno chcesz usunąć blokadę użytkownikowi: ${r.firstname} ${r.lastname}?`}
                  onConfirm={() => this.props.deleteBan(r.id)}
                  okText="Tak"
                  cancelText="Nie"
                >
                  <DeleteOutlined key="delete" />
                </Popconfirm>
              </>
            )}
          />
        </Table>
      );
    else {
      return <Skeleton />;
    }
  }
}

export default BansList;
