import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  Skeleton,
  Card,
  Checkbox,
  Button,
  Popconfirm,
  message,
  Form,
  Input,
  Tooltip,
} from "antd";
import vod from "../../../../apis/vod";
import { getConfig } from "../../../../actions/appConfigActions";
import { updateConfig } from "../../../../helpers/updateConfig";
import LineBreak from "../../../LineBreak";
import "../managers.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { funcNewContentEmail } from "../../../../theme-variables";

class NotificationsManager extends React.Component {
  state = {
    notify_remindplatform: this.props.config.notify_remindplatform,
    notify_remindpayedevent: this.props.config.notify_remindpayedevent,
    notify_newcontent_date: this.props.config.notify_newcontent_date,
    error_bar_changed: false,
    info_bar_changed: false,
    info_bar: this.props.config.info_bar,
    error_bar: this.props.config.error_bar,
  };
  toggleConfig = (e, field) => {
    let newData = {};
    newData[field] = e.target.checked ? 1 : 0;
    updateConfig(newData).then(() => {
      let newState = {};
      newState[field] = e.target.checked;
      this.setState(newState);
    });
  };
  updateBars = () => {
    const {
      info_bar,
      error_bar,
      info_bar_changed,
      error_bar_changed,
    } = this.state;
    updateConfig({
      info_bar: info_bar_changed ? info_bar : undefined,
      error_bar: error_bar_changed ? error_bar : undefined,
    }).then(() => {
      this.setState({ info_bar_changed: false, error_bar_changed: false });
    });
  };
  sendNotificationAboutNewContent = (allUsers) => {
    vod
      .post(
        `/api/notifications/newcontent`,
        { allUsers },
        {
          headers: {
            "x-auth-token": this.props.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) message.success("Wysłano");
      });
  };
  componentDidMount() {
    this.props.getConfig();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.config !== this.props.config) {
      this.setState({
        notify_remindplatform: this.props.config.notify_remindplatform,
        notify_remindpayedevent: this.props.config.notify_remindpayedevent,
        notify_newcontent_date: this.props.config.notify_newcontent_date,
        info_bar: this.props.config.info_bar,
        error_bar: this.props.config.error_bar,
      });
    }
  }

  render() {
    const {
      notify_remindpayedevent,
      notify_remindplatform,
      notify_newcontent_date,
      info_bar,
      error_bar,
      error_bar_changed,
      info_bar_changed,
    } = this.state;
    if (!this.props.loading)
      return (
        <>
          <Card style={{ marginBottom: "30px" }} title="Powiadomienia">
            <label htmlFor="userNotification">
              <Checkbox
                style={{ marginRight: "5px" }}
                id="userNotification"
                type="checkbox"
                checked={notify_remindplatform}
                name="userNotification"
                onChange={(e) => this.toggleConfig(e, "notify_remindplatform")}
              />
              Powiadomienia o długiej nieobecności użytkowników
            </label>
            <br />
            <br />
            <label htmlFor="upcomingEventNotification">
              <Checkbox
                style={{ marginRight: "5px" }}
                id="upcomingEventNotification"
                type="checkbox"
                checked={notify_remindpayedevent}
                name="upcomingEventNotification"
                onChange={(e) =>
                  this.toggleConfig(e, "notify_remindpayedevent")
                }
              />
              Powiadomienia o nadchodzących, opłaconych wydarzeniach
            </label>
            {funcNewContentEmail ? (
              <>
                <LineBreak style={{ margin: "30px 0" }} />
                <h3>Wyślij wiadomość o nowych treściach do użytkowników</h3>
                <h4>
                  Ostatnio wysłano:{" "}
                  {notify_newcontent_date
                    ? moment(notify_newcontent_date).format("DD.MM.YYYY H:mm")
                    : null}
                </h4>
                <Popconfirm
                  title="Czy uwzględnić użytkowników niezapisanych do mailingu?"
                  onConfirm={() => this.sendNotificationAboutNewContent(true)}
                  onCancel={() => this.sendNotificationAboutNewContent(false)}
                  okText="Tak"
                  cancelText="Nie"
                >
                  <Button type="primary">Wyślij</Button>
                </Popconfirm>
              </>
            ) : null}
          </Card>

          <Card style={{ marginBottom: "30px" }} title="Pasek informacyjny">
            <Form>
              <Form.Item label="Pasek z informacją o błędzie:">
                <Input.TextArea
                  name="error_bar"
                  value={error_bar}
                  type="text"
                  placeholder="Tekst / kod html..."
                  suffix={
                    <Tooltip title="Pasek wyświetli się na dole ekranu. Aby nie wyświetlać paska, proszę nie podawać treści.">
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                  onChange={(e) =>
                    this.setState({
                      error_bar: e.target.value,
                      error_bar_changed: true,
                    })
                  }
                />
              </Form.Item>
              <Form.Item label="Pasek z dodatkową informacją:">
                <Input.TextArea
                  name="info_bar"
                  value={info_bar}
                  type="text"
                  placeholder="Tekst / kod html..."
                  suffix={
                    <Tooltip title="Pasek wyświetli się na dole ekranu. Aby nie wyświetlać paska, proszę nie podawać treści.">
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                  onChange={(e) =>
                    this.setState({
                      info_bar: e.target.value,
                      info_bar_changed: true,
                    })
                  }
                />
              </Form.Item>

              <Button
                type="primary"
                onClick={() => this.updateBars()}
                disabled={!(info_bar_changed || error_bar_changed)}
              >
                Zapisz
              </Button>
            </Form>
          </Card>
        </>
      );
    else return <Skeleton />;
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.appConfig.config,
    loading: state.appConfig.loading,
    token: state.auth.token,
  };
};

export default connect(mapStateToProps, { getConfig })(NotificationsManager);
