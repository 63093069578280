import React from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Divider,
  message,
  Modal,
  Popconfirm,
  Table,
} from "antd";
import _ from "lodash";
import { getVideos as getVimeoVideos } from "../../../../../actions/vimeoActions";
import vod from "../../../../../apis/vod";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import VideoTranslationForm from "../forms/VideoTranslationForm";
import { updateSyncErrors } from "redux-form";
import Column from "antd/lib/table/Column";

class VideosTranslationsModal extends React.Component {
  state = {
    mode: "none",
    chosenTranslation: null,
    translations: [],
    initialValues: { video_id: this.props.video.id },
  };
  handleAdd = (formValues) => {
    vod
      .post(`/api/translations/video`, formValues, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          mode: "none",
          translations: [
            ...this.state.translations,
            res.data.translation,
          ],
          initialValues: { video_id: this.props.video.id },
        });
      })
      .catch((err) => {
        const error = err.response.data;
        if (error.formError)
          this.props.dispatch(
            updateSyncErrors("videoTranslationForm", error.formError)
          );
        message.error("Wystąpił błąd: " + error.message);
      });
  };
  handleEdit = (formValues) => {
    vod
      .put(
        `/api/translations/video/${this.state.chosenTranslation}`,
        formValues,
        {
          headers: {
            "x-auth-token": this.props.token,
          },
        }
      )
      .then((res) => {
        this.setState({
          mode: "none",
          translations: this.state.translations.map((t) => {
            if (t.id === this.state.chosenTranslation)
              return res.data.translation;
            return t;
          }),
          initialValues: { video_id: this.props.video.id },
        });
      })
      .catch((err) => {
        const error = err.response.data;
        if (error.formError)
          this.props.dispatch(
            updateSyncErrors("videoTranslationForm", error.formError)
          );
        message.error("Wystąpił błąd: " + error.message);
      });
  };
  deleteTranslationClick = (translationId) => {
    vod
      .delete(`/api/translations/video/${translationId}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          translations: this.state.translations.filter(
            (t) => t.id !== translationId
          ),
          mode: "none",
          initialValues: { video_id: this.props.video.id },
        });
      })
      .catch((err) => message.error("Wystąpił błąd: " + err));
  };
  editTranslationClick = (translationId) => {
    vod
      .get(`/api/translations/video/${translationId}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        let initialValues = _.pick(
          res.data.translation,
          "title",
          "video_link",
          "description",
          "additional_desc",
          "accessibility_video",
          "autodesc_video",
          "teaser_link",
          "video_id",
          "language_code"
        );
        if (initialValues.description)
          initialValues.description = initialValues.description.replace(
            /<br \/>/gi,
            "\n"
          );
        if (initialValues.additional_desc)
          initialValues.additional_desc = initialValues.additional_desc.replace(
            /<br \/>/gi,
            "\n"
          );
        this.setState({
          initialValues,
          mode: "edit",
          chosenTranslation: translationId,
          video_id: this.props.video.id,
        });
      })
      .catch((err) =>
        message.error("Wystąpił błąd przy pobieraniu tłumaczenia")
      );
  };
  fetchTranslations = (videoId) => {
    vod
      .get(`/api/videos/${videoId}/translations`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          translations: res.data.translations.map((t) => {
            t.key = t.id;
            return t;
          }),
        });
      })
      .catch((err) => {
        message.error("Wystąpił błąd:" + err);
      });
  };
  componentDidMount() {
    this.props.getVimeoVideos();
    this.fetchTranslations(this.props.video.id);
  }
  render() {
    const { video, visible, closeModal } = this.props;
    return (
      <Modal
        title={`${video.title} - zarządzaj tłumaczeniami`}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        style={{ top: 30 }}
      >
        <Button
          type="primary"
          onClick={() =>
            this.setState({
              mode: "add",
              initialValues: { video_id: this.props.video.id },
            })
          }
          style={{ marginBottom: 30 }}
          disabled={this.state.mode !== "none"}
        >
          Dodaj tłumaczenie
        </Button>
        {this.state.translations.length > 0 ? (
          <Table
            size="small"
            dataSource={this.state.translations.map((t) => {
              t.key = t.id;
              return t;
            })}
            pagination={false}
          >
            <Column
              title="Język"
              dataIndex="language_code"
              key="name"
            />
            <Column
              title="Operacje"
              key="action"
              render={(v, r) => (
                <>
                  <EditOutlined
                    key="edit"
                    onClick={() => this.editTranslationClick(r.id)}
                    style={{ fontSize: 20, margin: "0 10px" }}
                  />
                  <Popconfirm
                    title={`Czy na pewno chcesz usunąć tłumaczenie: ${r.language_code}?`}
                    onConfirm={() =>
                      this.deleteTranslationClick(r.id)
                    }
                    okText="Tak"
                    cancelText="Nie"
                  >
                    <DeleteOutlined
                      key="delete"
                      style={{ fontSize: 20, margin: "0 10px" }}
                    />
                  </Popconfirm>
                </>
              )}
            />
          </Table>
        ) : (
          <Alert message="Brak tłumaczeń" type="info" />
        )}
        {this.state.mode === "edit" || this.state.mode === "add" ? (
          <>
            <Divider />
            <VideoTranslationForm
              videoId={video.id}
              vimeoVideos={this.props.vimeoVideos}
              mode={this.state.mode}
              initialValues={this.state.initialValues}
              onSubmit={
                this.state.mode === "add"
                  ? this.handleAdd
                  : this.handleEdit
              }
            />
          </>
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vimeoVideos: state.vimeo.videos,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVimeoVideos,
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideosTranslationsModal);
