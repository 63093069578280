import vod, { tokenConfig } from "../apis/vod";
import {
  BUY_TICKET_LOADING,
  BUY_TICKET_SUCCESS,
  BUY_TICKET_FAILURE,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  ASSIGN_ACCESS_FAILURE,
  ASSIGN_ACCESS_SUCCESS,
  CLEAR_EVENT_PAYMENT_ERROR,
} from "./_types";
import handleErrors from "./handleErrors";
import history from "../history";
import ReactPixel from "react-facebook-pixel";
import { facebookPixel, googleTagManager } from "../theme-variables";
import TagManager from "react-gtm-module";

export const buyTicket = (eventId, props) => (dispatch, getState) => {
  dispatch({
    type: BUY_TICKET_LOADING,
    payload: eventId,
  });
  vod
    .post(`/api/payments/${eventId}`, props, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: BUY_TICKET_SUCCESS });
      if (facebookPixel && res.data.payu && res.data.order) {
        const { order } = res.data;
        ReactPixel.track("InitiateCheckout", {
          content_ids: [`event-${order.product_id}`],
          content_category: order.type,
          contents: order.contents,
          content_type: "product",
          currency: "PLN",
          value: parseFloat(order.value),
        });
      }
      if (googleTagManager && res.data.payu && res.data.order) {
        const { order } = res.data;
        TagManager.dataLayer({ ecommerce: null });
        TagManager.dataLayer({
          event: "checkout",
          ecommerce: {
            checkout: {
              actionField: {
                step: 1,
                option: parseFloat(order.value) == 0 ? "free" : "payu",
              },
              products: [
                {
                  name: order.title,
                  id: order.product_id,
                  price: order.value,
                  category: order.type,
                  quantity: order.contents.productQuantity,
                },
              ],
            },
          },
          eventCallback: function () {
            document.location = res.data.payu.redirectUri;
          },
        });
      }
      if (res.data.payu) window.location.href = res.data.payu.redirectUri;
      else history.push(`/wydarzenie/${eventId}`);
    })
    .catch((err) => {
      handleErrors(err, dispatch, BUY_TICKET_FAILURE);
    });
};

export const getOrder = (extOrderId) => (dispatch, getState) => {
  vod
    .get(`/api/payments/${extOrderId}`, {
      headers: {
        "x-auth-token": getState().auth.token,
      },
    })
    .then((res) => {
      dispatch({ type: GET_ORDER_SUCCESS, payload: res.data.order });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_ORDER_FAILURE);
    });
};

export const getUserOrders = () => (dispatch, getState) => {
  vod
    .get(`/api/payments/user`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_ORDERS_FAILURE);
    });
};

export const getOrders = (props) => (dispatch, getState) => {
  vod
    .get(`/api/payments/`, {
      params: props,
      ...tokenConfig(getState),
    })
    .then((res) => {
      dispatch({
        type: GET_ORDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      handleErrors(err, dispatch, GET_ORDERS_FAILURE);
    });
};

export const assignFreeAccess = (eventId, userId) => (dispatch, getState) => {
  vod
    .post(
      `/api/payments/free/${eventId}/${userId}`,
      null,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: ASSIGN_ACCESS_SUCCESS });
    })
    .catch((err) => {
      handleErrors(err, dispatch, ASSIGN_ACCESS_FAILURE);
    });
};

export const clearPaymentError = () => (dispatch) => {
  dispatch({ type: CLEAR_EVENT_PAYMENT_ERROR });
};
