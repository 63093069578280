import React from "react";
import { connect } from "react-redux";
import { Skeleton, message } from "antd";
import { getOrders as getEventOrders } from "../../../../actions/paymentsActions";
import { getOrders as getVideoOrders } from "../../../../actions/videoPaymentsActions";
import { getVideos } from "../../../../actions/videosActions";
import { getEvents } from "../../../../actions/eventsActions";
import PaymentList from "./lists/PaymentList";
import "../managers.scss";

class PaymentsManager extends React.Component {
  state = { visible: false, mode: "add", chosenEvent: null };
  componentDidMount() {
    this.props.getVideoOrders();
    this.props.getEventOrders();
    this.props.getEvents({ date_from: "2000-01-01" });
    this.props.getVideos();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.error) message.error(nextProps.error);
    return null;
  }
  handleSearch = (props) => {
    this.props.getVideoOrders(props);
    this.props.getEventOrders(props);
  };
  render() {
    const {
      orders,
      videoOrders,
      loading,
      eventRefunds,
      videoRefunds,
      videos,
      events,
    } = this.props;

    if (!loading)
      return (
        <>
          <PaymentList
            orders={orders}
            videoOrders={videoOrders}
            eventRefunds={eventRefunds}
            videoRefunds={videoRefunds}
            onSubmit={this.handleSearch}
            videos={videos}
            events={events}
          />
        </>
      );
    else return <Skeleton />;
  }
}

const mapStateToProsp = (state) => {
  return {
    eventSuccess: state.payments.success,
    eventError: state.payments.error,
    orders: state.payments.orders,
    loading: state.videos.loading,
    videoOrders: state.videoPayments.orders,
    eventRefunds: state.payments.refunds,
    videoRefunds: state.videoPayments.refunds,
    videos: state.videos.videos,
    events: state.events.events,
  };
};

export default connect(mapStateToProsp, {
  getEventOrders,
  getVideoOrders,
  getVideos,
  getEvents,
})(PaymentsManager);
