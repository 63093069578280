import React from "react";
import { Card, Form, Input, Button, Skeleton, Checkbox } from "antd";
import { connect } from "react-redux";
import { getConfig } from "../../../../actions/appConfigActions";
import { updateConfig } from "../../../../helpers/updateConfig";
import showErrorMesssage from "../../../../helpers/showErrorMesssage";
import "../managers.scss";

class FestivalSettings extends React.Component {
  state = {
    festival_title_en_changed: false,
    festival_title_changed: false,
    festival_title: this.props.config.festival_title,
    festival_title_en: this.props.config.festival_title_en,
  };
  changeConfig = (data) => {
    return updateConfig(data).then(() => {
      this.props.getConfig();
    });
  };
  toggleConfig = (e, field) => {
    let newData = {};
    newData[field] = e.target.checked ? 1 : 0;
    this.changeConfig(newData);
  };
  onInputChange = (e, inputName) => {
    let newState = {};
    newState[inputName] = e.target.value;
    newState[`${inputName}_changed`] = true;
    this.setState(newState);
  };
  componentDidMount() {
    this.props.getConfig();
  }
  changeFestivalTitle = () => {
    this.changeConfig({
      festival_title: this.state.festival_title,
    })
      .then(() => {
        this.setState({ festival_title_changed: false });
      })
      .catch((err) => {
        showErrorMesssage(err, "festival_title");
      });
  };
  changeFestivalEnTitle = () => {
    this.changeConfig({
      festival_title_en: this.state.festival_title_en,
    })
      .then(() => {
        this.setState({ festival_title_en_changed: false });
      })
      .catch((err) => {
        showErrorMesssage(err, "festival_title_en");
      });
  };
  render() {
    const {
      view_festival,
      festival_short_dates,
      festival_show_finished,
      festival_reverse_finished,
      loading,
    } = this.props.config;
    const {
      festival_title_en_changed,
      festival_title_changed,
      festival_title,
      festival_title_en,
    } = this.state;
    if (!loading)
      return (
        <Card
          style={{ marginBottom: "30px" }}
          title="Zarządzaj ustawieniami festiwalu"
        >
          <p style={{ maxWidth: 500 }}>
            Aby wybrać wydarzenia dotyczące festiwalu, należy przejść do
            zakładki <b>Repertuar</b>&nbsp;i zaznaczyć opcję{" "}
            <b>"Wydarzenie festiwalowe"</b> w formularzu edycji wydarzenia.
            Wydarzenia oznaczone jako festiwalowe nie będą wyświetlane
            w&nbsp;standardowym widoku repertuaru.
          </p>
          <Form layout="inline">
            <Form.Item>
              <Input
                name="festival_title"
                value={festival_title}
                placeholder="Tytuł festiwalu"
                type="text"
                style={{ width: 170, marginRight: 15 }}
                onChange={(e) => this.onInputChange(e, "festival_title")}
              />
              <Button
                type="primary"
                onClick={() => this.changeFestivalTitle()}
                disabled={!festival_title_changed}
              >
                Zapisz
              </Button>
            </Form.Item>
          </Form>
          <Form layout="inline">
            <Form.Item>
              <Input
                name="festival_title_en"
                value={festival_title_en}
                placeholder="Tytuł festiwalu (en)"
                type="text"
                style={{ width: 170, marginRight: 15 }}
                onChange={(e) => this.onInputChange(e, "festival_title_en")}
              />
              <Button
                type="primary"
                onClick={() => this.changeFestivalEnTitle()}
                disabled={!festival_title_en_changed}
              >
                Zapisz
              </Button>
            </Form.Item>
          </Form>

          <br />
          <br />
          <label htmlFor="viewFestival">
            <Checkbox
              style={{ marginRight: "5px" }}
              id="viewFestival"
              checked={view_festival}
              type="checkbox"
              name="viewFestival"
              onChange={(e) => this.toggleConfig(e, "view_festival")}
            />
            Pokaż blok "Festiwal" na stronie głównej
          </label>
          <br />
          <br />
          <label htmlFor="festivalShortDates">
            <Checkbox
              style={{ marginRight: "5px" }}
              id="festivalShortDates"
              checked={festival_short_dates}
              type="checkbox"
              name="festivalShortDates"
              onChange={(e) => this.toggleConfig(e, "festival_short_dates")}
            />
            Nie pokazuj daty zakończenia na bloku wydarzenia
          </label>
          <br />
          <br />
          <label htmlFor="festivalShowFinished">
            <Checkbox
              style={{ marginRight: "5px" }}
              id="festivalShowFinished"
              checked={festival_show_finished}
              type="checkbox"
              name="festivalShowFinished"
              onChange={(e) => this.toggleConfig(e, "festival_show_finished")}
            />
            Pokazuj zakończone wydarzenia w ramach festiwalu
          </label>
          <br />
          <br />
          <label htmlFor="festivalReverseFinished">
            <Checkbox
              style={{ marginRight: "5px" }}
              id="festivalReverseFinished"
              checked={festival_reverse_finished}
              type="checkbox"
              name="festivalReverseFinished"
              onChange={(e) =>
                this.toggleConfig(e, "festival_reverse_finished")
              }
            />
            Pokazuj zakończone wydarzenia na końcu listy
          </label>
          <br />
          <br />
        </Card>
      );
    else return <Skeleton />;
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    configLoading: state.appConfig.loading,
    config: state.appConfig.config,
  };
};

export default connect(mapStateToProps, { getConfig })(FestivalSettings);
