import React from "react";
import { Form, Button as AButton } from "antd";

const Button = ({ children, type, htmlType, loading, ...rest }) => {
  return (
    <Form.Item className="teapp-button-wrapper" {...rest}>
      <AButton
        block
        className="teapp-button"
        type={type}
        htmlType={htmlType}
        loading={loading}
      >
        {children}
      </AButton>
    </Form.Item>
  );
};

export default Button;
