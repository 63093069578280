import React from "react";
import { connect } from "react-redux";
import { Skeleton, Card } from "antd";
import { getConfig } from "../../../../actions/appConfigActions";
import {
  updateConfig,
  deleteImgInConfig,
} from "../../../../helpers/updateConfig";
import AdvertisementForm from "./forms/AdvertisementForm";
import LineBreak from "../../../LineBreak";
import "../managers.scss";

class AdvertisementSettings extends React.Component {
  state = {
    loading: true,
    show_advertisement: false,
    mobile_ad_image: null,
    tablet_ad_image: null,
    desktop_ad_image: null,
  };
  submitForm = (formData) => {
    updateConfig(formData).then((res) => {
      this.props.getConfig();
    });
  };
  componentDidMount = () => {
    this.props.getConfig();
  };
  render() {
    if (this.props.configLoading) return <Skeleton />;
    else {
      const initialValues = this.props.config;
      return (
        <Card
          style={{ marginBottom: "30px" }}
          title="Zarządzaj reklamami"
        >
          <p>
            Należy wgrać trzy formaty reklamy dla różnych
            rozdzielczości urządzeń. W przypadku formatu wspólnego dla
            wszystkich rozdzielczości, należy wgrać zdjęcie tylko w
            miejscu reklamy na smartfony. Wciśnięcie czerwonego "x"
            spowoduje usunięcie zdjęcia. Wszelkie inne zmiany
            (nadpisanie zdjęcia, ustawienie widoczności reklamy,
            wgranie nowego zdjęcia) należy zaakceptować, wciskając
            przycisk "Zaktualizuj". Dostępne formaty:
          </p>
          <ul>
            <li>
              <strong>Reklama wyświetlana na smartfonach</strong> - do
              575px
            </li>
            <li>
              <strong>Reklama wyświetlana na tabletach</strong> - od
              576px do 991px
            </li>
            <li>
              <strong>Reklama wyświetlana na komputerach</strong> - od
              992px
            </li>
          </ul>
          <LineBreak />
          <AdvertisementForm
            token={this.props.token}
            initialValues={initialValues}
            onSubmit={this.submitForm}
            deleteImgInConfig={deleteImgInConfig}
          />
        </Card>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.appConfig.config,
    configLoading: state.appConfig.loading,
  };
};

export default connect(mapStateToProps, { getConfig, updateConfig })(
  AdvertisementSettings
);
