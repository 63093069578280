import React from "react";
import { reduxForm, change } from "redux-form";
import { Alert, Button, Row, Col } from "antd";
import File from "../../../../form-components/FileInput";
import FileField from "../../../../form-components/FileField";
import createFormData from "../../../../../helpers/createFormData";
import {
  updateConfig,
  deleteImgInConfig,
} from "../../../../../helpers/updateConfig";

class NoAuthPageForm extends React.Component {
  state = {
    noauth_bg_image: this.props.initialValues.noauth_bg_image,
  };
  onSubmit = async (formValues) => {
    const formData = await createFormData(
      ["noauth_bg_image", "noauth_bg_img_data"],
      [
        {
          name: "noauth_bg_img_data",
          options: {
            maxSizeMB: 5,
            maxWidthOrHeight: 4000,
          },
        },
      ],
      formValues
    );

    this.updateBgImage(formData);
  };
  updateBgImage = (formData) => {
    updateConfig(formData);
  };
  setImageUrl = (url, fieldName) => {
    const newStateValue = {};
    newStateValue[fieldName] = url;
    this.setState(newStateValue);
    this.props.dispatch(
      change("noAuthPageForm", "noauth_bg_image", newStateValue)
    );
  };
  deleteImageFromServer = (urlField) => {
    deleteImgInConfig(urlField).then(() => {
      this.setState({ noauth_bg_image: "" });
      this.props.dispatch(
        change("noAuthPageForm", "noauth_bg_image", "")
      );
    });
  };
  render() {
    const error = this.props.error;
    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        style={{ marginBottom: "25px" }}
      >
        <Row>
          <Col>
            <FileField
              name="noauth_bg_img_data"
              urlField="noauth_bg_image"
              label="Obraz tła"
              component={File}
              setImageUrl={this.setImageUrl}
              deleteImageFromServer={this.deleteImageFromServer}
              currentImage={this.state.noauth_bg_image}
              maxWidth={4000}
              maxHeight={4000}
              maxWeight={5200}
            />

            {error ? (
              <Alert message={error} type="error" showIcon />
            ) : null}
          </Col>
          <Col>
            <br />
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 15 }}
            >
              Zaktualizuj
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  return errors;
};

export default reduxForm({
  form: "noAuthPageForm",
  enableReinitialize: true,
  validate,
})(NoAuthPageForm);
