import React from "react";
import { Card, Form, Input, Button, message } from "antd";
import showErrorMesssage from "../../../../../helpers/showErrorMesssage";
import "../../managers.scss";

class UrlsSettings extends React.Component {
  state = {
    institution_page_changed: false,
    institution_title_changed: false,
    regulations_page_changed: false,
    policy_page_changed: false,
    institution_page: this.props.config.institution_page,
    institution_page_title: this.props.config.institution_page_title,
    regulations_page: this.props.config.regulations_page,
    policy_page: this.props.config.policy_page,
  };
  onInputChange = (e, inputName) => {
    let newState = {};
    newState[inputName] = e.target.value;
    newState[`${inputName}_changed`] = true;
    this.setState(newState);
  };
  changePageUrl = (inputName) => {
    if (
      this.state[inputName] &&
      !/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/.test(
        this.state[inputName]
      ) &&
      !/^\/.*$/.test(this.state[inputName])
    ) {
      message.error("Niepoprawny odnośnik");
      return;
    }
    let configObj = {};
    configObj[inputName] = this.state[inputName];
    if (inputName === "institution_page")
      configObj["institution_page_title"] = this.state.institution_page_title;
    this.props
      .changeConfig(configObj)
      .then(() => {
        let newState = {};
        newState[`${inputName}_changed`] = false;
        this.setState(newState);
      })
      .catch((err) => {
        showErrorMesssage(err, inputName);
      });
  };
  render() {
    const {
      institution_page_changed,
      institution_page_title_changed,
      regulations_page_changed,
      policy_page_changed,
      institution_page,
      institution_page_title,
      regulations_page,
      policy_page,
    } = this.state;
    return (
      <Card style={{ marginBottom: "30px" }} title="Zarządzaj odnośnikami">
        <Form layout="inline">
          <Form.Item>
            <Input
              name="institution_page_title"
              value={institution_page_title}
              placeholder="Strona instytucji / Strona teatru"
              type="text"
              style={{ width: 170, marginRight: 15 }}
              onChange={(e) => this.onInputChange(e, "institution_page_title")}
            />
            <Input
              name="institution_page"
              value={institution_page}
              type="text"
              placeholder="Odnośnik do strony instytucji"
              style={{ width: 200, marginRight: 15 }}
              onChange={(e) => this.onInputChange(e, "institution_page")}
            />
            <Button
              type="primary"
              onClick={() =>
                this.changePageUrl("institution_page", "institution_changed")
              }
              disabled={
                !institution_page_changed && !institution_page_title_changed
              }
            >
              Zapisz
            </Button>
          </Form.Item>
        </Form>
        <br />
        <Form layout="inline">
          <Form.Item label="Strona polityki prywatności:">
            <Input
              name="policy_page"
              value={policy_page}
              type="text"
              placeholder="Odnośnik do polityki prywatności"
              style={{ width: 200, marginRight: 15 }}
              onChange={(e) => this.onInputChange(e, "policy_page")}
            />
            <Button
              type="primary"
              onClick={() =>
                this.changePageUrl("policy_page", "policy_changed")
              }
              disabled={!policy_page_changed}
            >
              Zapisz
            </Button>
          </Form.Item>
        </Form>
        <br />
        <Form layout="inline">
          <Form.Item label="Strona regulaminu serwisu:">
            <Input
              name="regulations_page"
              placeholder="Odnośnik do regulaminu"
              value={regulations_page}
              type="text"
              style={{ width: 200, marginRight: 15 }}
              onChange={(e) => this.onInputChange(e, "regulations_page")}
            />
            <Button
              type="primary"
              onClick={() =>
                this.changePageUrl("regulations_page", "regulations_changed")
              }
              disabled={!regulations_page_changed}
            >
              Zapisz
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default UrlsSettings;
