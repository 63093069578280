import {
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILURE,
  INVOICES_LOADING,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILURE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAILURE,
} from "../actions/_types";

const initialState = {
  invoices: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        invoices: action.payload,
      };
    case GET_INVOICES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_INVOICE_SUCCESS:
      let found = false;
      let invoices = state.invoices.map((invoice) => {
        if (invoice.id === action.payload.id) {
          found = true;
          return action.payload;
        }
        return invoice;
      });
      if (!found) invoices.push(action.payload);
      return {
        ...state,
        loading: false,
        error: undefined,
        invoices: invoices,
      };
    case GET_INVOICE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        invoices: state.invoices.filter(
          (invoice) => invoice.id !== action.payload
        ),
      };
    case DELETE_INVOICE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        invoices: state.invoices.map((invoice) => {
          if (invoice.id === action.payload.id) return action.payload;
          return invoice;
        }),
      };
    case UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case INVOICES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
