import React from "react";
import { connect } from "react-redux";
import { Skeleton, Card, Checkbox } from "antd";
import {
  getInvoices,
  deleteInvoice,
  updateInvoice,
} from "../../../../actions/invoicesActions";
import { getConfig } from "../../../../actions/appConfigActions";
import InvoiceList from "./lists/InvoiceList";
import "../managers.scss";
import { updateConfig } from "../../../../helpers/updateConfig";
import InvoiceSettings from "./forms/InvoiceSettings";

class InvoicesManager extends React.Component {
  state = {
    only_completed: true,
    not_sent: false,
  };
  changeConfig = (data) => {
    return updateConfig(data).then(() => {
      this.props.getConfig();
    });
  };
  componentDidMount() {
    this.props.getInvoices();
    this.props.getConfig();
  }
  deleteInvoiceClick = (id) => {
    this.props.deleteInvoice(id);
  };
  render() {
    const loading = this.props.loading;
    const { only_completed, not_sent } = this.state;
    const { invoices, config } = this.props;
    return (
      <>
        {!loading ? (
          <>
            <InvoiceSettings
              config={config}
              changeConfig={this.changeConfig}
              key="invoice-settings"
            />
            <Card
              key="invoice-list"
              title="Lista faktur"
              style={{ marginBottom: 30 }}
              className="invoice-list"
            >
              <Checkbox
                key="only_completed_invoices"
                checked={only_completed}
                onChange={(e) => {
                  this.setState({ only_completed: e.target.checked });
                }}
              >
                Pokaż faktury ze zrealizowaną płatnością
              </Checkbox>
              <br />
              <br />
              <Checkbox
                key="only_not_sent_invoices"
                checked={not_sent}
                onChange={(e) => {
                  this.setState({ not_sent: e.target.checked });
                }}
              >
                Pokaż tylko niewysłane faktury
              </Checkbox>
              <InvoiceList
                deleteInvoiceClick={this.deleteInvoiceClick}
                updateInvoice={this.props.updateInvoice}
                notSent={not_sent}
                onlyCompleted={only_completed}
                invoices={invoices}
              />
            </Card>
          </>
        ) : (
          <Skeleton />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices.invoices,
    loading: state.invoices.loading,
    config: state.appConfig.config,
  };
};

export default connect(mapStateToProps, {
  getInvoices,
  deleteInvoice,
  updateInvoice,
  getConfig,
})(InvoicesManager);
