import React from "react";
import vod from "../../../../apis/vod";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Input, message, Button, List } from "antd";
import { UserOutlined } from "@ant-design/icons";
import BansList from "./lists/BansList";
import LineBreak from "../../../LineBreak";
import { getBans, deleteBan } from "../../../../actions/bansActions";

class UsersManager extends React.Component {
  state = { search: "", users: [] };
  componentDidMount() {
    this.props.getBans();
  }
  onChange = (e) => {
    this.setState({ search: e.target.value });
  };
  onClick = () => {
    const props = { search: this.state.search };
    vod
      .get(`/api/users`, {
        params: props,
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({ ...this.state, users: res.data.users });
      })
      .catch((err) => {
        message.error("Wystąpił błąd");
      });
  };
  render() {
    return (
      <>
        <Card
          title="Zarządzaj użytkownikami"
          className="user-manager"
          style={{ marginBottom: "30px" }}
        >
          <Link to="/teapp-admin/uzytkownicy">
            <Button type="primary" size="small" style={{ marginBottom: 30 }}>
              Zobacz wszystkich użytkowników
            </Button>
          </Link>
          <br />
          <br />
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label htmlFor="userSearch">
              Podaj fragment imienia, nazwiska lub adresu email użytkownika
              <br />
              <br />
              <Input
                id="userSearch"
                type="text"
                name="usersearch"
                value={this.state.search}
                onChange={this.onChange}
                style={{
                  maxWidth: "200px",
                  marginRight: "15px",
                  marginBottom: "15px",
                }}
              />
            </label>
            <Button type="primary" htmlType="submit" onClick={this.onClick}>
              Szukaj
            </Button>
          </form>
          <List
            itemLayout="horizontal"
            dataSource={this.state.users}
            renderItem={(u) => (
              <List.Item>
                <UserOutlined />
                <List.Item.Meta
                  title={`${u.firstname} ${u.lastname} | Liczba zakończonych zamówień: ${u.paymentStatus}`}
                  description={`email: ${u.email}`}
                />
                <Link to={`/konto/${u.id}`} key={u.id}>
                  Zobacz
                </Link>
              </List.Item>
            )}
          />
        </Card>

        <Card
          title="Blokady użytkowników"
          className="user-manager"
          style={{ marginBottom: "30px" }}
        >
          <LineBreak style={{ margin: "25px 0" }} />
          <BansList bans={this.props.bans} deleteBan={this.props.deleteBan} />
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    bans: state.bans,
  };
};
export default connect(mapStateToProps, { getBans, deleteBan })(UsersManager);
