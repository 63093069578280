import {
  BUY_VIDEO_TICKET_LOADING,
  BUY_VIDEO_TICKET_SUCCESS,
  BUY_VIDEO_TICKET_FAILURE,
  GET_VIDEO_ORDER_SUCCESS,
  GET_VIDEO_ORDER_FAILURE,
  GET_VIDEO_ORDERS_FAILURE,
  GET_VIDEO_ORDERS_SUCCESS,
  ASSIGN_VIDEO_ACCESS_FAILURE,
  ASSIGN_VIDEO_ACCESS_SUCCESS,
  CLEAR_VIDEO_PAYMENT_ERROR,
} from "../actions/_types";

const initialState = {
  order: {},
  orders: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BUY_VIDEO_TICKET_LOADING:
      return {
        ...state,
        loading: true,
        videoId: action.payload,
      };
    case BUY_VIDEO_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        videoId: undefined,
      };
    case BUY_VIDEO_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        videoId: undefined,
      };
    case GET_VIDEO_ORDER_SUCCESS:
      return {
        ...state,
        success: true,
        order: action.payload,
      };
    case GET_VIDEO_ORDER_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case GET_VIDEO_ORDERS_SUCCESS:
      return {
        ...state,
        success: true,
        orders: action.payload.orders,
        refunds: action.payload.refunds,
      };
    case GET_VIDEO_ORDERS_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case ASSIGN_VIDEO_ACCESS_SUCCESS:
      return {
        ...state,
        success: true,
        orders: action.payload.order,
      };
    case ASSIGN_VIDEO_ACCESS_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case CLEAR_VIDEO_PAYMENT_ERROR:
      return {
        ...state,
        error: undefined,
      };
    default:
      return state;
  }
}
