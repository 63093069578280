import React, { Component } from "react";
import * as tus from "tus-js-client";
import { Field, reduxForm } from "redux-form";
import { Button, Progress, message, Alert } from "antd";
import vod from "../../../../../apis/vod";
import Input from "../../../../form-components/Input";
import { maxVideoUploadWeight } from "../../../../../theme-variables";

const validateVideoWeight = (file) => {
  if (file && file.size) {
    // Get image size in kilobytes
    const fileKb = file.size / 1048576;
    const maxWeight = maxVideoUploadWeight * 1000;
    if (fileKb > maxWeight) {
      return `Zbyt duży rozmiar pliku. Maksymalny rozmiar pliku to ${maxVideoUploadWeight}GB.`;
    }
  }
};
const validateVideoFormat = (file) => {
  if (file) {
    const mimeType =
      "video/mp4, video/webm, video/ogg, video/quicktime, video/x-msvideo, video/x-flv, video/x-ms-wmv";
    if (!mimeType.includes(file.type)) {
      return `Złe rozszerzenie pliku. Dozwolony mp4, mov, wmv, avi, flv.`;
    }
  }
};
class VimeoForm extends Component {
  state = { percent: 0, uploadState: "", loading: false };
  handleFormSubmit = (formValues) => {
    if (!formValues.video_file) return;
    const file = formValues.video_file;
    const token = this.props.token;
    const setPercent = (percent, uploadState = "active") => {
      this.setState({ percent, uploadState });
    };
    const onClose = () => {
      this.props.onClose();
    };
    setPercent(0);
    this.setState({ loading: true });
    vod
      .post(
        `/api/vimeo`,
        {
          name: formValues.name,
          size: formValues.video_file.size,
        },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        var upload = new tus.Upload(file, {
          uploadUrl: res.data.video.upload_link,
          endpoint: res.data.video.upload_link,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          onError: function (error) {
            message.error(
              "Wystąpił błąd. Upewnij się, że wybrałeś/aś dobry plik."
            );
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setPercent(percentage);
          },
          onSuccess: async function () {
            try {
              await vod.patch(`/api/vimeo/${res.data.video.uri}`, null, {
                headers: {
                  "x-auth-token": token,
                },
              });
              message.success("Dodano wideo.");
              onClose();
            } catch (err) {
              message.error(
                "Wystąpił błąd. Prosimy o kontakt z administratorem."
              );
            }
          },
        });
        upload.start();
      })
      .catch((err) => {
        message.error("Wystąpił błąd: " + err.response.data.message);
      });
  };
  handleVideoUploadChange = (event, input) => {
    event.preventDefault();
    let videoFile = event.target.files[0];
    input.onChange(videoFile);
  };
  renderFileInput = ({ input, type, meta, label, id }) => {
    return (
      <div style={{ marginBottom: 20 }}>
        <label>
          <b>{label}:</b>
        </label>
        <br />
        <input
          id={id}
          style={{ margin: "5px 0" }}
          name={input.name}
          type={type}
          accept="video/mp4, video/webm, video/ogg"
          onChange={(event) => this.handleVideoUploadChange(event, input)}
        />
        <a
          style={{ fontSize: 20 }}
          onClick={() => {
            input.onChange(undefined);
            document.querySelector("#videoFile").value = "";
          }}
        >
          x
        </a>
        {meta && meta.invalid && meta.error && (
          <div style={{ color: "#ff4d4f", marginBottom: 10 }}>{meta.error}</div>
        )}
      </div>
    );
  };
  render = () => (
    <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
      <h2>Umieść wideo w serwisie Vimeo</h2>
      <Field
        name="name"
        component={Input}
        placeholder="Tytuł wideo"
        type="text"
      />
      <Field
        id="videoFile"
        name="video_file"
        label="Plik wideo"
        type="file"
        validate={[validateVideoFormat, validateVideoWeight]}
        component={this.renderFileInput}
      />
      <Button
        type="primary"
        htmlType="submit"
        loading={this.state.loading}
        style={{ marginRight: "10px" }}
      >
        Wyślij
      </Button>
      <br />
      <br />
      {this.state.loading ? (
        <Alert
          message="Uwaga"
          description="Prosimy nie zamykać okna przeglądarki do momentu przesłania wideo na serwer."
          type="warning"
          showIcon
          closable
        />
      ) : null}
      <br />
      <br />
      <Progress percent={this.state.percent} status={this.state.uploadStatus} />
    </form>
  );
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.name) errors.name = "Proszę podać nazwę wideo.";
  if (!formValues.video_file) errors.video_file = "Proszę wybrać plik.";
  return errors;
};

export default reduxForm({ form: "vimeoVideoForm", validate })(VimeoForm);
